import { IntlMessage } from "../../../../Elements";
import React from "react";
import { initialState, SingleStatusPageModel } from "../../Model/SingleStatusPageModel";
import MIcon from "../../../../Elements/MIcon";

/**
 * @author WUM Solution UG <Gregor Schalm>
 * CanceledView component renders a message indicating an unexpected Stripe payment state.
 *
 * It takes an optional `props` argument of type `SingleStatusPageModel`.
 * If no props are provided, it defaults to the `initialState` imported from the SingleStatusPageModel module.
 *
 * The component displays a heading, text message, current payment status, and potentially a smiley icon and contact email based on the configuration provided in props.
 * - Heading and text message use IntlMessage for internationalization with dynamic store context.
 * - Status is displayed as a bold text.
 * - Smiley icon is displayed if `unexpectedSmiley` property exists in configuration.
 * - Contact email link is displayed if `contactEmail` property exists in configuration.
 *
 * @param {SingleStatusPageModel} [props] - Optional props object of type SingleStatusPageModel. Defaults to initialState.
 * @returns {JSX.Element} - The JSX element representing the canceled view.
 */

const CanceledView = (props: SingleStatusPageModel = initialState):JSX.Element => {

    const Configuration = props.configuration;

    return (
        <>
            <div className={"m-3 col-md-5 mx-auto"}>
                <h3 className={'text-center'}><IntlMessage messageId={"stripe.unexpected.h3"} Store={props.Store}/></h3>
                <p className={'text-center'}><IntlMessage messageId={"stripe.unexpected.text"} Store={props.Store}/></p>
                <p className={"font-weight-bold text-center"}>Status:  {props.status}</p>
                {Configuration?.unexpectedSmiley &&
                    <div className={'text-center'}>
                        <MIcon newMaterial={true} variant={'-outlined'} style={{fontSize: '13em'}} className={'text-center'}>
                            {Configuration.unexpectedSmiley}
                        </MIcon>
                    </div>
                }
                {Configuration?.contactEmail &&
                    <div className={"text-end w-100"}>
                        <MIcon newMaterial={true} variant={'-outlined'} className={'align-items-center align-middle pe-2'}>
                            support
                        </MIcon>
                        <a href={'mailto:' + Configuration.contactEmail + '?subject=Order ' + props.status} ><IntlMessage
                            messageId={"stripe.support.email"} Store={props.Store}/></a>
                    </div>
                }
            </div>
        </>
    );
};

export default CanceledView;
