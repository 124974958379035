import {
    formatDateLongEnglishControllerSingle,
} from "../../MuiTabpanel/Controller/FormatDatesLongEnglish.Controller";

interface DailyHeaderViewProps {
    tabLabel: string,
    date: string
}

const DailyHeaderView: React.FC<DailyHeaderViewProps> = (props:DailyHeaderViewProps) => {
    console.log(props.tabLabel)

    const dateInfo = formatDateLongEnglishControllerSingle(props.date)

    return (
        <>
            <div className={"daily-header"}>
                {props.tabLabel} - {dateInfo}
            </div>
        </>
    )
}

export default DailyHeaderView;