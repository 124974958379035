import IntlMessage from '../../../../../../@WUM/core/component/const/IntlMessage';
import React from "react";

/**
 *
 * @memberOf blog
 * @prop conf
 * @prop response
 * @prop page
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
function configuration(conf: string,response:any,page:any,Store:any,endpoint:any='') {

    return {
        tableBody: {
            data: response,
            dataID: 'id',
            underdata: true,
            keys: [
                'id',
                'presentationTitle'
            ],
            type: ['string', 'string'],
        },
        tableHeader: [
            'ID',
            'Presentation Title'
        ],
        tableHeaderWidth: ['auto', '80%'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menutriggerDelete: true,
            menuItem: [
                'Show'
            ],
            menuroute: ['settings/event/Detail'+endpoint],
            menuParams: ['', 'id', 'id'],
            menuIcons: ['pencil'],
        },
    }
}

export default configuration;
