import React, {useState
   } from 'react';
import { useParams} from 'react-router-dom';
import {ResponseEventSingleDeleted} from "../../API/response/event";




const RewriteDeleted = (value:any) => {
    console.log("test")
    const {page, deleteID} = useParams<{ page: string, deleteID: string }>();
    let landingpages = '/settings/events/overview/'+page
    const deleted = ResponseEventSingleDeleted(deleteID);
    if(deleted !== null  && deleted !== undefined) {
        setTimeout(() => window.location.href = landingpages, 3000);
    }
    return (<></>);

}
export default RewriteDeleted;



