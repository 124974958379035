import {DashbaordMenuConfig, DashbaordPagesConfig} from "./dashboard/modules";
import {AbstractMenuConfig, AbstractPagesConfig} from "./abstractForm";


export const CMSRouteConfig = (value:any) => {
    if(process.env.REACT_APP_ABSTRACT !== undefined && process.env.REACT_APP_ABSTRACT === 'True') {
        return [
            ...DashbaordPagesConfig(value),
            ...AbstractPagesConfig(value)
        ]
    } else {
        return [
            ...DashbaordPagesConfig(value)
        ]
    }

}

export const CMSMenuConfig = (value:any) => {

    if(process.env.REACT_APP_ABSTRACT !== undefined && process.env.REACT_APP_ABSTRACT === 'True') {
        return [
            {
                header: 'Overview',
                icon: 'schedule_send',
                menuitem: [
                    ...DashbaordMenuConfig(value),
                    ...AbstractMenuConfig(value)
                ],
                roles: [
                    'superadmin',
                    'admin',
                    'host',
                    'user',
                    'icymare'
                ]
            },
        ];
    } else {
        return [
            {
                header: 'Overview',
                icon: 'schedule_send',
                menuitem: [
                    ...DashbaordMenuConfig(value),
                ],
                roles: [
                    'superadmin',
                    'admin',
                    'host',
                    'user',
                    'icymare'
                ]
            },
        ];
    }


    }

