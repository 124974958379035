import { Button } from "react-bootstrap";
import {responseDetailExample} from "../../API/example/responseDetails";
import {HeaderView, LocationBarView, PresentationOverview} from "../Views/Elements";
import {Link, useLocation, useParams} from "react-router-dom";
import {EventCalendarSessionDetails} from "../../API/request/EventCalendar";
import {MIcon} from "../../../../@WUM/core/Elements";
import {SubstituteHtmlCharactersController} from "../Controller/SubstituteHtmlCharacters.Controller";

interface SessionDetailViewProps {
    value?: any
}

export const DetailSession = (props: SessionDetailViewProps) => {
    const {id} = useParams<{ id: string }>();
    const response = EventCalendarSessionDetails(id)

    const location = useLocation();

    if (response !== null && response !== undefined) {
        console.log(response)

        return (
            <>
                <div className={'freeWrapper bg-white'}>
                    <div className="event-calendar">
                        <HeaderView header={location.state} />
                        <Link to="/unbound/event-calendar">
                            <Button variant={"dark"} className={"w-100 event-button-back"}> <MIcon style={{verticalAlign: "top"}}>arrow_back</MIcon>  Back to Schedule</Button>
                        </Link>
                        <PresentationOverview value={response.presentations} locationLInk={response.locationLink}
                                              locationAddress={SubstituteHtmlCharactersController(response.locationAddress)}
                                              locationHeadline={SubstituteHtmlCharactersController(response.locationHeadline)} name={response.name}
                                              description={SubstituteHtmlCharactersController(response.description)} header={location.state} id={id}/>
                    </div>
                </div>
            </>
        )
    } else {
        return <>waiting</>
    }
}
