import {ValidatorFields} from "../../../../../@WUM/core/component/const/Validator";

/**
 * @constant {ValidatorFields[]} FieldNamesPersonal - An array of `ValidatorFields` objects for personal information fields.
 */
const FieldNamesPersonal:ValidatorFields[] = [

    {element:'first_name',error:'Please enter the information',valid:null},
    {element:'last_name',error:'Please enter the information',valid:null},
    {element:'street',error:'Please enter the information',valid:null},
    {element:'streetNumber',error:'Please enter the information',valid:null},
    {element:'zip',error:' Please enter the information',valid:null},
    {element:'cityPersonal',error:'Please enter the information',valid:null},
    {element:'CountyAdressPersonal',error:'Please enter the information',valid:null},
    {element:'nameAff',error:'Please enter the information',valid:null},
    {element:'streetAff',error:'Please enter the information', valid:null},
    {element:'numberAff',error:'Please enter the information',valid:null},
    {element:'zipAff',error:'Please enter the information',valid:null},
    {element:'cityInstitut',error:'Please enter the information',valid:null},
    {element:'CountryAdressInstitutional',error:'Please enter the information',valid:null},
    // {element:'vatInstitut',error:'Please enter the information',valid:null},
    {element:'InvoicePersonal',error:'Please enter the information',valid:null}

];

export default FieldNamesPersonal
