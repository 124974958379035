import * as React from 'react';
import {UniCol, UniRow} from "../../../../../@WUM/core/Elements";
import {VariantsModel} from "../Model/Variants.Model";


export const RowVariantView = (props: VariantsModel) => {
    return (
        <>
            <UniRow class={props.tabConfigurations.class} id={props.tabConfigurations.id}>
                {props.tabConfigurations.tabs.map((config) => (
                    <UniCol>
                        <>{config.content}</>
                    </UniCol>
                ))}
            </UniRow>
        </>
    )
}