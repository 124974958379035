import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
import token from "../../../../ticketsystem/modules/components/Token";

const send = (e:any,afterSend:any,id:any='0',oldFile1:any='0',OldFile2:any='0') => {
    e.preventDefault();
    let newFile1:any;

    let newFile2:any;

    let formfile = (document.getElementById('fileuploadAttachment1') as HTMLInputElement).files
    let formfile2 = (document.getElementById('fileuploadAttachment2') as HTMLInputElement).files
    console.log(formfile)
    console.log(formfile2)
    let allowedType
    if(formfile !== null && formfile?.length !== 0) {
        for (let f = 0; f < formfile?.length; f++) {
            let formDatas = new FormData()
            allowedType = [
                { type: 'application/pdf', size: 5000000 },
                { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', size: 5000000 }
            ]
            let checkType = 0
            let checkSize = 0
            if (allowedType !== undefined && allowedType.length > 0) {
                for (let type = 0; type < allowedType.length; type++) {
                    // @ts-ignore
                    if (allowedType[type].type === formfile[f].type) {
                        checkType = checkType + 1
                    }
                    // @ts-ignore
                    if (formfile[f].size < allowedType[type].size) {
                        checkSize = checkSize + 1
                    }
                }
            }
            if (checkType === 0 && checkSize === 0) {
                return;
            }
            formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
            formDatas.append('context', 'secure')
            // @ts-ignore
            formDatas.append(`file${0}`, formfile[f])
            var requestOptions = {
                method: 'POST',
                body: formDatas
            }
            let link
            if (process.env.REACT_APP_MODE === 'LOCAL') {
                link = process.env.REACT_APP_CDN_API_URL_LOCAL
            } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
                link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
            } else if (process.env.REACT_APP_MODE === 'LIVE') {
                link = process.env.REACT_APP_CDN_API_URL_LIVE
            }

            fetch(link + 'upload/', requestOptions)
                .then((response) => response.text())
                .then((result) => {

                    fetch(ApiURL()+'upload/secure/', {
                        method: 'POST',
                        body: result,
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + token()
                        }
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            newFile1 = data.id
                            let formfile2 = (document.getElementById('fileuploadAttachment2') as HTMLInputElement).files
                            if(formfile2 !== null) {
                                for (let f = 0; f < formfile2?.length; f++) {
                                    let formDatas = new FormData()
                                    allowedType = [
                                        { type: 'application/pdf', size: 5000000 },
                                        { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', size: 5000000 }
                                    ]
                                    let checkType = 0
                                    let checkSize = 0
                                    if (allowedType !== undefined && allowedType.length > 0) {
                                        for (let type = 0; type < allowedType.length; type++) {
                                            // @ts-ignore
                                            if (allowedType[type].type === formfile2[f].type) {
                                                checkType = checkType + 1
                                            }
                                            // @ts-ignore
                                            if (formfile2[f].size < allowedType[type].size) {
                                                checkSize = checkSize + 1
                                            }
                                        }
                                    }
                                    if (checkType === 0 && checkSize === 0) {
                                        return;
                                    }
                                    formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
                                    formDatas.append('context', 'secure')
                                    // @ts-ignore
                                    formDatas.append(`file${0}`, formfile2[f])
                                    var requestOptions = {
                                        method: 'POST',
                                        body: formDatas
                                    }
                                    let link
                                    if (process.env.REACT_APP_MODE === 'LOCAL') {
                                        link = process.env.REACT_APP_CDN_API_URL_LOCAL
                                    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
                                        link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
                                    } else if (process.env.REACT_APP_MODE === 'LIVE') {
                                        link = process.env.REACT_APP_CDN_API_URL_LIVE
                                    }

                                    fetch(link + 'upload/', requestOptions)
                                        .then((response) => response.text())
                                        .then((result) => {

                                            fetch(ApiURL()+'upload/secure/', {
                                                method: 'POST',
                                                body: result,
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    Authorization: 'Bearer ' + token()
                                                }
                                            })
                                                .then((response) => response.json())
                                                .then((data) => {
                                                    newFile2 = data.id;
                                                    let datas = new FormData(e.target);
                                                    let value = Object.fromEntries(datas.entries());
                                                    delete value.file1;
                                                    delete value.file2;
                                                    let files = {file1:newFile1,file2:newFile2}
                                                    let merged = {...value, ...files};
                                                    let raw = JSON.stringify(merged);
                                                    let root = ApiURL();
                                                    let requestOptions = {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
                                                        },
                                                        body: raw,
                                                    };
                                                    fetch(root+"Conference/Presentation/"+id+"/", requestOptions)
                                                        .then(response => response.text())
                                                        .then(result => afterSend('success'))
                                                        .catch(error => afterSend('error'));
                                                    let Button = document.getElementById('sendButton') as HTMLButtonElement;
                                                    Button.setAttribute('disabled','disabled');
                                                    Button.style.background = 'gray'

                                                })
                                                .catch((e:any) => {
                                                    console.log(e)
                                                })


                                        })
                                        .catch((e:any) => {
                                            console.log(e)
                                        })
                                }
                            }
                        })
                        .catch((e:any) => {
                            console.log(e)
                        })


                })
                .catch((e:any) => {
                    console.log(e)
                })
        }
    } else if(formfile2 !== null && formfile2?.length !== 0) {

        if(formfile2 !== null) {
            for (let f = 0; f < formfile2?.length; f++) {
                let formDatas = new FormData()
                allowedType = [
                    { type: 'application/pdf', size: 5000000 },
                    { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', size: 5000000 }
                ]
                let checkType = 0
                let checkSize = 0
                if (allowedType !== undefined && allowedType.length > 0) {
                    for (let type = 0; type < allowedType.length; type++) {
                        // @ts-ignore
                        if (allowedType[type].type === formfile2[f].type) {
                            checkType = checkType + 1
                        }
                        // @ts-ignore
                        if (formfile2[f].size < allowedType[type].size) {
                            checkSize = checkSize + 1
                        }
                    }
                }
                if (checkType === 0 && checkSize === 0) {
                    return;
                }
                formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
                formDatas.append('context', 'secure')
                // @ts-ignore
                formDatas.append(`file${0}`, formfile2[f])
                var requestOptions = {
                    method: 'POST',
                    body: formDatas
                }
                let link
                if (process.env.REACT_APP_MODE === 'LOCAL') {
                    link = process.env.REACT_APP_CDN_API_URL_LOCAL
                } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
                    link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
                } else if (process.env.REACT_APP_MODE === 'LIVE') {
                    link = process.env.REACT_APP_CDN_API_URL_LIVE
                }

                fetch(link + 'upload/', requestOptions)
                    .then((response) => response.text())
                    .then((result) => {

                        fetch(ApiURL()+'upload/secure/', {
                            method: 'POST',
                            body: result,
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + token()
                            }
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                newFile2 = data.id;
                                let datas = new FormData(e.target);
                                let value = Object.fromEntries(datas.entries());
                                delete value.file1;
                                delete value.file2;
                                let files = {file2:newFile2}
                                let merged = {...value, ...files};
                                let raw = JSON.stringify(merged);
                                let root = ApiURL();
                                let requestOptions = {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
                                    },
                                    body: raw,
                                };
                                fetch(root+"Conference/Presentation/"+id+"/", requestOptions)
                                    .then(response => response.text())
                                    .then(result => afterSend('success'))
                                    .catch(error => afterSend('error'));
                                let Button = document.getElementById('sendButton') as HTMLButtonElement;
                                Button.setAttribute('disabled','disabled');
                                Button.style.background = 'gray'

                            })
                            .catch((e:any) => {
                                console.log(e)
                            })


                    })
                    .catch((e:any) => {
                        console.log(e)
                    })
            }
        }
    } else {
        let data = new FormData(e.target);
        let value = Object.fromEntries(data.entries());
        let files = {file1:oldFile1,file2:OldFile2}
        let merged = {...value, ...files};
        let raw = JSON.stringify(merged);
        let root = ApiURL();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw,
        };
        fetch(root+"Conference/Presentation/"+id+"/", requestOptions)
            .then(response => response.text())
            .then(result => afterSend('success'))
            .catch(error => afterSend('error'));
    }
}

export default send;
