import React, {useState
   } from 'react';
import { useParams} from 'react-router-dom';
import {ResponseSessionSingleDeleted} from "../../API/response/session";




const RewriteDeleted = (value:any) => {
    const {page, deleteID} = useParams<{ page: string, deleteID: string }>();
    let landingpages = '/Presentation/overview/'+page
    let deleted = ResponseSessionSingleDeleted(deleteID);
    if(deleted !== null  && deleted !== undefined && deleted !== false) {
        setTimeout(() => window.location.href = landingpages, 3000);
    }
    return (<></>);

}
export default RewriteDeleted;



