import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import {initialState, PDFUniTextModel} from "../../../Model/Elements/PDFUniTextModel";

/**
 * @param {PDFUniTextModel} [props=initialState] - Props for the PDFUniText component. Defaults to initialState if no props are provided.
 * @returns {JSX.Element} - A React component that renders text content within a View container.
 */
export const PDFUniTextView = (props:PDFUniTextModel = initialState): JSX.Element => {
    return (
        <View style={[props.classView, props.styleView]} debug={props.debugView} fixed={props.fixed}>
            <Text style={[props.classText, props.styleText]} debug={props.debugText} wrap={props.wrap}>
                {props.text}
            </Text>
        </View>
    );
};