import React from 'react'
import EmailOverview from './Pages/overview'
import EmailNew from './Pages/new'
import EmailDetail from './Pages/Detail'
import EmailDeleted from './Pages/deleted'

export const EmailPagesConfig = (value: any) => {
    return [
        {
            path: '/Email/:page',
            elements: <EmailOverview value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Email/overview/:page',
            elements: <EmailOverview value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Email/Delete/:page/:deleteID',
            elements: <EmailDeleted value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Email/new/new',
            elements: <EmailNew value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Email/new/:page',
            elements: <EmailNew value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Email/Detail/:id',
            elements: <EmailDetail value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        }
    ]
}

export const EmailMenuConfig = (value: any) => {
    return [
        {
            name: 'Email',
            route: '/Email/1',
            icon: 'post_add',
            roles: ['superadmin', 'admin', 'operator']
        }
    ]
}
