import { initialState, InvoiceStatusModel } from "../Model/InvoiceStatusModel";

/**
 * @author Gregor Schalm
 * InvoiceStatusController function that takes an optional `props` argument of type `InvoiceStatusModel`.
 * If no props are provided, it defaults to the `initialState` imported from the InvoiceStatusModel module.
 *
 * This function determines a value for `receive` based on the value of the `status` property in `props`.
 *   - If `status` is "succeeded", `receive` is set to 1.
 *   - If `NonStripePath` is true (optional property), `receive` is set to 1.
 *   - Otherwise, `receive` is set to 0.
 *
 * The function then returns the calculated `receive` value.
 *
 * @param {InvoiceStatusModel} [props] - Optional props object of type InvoiceStatusModel. Defaults to initialState.
 * @returns {number} - The calculated value for "receive".
 */
const InvoiceStatusController = (props: InvoiceStatusModel = initialState): number => {
    let receive: number;

    if (props.status === "succeeded") {
        receive = 1;
    } else if (props.NonStripePath === true) {
        receive = 1;
    } else {
        receive = 0;
    }
    return receive;
};

export default InvoiceStatusController;
