import React from 'react';
import AbstractNew from "./modules/Pages/new";
import AbtractOverview from "./modules/Pages/overview";
import AbstractDetail from "./modules/Pages/detail";
import AbstractDetailUser from "./modules/Pages/detailUser";
import AbstractDetailSessionHost from "./modules/Pages/detailHost";
import AbtractOverviewUser from "./modules/Pages/overviewUser";
import AbtractOverviewHost from "./modules/Pages/overviewHost";
import {useSelector} from "react-redux";
import {CMSRouteConfig} from "../index";
import {TicketPagesConfig} from "../../ticketsystem/modules";
import {SettingsRouteConfig} from "../../setting";

export const AbstractPagesConfig = (value: any) => {
    const Store = useSelector((state: any) => state)
    let Role = Store.user.roles

    switch(Role){
        case 'user':
            return [
                {
                    path: '/Abstract/overviewUser/:page',
                    elements: <AbtractOverviewUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/new',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/new/:page',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/DetailUser/:id',
                    elements: <AbstractDetailUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ];
        case 'host':
            return [
                {
                    path: '/Abstract/overviewSessionHost/:page',
                    elements: <AbtractOverviewHost value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/new',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/new/:page',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/DetailSessionHost/:id',
                    elements: <AbstractDetailSessionHost value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/overviewUser/:page',
                    elements: <AbtractOverviewUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/new',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/new/:page',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/DetailUser/:id',
                    elements: <AbstractDetailUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                }
            ];
        case 'icymare':
            return [
                {
                    path: '/Abstract/overview/:page',
                    elements: <AbtractOverview value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/new',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/new/:page',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/Detail/:id',
                    elements: <AbstractDetail value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                }
            ];
        case 'superadmin':

            return [
                {
                    path: '/Abstract/overview/:page',
                    elements: <AbtractOverview value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/overviewSessionHost/:page',
                    elements: <AbtractOverviewHost value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/overviewUser/:page',
                    elements: <AbtractOverviewUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/new',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/new/:page',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/Detail/:id',
                    elements: <AbstractDetail value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/DetailUser/:id',
                    elements: <AbstractDetailUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/DetailSessionHost/:id',
                    elements: <AbstractDetailSessionHost value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ];
        default:
            return [
                {
                    path: '/Abstract/overviewUser/:page',
                    elements: <AbtractOverviewUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/new',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/new/:page',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/Abstract/DetailUser/:id',
                    elements: <AbstractDetailUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ];
    }

}

export const AbstractMenuConfig = (value: any) => {
    const Store = useSelector((state: any) => state)
    let Role = Store.user.roles

    switch(Role){
        case 'user':
            return [
                {
                    name: 'Abstract Overview',
                    route: '/Abstract/overviewUser/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ]
        case 'host':
            return [
                {
                    name: 'Host Abstracts',
                    route: '/Abstract/overviewSessionHost/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    name: 'Presenter Abstracts',
                    route: '/Abstract/overviewUser/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                }
            ]
        case 'icymare':
            return [
                {
                    name: 'Abstract Overview',
                    route: '/Abstract/overview/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ]
        case 'superadmin':
            return [
                {
                    name: 'Icymare Abstract Overview',
                    route: '/Abstract/overview/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    name: 'Host Abstract Overview',
                    route: '/Abstract/overviewSessionHost/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    name: 'User Abstract Overview',
                    route: '/Abstract/overviewUser/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ]
        default:
            return [
                {
                    name: 'Abstract Overview',
                    route: '/Abstract/overviewUser/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ]
    }

}
