// src/controllers/PDFUniTableController.ts

/**
 * Calculates the widths for each column.
 * If widths are provided and their length matches the number of columns, they are used.
 * Otherwise, a default width is calculated.
 *
 * @param {string[]} header - The headers of the table.
 * @param {Percentage[]} [widths] - Optional array of widths for each column.
 * @returns {string[]} An array of percentages representing the widths of each column.
 */
export const calculateColumnWidths = (header: string[], widths?: string[]): string[] => {
    const columnCount = header.length;

    if (widths && widths.length === columnCount) {
        return widths;
    } else {
        const defaultWidth = (100 / columnCount).toFixed(2) + '%';
        return Array(columnCount).fill(defaultWidth);
    }
};
