import { Line, Svg } from "@react-pdf/renderer";
import { PDFUniHorizontalLineModel, initialState } from "../../../Model/Elements/PDFUniHorizontalLineModel";
import {
    MappingPropsToInitialState,
} from "../../../../../../../@WUM/core/Function/MappingPropsToInitialState/MappingPropsToInitialState";

/**
 * @author Gregor Schalm
 * @function PDFUniHorizontalLineView - Functional React component for rendering a horizontal line in a PDF document.
 * @param {PDFUniHorizontalLineModelProps} props - Component properties that define the line's attributes.
 * @returns {JSX.Element} - React JSX element representing the horizontal line.
 */

export const PDFUniHorizontalLineView = (props: PDFUniHorizontalLineModel = initialState) => {

    // Utility function to map incoming props to the initial state with defaults.
    const State = MappingPropsToInitialState(props, initialState);

    return (
        <>
            <Svg debug={State.debug} height={State.strokeWidth} style={[State.class, State.style]} >
                <Line
                    x1={State.begin}
                    y1="0"
                    x2={State.length}
                    y2="0"
                    strokeWidth={State.strokeWidth}
                    stroke={State.color}
                />
            </Svg>
        </>
    );
};