import { PaymentPageConfigModel } from "./PaymentPageConfigModel";

/**
 * @author WUM Solution UG <Gregor Schalm>
 * @typedef {Object} SingleStatusPageModel - Model representing each single status page.
 * @property {any} Store - Identifier for the store associated with the status page.
 * @property {string} status - The current status of the payment process.
 * @property {PaymentPageConfigModel} [configuration] - Optional configuration object for the payment page (type: PaymentPageConfigModel).
 */
export interface SingleStatusPageModel {
    Store: any;
    status: string;
    configuration?: PaymentPageConfigModel;
}

/**
 * Defines the initial state for the SingleStatusPageModel as an empty object with the specified properties.
 */
export const initialState: SingleStatusPageModel = {
    Store: '',
    status: '',
};
