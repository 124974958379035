/**
 * @author Gregor Schalm
 * @typedef {Object} HeaderModel - Interface defining the structure of header data.
 *
 * @property {any[]} data - An array containing header data.
 * The specific data types within the array might vary depending on usage.
 */
export interface HeaderModel {
    data: any;
}

/**
 * @constant {HeaderModel} initialState - Default initial state for the Header component.
 *
 * Contains an empty array for the `data` property.
 */
export const initialState: HeaderModel = {
    data: [],
};
