import React from "react";
import {TextareaModel, initialState} from "../Model/TextareaModel";
import {PDFUniTextView, PDFUniHorizontalLineView} from "./Elements";
import { styles } from "./css/Textarea.css";
import {InvoiceTableView} from "./InvoiceTableView";

export const TextareaView = (props: TextareaModel = initialState) => {

    return (
        <>
            <PDFUniHorizontalLineView style={{marginTop: '50mm', marginBottom: '1mm'}}/>
            <PDFUniTextView text={`INVOICE: PARTICIPATION FEE ICYMARE 2024 Bremen `} classView={styles.headline}/>
            
            <PDFUniTextView text={`We thank you for attending and supporting the ICYMARE initiative. For your participation at the ICYMARE 2024 Bremen we invoice the following:`} classView={styles.text} styleView={{marginTop: '8mm', marginBottom: '8mm'}}/>

            <InvoiceTableView data={props.data} rows={props.rows} totalRow={props.totalRow}/>

            <PDFUniTextView text={props.message} classView={styles.text} styleView={{marginTop: '6mm', marginBottom: '10mm'}}/>
            
            <PDFUniTextView text={"Your ICYMARE Team of the Bremen Society for Natural Sciences"} classView={styles.text} styleView={{marginBottom: '4mm'}} />

            <PDFUniTextView text={"This invoice was created automatically and is valid without a signature."} classView={styles.legalText}  />
        </>
    );
};