import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
import FetchSelect from "../../../../../@WUM/core/component/const/Fetchselect";
import {ResponseAuthor} from "../../API/response/session";
import {SpinnerJSX} from "../../../../../@WUM/core/component/const/Spinner";
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import {useSelector} from "react-redux";
import {btn_back} from "../../../../../@WUM/core/component/const/btn_back";
import {useNavigate} from "react-router-dom";
const SessionNew = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    const author = ResponseAuthor();
    const history = useNavigate();
    const back =  btn_back(history,"common.back","btnBack",Store)

    const send = (e:any) => {
        e.preventDefault();
        let data = new FormData(e.target);
        let value = Object.fromEntries(data.entries());
        let raw = JSON.stringify(value);

        let root = ApiURL();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw,
        };
        fetch(root+"Presentation/Types/0/", requestOptions)
            .then(response => response.text())
            .then(result => Notification({type:'success', mode:'classic', title:{text:'Success'}, text:{text: 'Saved successfully'}, insert:'center right',duration:4}))
            .catch(error => Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Sorry, an error has occurred'}, insert:'center right',duration:4}));
    }



    if(author.length > 0) {
    return (<>
            <Row className={''}>
                <Row>
                    <Col xs={4} md={4} lg={4} style={{background: 'transparent linear-gradient(270deg, #0CB4CE 0%, #317391 100%) 0% 0% no-repeat padding-box',color:'white',maxHeight:'840px',minHeight: '815px'}}>
                        <br />
                        <p className={'text-center'}><img src={'/assets/ICYMARELogo.jpg'} /></p>
                        <br />
                        <h2>Abstract Submission ICYMARE 2023 Oldenburg</h2>
                        <p> <img src={"/assets/ICYMARE-2023-OLDENBURG_Banner.jpg"} /></p>
                        <br />
                        <p>Please fill in this form for the submission of your abstract for the ICYMARE 2023 Oldenburg conference. Once your abstract is accepted, please register as soon as possible via the ICYMARE webpage (https://www.icymare.com/), in order to secure your presentation.</p>
                    </Col>
                    <Col style={{background: '#FFFFFF 0% 0% no-repeat padding-box',maxHeight:'840px',minHeight: '815px',overflowX:'hidden',overflowY:'scroll'}}>  <Form id={'form01'} onSubmit={(e)=> send(e)}>
                        <Row>
                            <Col>
                                <h1>Presentation</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="type">
                                    <Form.Label>Presentation Type</Form.Label>
                                    <Form.Control type="text" name={'type'} required={true} placeholder="Enter Presentation Type"/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>

                            <Col>
                                {back}
                                <Button variant="primary" className={'float-end'} type="submit"
                                        style={{background: '#307593 0% 0% no-repeat padding-box',
                                            boxShadow: '0px 3px 6px #00000029',
                                            borderRadius: '34px',
                                            marginBottom:'20px',
                                            opacity: 1,
                                            float:'right',
                                            height: '67px',
                                            width: '350px'}}>
                        <span style={{
                            font: 'normal normal bold 30px/40px Segoe UI',
                            letterSpacing: '0px',
                            color: '#FFFFFF',
                            opacity: 1
                        }}>
                             Send
                        </span>
                                </Button></Col>
                        </Row>
                        <Row>
                            <Col>
                                <br/> <br/>
                            </Col>
                        </Row>
                    </Form></Col>
                </Row>

            </Row>
        </>)
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }
}

export default SessionNew;
