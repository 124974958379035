import {ShowDialog} from "../@WUM/core/sidebar/sidebar";
import {CMSMenuConfig, CMSRouteConfig} from "../modules/CMS";
import {TicketMenuConfig, TicketPagesConfig} from "../modules/ticketsystem/modules";
import {SettingsMenuConfig, SettingsRouteConfig} from "../modules/setting";
import {useSelector} from "react-redux";
import {checkLocation} from "../@WUM/core/sidebar/ItemMenu";
import {SessionHostMainMenuConfig} from "../modules/CMS/SessionforHost";
import {ProfilMainMenuConfig} from "../modules/CMS/profil";
import {RegistrationMainMenuConfig} from "../modules/CMS/registrationConference"
import {SurveyMainMenuConfig, SurveyMenuConfig} from "../modules/CMS/Survey";
import {StatisticsMainMenuConfig, StatisticsMenuConfig} from "../modules/CMS/statistics";

export const RegisterSitemap = (value: any) => {
    const Store = useSelector((state: any) => state)
    let Role = Store.user.roles
    switch(Role){
        case 'user':
            return[
                ...CMSMenuConfig(value),
                ...SessionHostMainMenuConfig(value),
                ...RegistrationMainMenuConfig(value),
                ...ProfilMainMenuConfig(value)
            ]
        case 'host':
            return[
                ...CMSMenuConfig(value),
                ...SessionHostMainMenuConfig(value),
                ...RegistrationMainMenuConfig(value),
                ...ProfilMainMenuConfig(value)
            ]
        case 'icymare':
            return[
                ...CMSMenuConfig(value),
                ...SessionHostMainMenuConfig(value),
                ...RegistrationMainMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...TicketMenuConfig(value),
                ...ProfilMainMenuConfig(value),
                ...RegistrationMainMenuConfig(value),
                ...StatisticsMainMenuConfig(value)
            ]
        case 'superadmin':
            return[
                ...CMSMenuConfig(value),
                ...SessionHostMainMenuConfig(value),
                ...RegistrationMainMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...TicketMenuConfig(value),
                ...ProfilMainMenuConfig(value),
                ...SurveyMainMenuConfig(value),
                ...StatisticsMainMenuConfig(value)
            ]
        default:
            return[
                ...CMSMenuConfig(value),
                ...SessionHostMainMenuConfig(value),
                ...RegistrationMainMenuConfig(value),
                ...ProfilMainMenuConfig(value)
            ]
    }

}

export const Submenu = (index:any,active:any) => {

    if(active === '') {
        active = window.location.pathname
    }

    let identifier;

    return false;

}


export const switchOpen = (identifier:any,active:any) => {
    let check = [];
    for(let x=0;x<identifier.length;x++) {
        if(checkLocation(identifier[x].split('/')[1],active)===true) {
            check.push('true');
        } else {
            check.push('false');
        }
    }
    if(check.includes('true') === true){
        return true;
    } else {
        return false;
    }
}

