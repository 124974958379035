import InvoiceStatusController from "../Controller/InvoiceStatusController";
import {getToken} from "../../../component/const/getToken";
import {ApiURL} from "../../../component/const/ApiURL";
import {CreateInvoiceModel, initialState} from "../Model/CreateInvoiceModel";
import CommentInvoiceController from "../Controller/CommentInvoiceController";


export const CreateInvoice = (props: CreateInvoiceModel = initialState) => {

    // get number value of invoice
    const priceString = localStorage.getItem('priceWebsite'); // Get the string value
    let priceWebsite: number = 0
    if (priceString !== null) {
        priceWebsite = parseFloat(priceString); // Convert to a number
    } else {
        console.error('Price not found in localStorage');
    }

    // Set discount, if Stripe was bypassed
    let discount = 0
    if (props.NonStripePath === true) {
        discount = priceWebsite
    } else {
        discount = 0
    }

    // create comment
    const comment = CommentInvoiceController({status: props.status, NonStripePath: props.NonStripePath})

    // create receive status
    const InvoiceStatus = InvoiceStatusController({status:props.status, NonStripePath: props.NonStripePath})

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+ getToken());

    var raw = JSON.stringify(
        {
            "amount": 1,
            "price": priceWebsite,
            "comment": comment,
            "receive": InvoiceStatus,
            "discount": discount
        }
    );

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
    };

    fetch(ApiURL() +"conf/invoice/create/registration/"+props.id+"/", requestOptions)
        .then(response => response.json())
        .then(result => {console.log(result)})
        .catch(error => console.log(error));

}