import React from 'react';
import EventDetail from "./Pages/detail";
import EventOverview from "./Pages/overview";
import EventNew from "./Pages/new";
import RewriteDeleted from "./Pages/deleted";

export const SocialEventSettingsPagesConfig = (value: any) => {

    return [
        {
            path: '/settings/social-events/overview/:page',
            elements: <EventOverview value={value}/>,
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
        {
            path: '/settings/social-events/Detail/:id',
            elements: <EventDetail value={value}/>,
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
        {
            path: '/settings/social-events/delete/:page/:deleteID',
            elements: <RewriteDeleted value={value}/>,
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
        {
            path: '/settings/social-events/new/1',
            elements: <EventNew value={value}/>,
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        }
    ];
}

export const SocialEventSettingsMenuConfig = (value: any) => {
    return [
            {
                name: 'Social Events',
                route: '/settings/social-events/overview/1',
                icon: 'event',
                roles: [
                    'superadmin',
                    'admin',
                    'host',
                    'user',
                    'icymare'
                ]
            }
        ]
}
