import React from "react";
import QrView from "../View/QrView";
import { QrControllerModel, initialState } from "../Model/QrControllerModel";
import { ResponsefetchQR } from "../API/response/FetchQr";

/**
 * QrController component renders a QR code based on the provided URL and optional ID.
 *
 * @component
 * @param {QrControllerModel} props - The properties passed to the component.
 * @param {string} props.url - The base URL for generating the QR code.
 * @param {boolean} [props.urlWithId] - Optional. Flag indicating whether to append the `id` to the URL.
 * @param {any} [props.id] - Optional. The identifier to append to the URL if `urlWithId` is true.
 * @param {number} props.width - The width of the QR code in pixels.
 * @param {number} props.height - The height of the QR code in pixels.
 * @param {React.CSSProperties} [props.style] - Optional. Inline styles to apply to the QR code container.
 * @param {string} [props.className] - Optional. CSS class name to apply to the QR code container.
 *
 * @returns {JSX.Element} JSX element representing the QR code.
 *
 * @example
 * // Example usage of QrController
 * <QrController
 *    url="https://example.com"
 *    urlWithId={true}
 *    id="123"
 *    width={200}
 *    height={200}
 *    style={{ border: '1px solid black' }}
 *    className="qr-code"
 * />
 *
 * @remarks
 * This component fetches the QR code data based on the provided URL and renders it using the QrView component.
 * Ensure that the API endpoint specified by `url` supports the optional `id` parameter if `urlWithId` is set to true.
 *
 * @see QrView for the view component that displays the QR code.
 * @see QrControllerModel for the model defining the properties of this component.
 * @see initialState for the default property values.
 * @see ResponsefetchQR for the API call to fetch the QR code data.
 *
 * @since 2024
 *
 * @author
 * Gregor Schalm
 */
const QrController = (props: QrControllerModel = initialState) => {
    // Construct the URL based on the urlWithId flag
    const url = props.urlWithId ? `${props.url}/${props.id}/` : props.url;

    // Fetch the QR code data using the API
    const QR = ResponsefetchQR({ url: url, id: props.id });
    let src = '';
    if (QR !== undefined && QR !== null) {
        src = QR[0].src as string; // Extract the source URL for the QR code image
    }

    return (
        <>
            {/* Render the QR code using the QrView component */}
            <QrView
                src={src}
                width={props.width}
                height={props.height}
                id={props.id}
                style={props.style}
                className={props.className}
            />
        </>
    );
};

export default QrController;
