import React, {useState
} from 'react';
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import {useNavigate,
    useParams
} from 'react-router-dom';
import { ContentSwitch } from '../../../../../@WUM/core/Elements/index';
import {useSelector} from "react-redux";
import SearchJSX from "../../../../../@WUM/core/component/const/SearchJSX";
import configuration from '../configuration/Page/overview';
import {ResponseEvent} from "../../API/response/event";
import {ResponseSessionSingleDeleted} from "../../../presentationType/API/response/session";

const EventOverview = (value:any) => {

    const {page,deleteID} = useParams<{page: any,deleteID: string}>();
    let limit: any;

    const Store = useSelector((state: any) => state)
    const history = useNavigate();
    /**
     * Neuladen der Seite
     */
    const routeChange = (paths:string) => {
        history(-1);
    };

    if(deleteID) {
        ResponseSessionSingleDeleted(deleteID);
        routeChange('/Presentation/overview/1')
    }

    const debug = (search: any,modalShow: boolean, modalData: any[]) => {
        return;
    }

    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    } else {
        limit = (page * 10) - 10;
    }

    const destination = 'event';
    const namespace_search = "Session_searchValue";
    const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
    const [search, setSearch] = useState<boolean>(false)
    const response = ResponseEvent(limit,searchValue);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState([]);
    const Configuration = configuration('',response,page,Store);
    debug(search,modalShow,modalData)
    const [mode, setMode] = useState('cart');


    const SearchConfig = [{
        namespace:namespace_search,
        destination: destination,
        input:[
            {id:'search_name',translation: 'Search for title...',param: 'title',method: 'like'}

        ],
        button: ['Search','Delete Search','New Event']
    }]


    const CardsConfiguration = {
        'data': {
            'ressource': response,
            'img': '',
            'dontStatus': true,
            'status': 'state',
            'statusindi': true,
            'statusMapping': [
                {name: 'error',color:'dark'},
                {name: 'active',color:'success'},
                {name: 'changed',color:'warning'},
                {name: 'cancelled',color:'danger'},
            ],
            'link': 'translation.de.slug',
            'prelink': '/magazin/[param]/',
            'prelinksearch': 'translation.de.slug',
            'title': 'name',
            'descriptionShow': true,
            'description': 'extends.shortDescription',
            'router': 'id',
            'showauthor': true,
            'author_firstname': 'date',
            'author_lastname': 'dateEnd',
            'preroute': '/settings/event/Detail/',
            'edit': 'Show Event',
            'editOnly': true,
            'delete': false,
            'deleteLink': '/settings/event/delete/'+page+'/[ID]',
            'Dontvisited': true,
        }
    }

    let NewPage = (page === undefined ? 1:page);
    if (response.length > 0) {
        return (
            <> <SearchJSX setSearchValue={setSearchValue} Store={Store} SearchConfig={SearchConfig} />
                { <ContentSwitch pagenew={'new'} destinationnew={'Session'} tonew={'new'} text={'Show Session'}
                                 response={response} CardsConfiguration={CardsConfiguration} mode={mode}
                                 Configuration={Configuration} setMode={setMode}  destination={destination} page={NewPage}
                                 to={'overview'} setSearchValues={setSearchValue} setSearchs={setSearch} search={false}
                                 modalShow={modalShow} setModalShow={setModalShow} modalData={modalData} setModalData={setModalData} SearchParam={'?translation.title'}
                                 searchfield={false} allTaxon={[]} _handleKeyFilters={[]} _handleEnter={[]} filter={true} stop={false} Store={Store}/>}
            </>

        )
    } else {
        localStorage.setItem('searchEnterInput','');
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }

};



export default EventOverview;



