import OpenSansFontsRegister from "./Fonts/OpenSans/FontsRegister";
import RalewayFontsRegister from "./Fonts/Raleway/FontsRegister";
import DaysOneFontsRegister from "./Fonts/DaysOne/FontsRegister";

interface FontsRegisterProps {
    opensans?: boolean
    raleway?: boolean
    daysone?: boolean
}

const FontsRegister = (props:FontsRegisterProps) => {
    if (props.opensans) {
        OpenSansFontsRegister();
    }
    if (props.raleway) {
        RalewayFontsRegister();
    }
    if (props.daysone) {
        DaysOneFontsRegister();
    }

}

export default FontsRegister