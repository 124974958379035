import {PaymentPageConfigModel} from "./PaymentPageConfigModel";

/**
 * @author WUM Solution UG <Gregor Schalm>
 */
export interface PaymentPageModel {
    /**
     * @property {any} Value - Value from the register path, contains RegistrationID
     */
    value: any;
    /**
     * @property {PaymentPageConfigModel} configuration - Optional configuration object for the payment page (type: PaymentPageConfigModel).
     */
    configuration?: PaymentPageConfigModel;
    /**
     * @property {boolean} NonStripePath - Optional flag indicating a non-Stripe payment path (default: false).
     */
    NonStripePath?: boolean;
}

/**
 * Defines the initial state for the `PaymentPageModel`.
 * - `value` is set to an empty string.
 * - `NonStripePath` is set to false.
 */
export const initialState: PaymentPageModel = {
    value: '',
    NonStripePath: false,
};
