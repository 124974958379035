/**
 * @author WUM Solution UG <Gregor Schalm>
 */
export interface CreateInvoiceModel {
    /**
     * @property {boolean} NonStripePath - Optional flag indicating a non-Stripe payment path (default: false).
     */
    NonStripePath?: boolean;
    /**
     * @property {string} status - The current status of the payment process (default: '')
     */
    status: string;
    /**
     * @property {string} id - The id where buying data is stored, optional.
     */
    id?: string
    /**
     * @property {any} e - event object
     */
    e?: any
}

/**
 * Defines the initial state for the `PaymentPageModel`.
 * - `status` is set to an empty string.
 * - `NonStripePath` is set to false.
 * - `id` is set to an empty string
 */
export const initialState: CreateInvoiceModel = {
    status: '',
    NonStripePath: false,
    id: ''
};
