import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {IntlMessageValue, Store, UniButton, UniForm} from "../Elements/";
import React, {useState} from "react";


/**
 * @description Renders a checkout form using Stripe Elements for payment processing.
 *
 * @component CheckoutForm
 * @return {JSX.Element} The checkout form component.
 */
const CheckoutForm = (props: { backUrl: any, LinkAGB?: string, LinkDataProtection?: string }): JSX.Element => {
    /**
     * @type {Stripe | null}
     * @private
     */
    const stripe = useStripe();
    /**
     * @type {Elements | null}
     * @private
     */
    const elements = useElements();
    /**
     * @type {boolean}
     * @default false
     */
    const [checkAGB, setCheckAGB] = useState<boolean>(false);
    /**
     * @type {boolean}
     * @default false
     */
    const [checkDS, setCheckDS] = useState<boolean>(false)
    /**
     * Handles the form submission and initiates payment processing with Stripe.
     *
     * @param {React.FormEvent<HTMLFormElement>} event The form submission event.
     * @private
     */
    const handleSubmit = async (event: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const baseUrl = window.location.origin;
        console.log(baseUrl + props.backUrl)
        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: baseUrl + props.backUrl,
            },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };


    return (
        /* <form  onSubmit={handleSubmit}>*/<>
            <PaymentElement/>

            <UniForm.Checks type={'checkbox'}
                            label={
                                props.LinkAGB ? (
                                    <a href={props.LinkAGB} target="_blank" rel="noopener noreferrer">
                                        {IntlMessageValue('de', 'payment.AGB.label', Store)}
                                    </a>
                                ) : IntlMessageValue('de', 'payment.AGB.label', Store)
                            }
                            value={"AGB"}
                            checked={checkAGB}
                            onChange={(e: { target: { value: string, } }) => {
                                setCheckAGB((prev) => !prev);
                            }}/>
            <UniForm.Checks type={'checkbox'}
                            label={
                                props.LinkDataProtection ? (
                                    <a href={props.LinkDataProtection} target="_blank" rel="noopener noreferrer">
                                        {IntlMessageValue('de', 'payment.DS.label', Store)}
                                    </a>
                                ) : IntlMessageValue('de', 'payment.DS.label', Store)
                            }
                            value={"Datenschutz"}
                            checked={checkDS}
                            onChange={(e: { target: { value: string, } }) => {
                                setCheckDS((prev) => !prev);
                            }}/>
            <UniButton
                children={IntlMessageValue('de', 'payment.btn.label', Store)} onClick={(e) => handleSubmit(e)}
                type={'button'} class={'float-end'} variant={'primary'} disabled={!stripe || !checkAGB || !checkDS}
            /></>
        /*  </form>*/
    );
};

export default CheckoutForm;