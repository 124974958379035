import {EventOverviewView, LocationBarView, SessionOverviewView, SinglePresentationsView, TimeFrameView} from "./";
import React from "react";
import {sortByStart} from "../../Controller/SortByStart";

export const PresentationOverview = (props: any ) => {

    const filteredEvents = props.value.filter((event: { presentationType: number; }) => event.presentationType === 1);

    const sortedEvents = sortByStart(filteredEvents)

    console.log(props.value)

    return (
        <div style={{background: props.color}}>
            {props.state === 2 && (
                <div className="event-changed-warning">{props.changeReason ? props.changeReason : "The time/venue has been updated"}</div>
            )}
            {props.state === 3 && (
                <div className="event-changed-removed">{props.changeReason ? props.changeReason : "The Session was cancelled"}</div>
            )}
            <div className={"daily-header"}>
                <h3 className={"session-headline"}>{props.name}</h3>
                <p className={"session-description"}>{props.description}</p>
            </div>
            <LocationBarView locationLink={props.locationLink} locationAddress={props.locationAddress} locationHeadline={props.locationHeadline} referral={"detail"}/>

            {sortedEvents.map((event: {id: any; }) => {
                return <SinglePresentationsView  key={event.id} {...event} header={props.header} id={event.id} sessionId={props.id}/>
            })}



        </div>
    )
}
