import React from "react";
import { QrViewModel, initialState } from "../Model/QrViewModel";

/**
 * QrView component renders an image representing a QR code.
 *
 * @component
 * @param {QrViewModel} props - The properties passed to the component.
 * @param {string} props.src - The image source in base64 format, passed from an API.
 * @param {number} props.width - The width of the QR code image in pixels.
 * @param {number} props.height - The height of the QR code image in pixels.
 * @param {string} [props.id] - Optional. The identifier for the QR code image element.
 * @param {React.CSSProperties} [props.style] - Optional. Inline styles to apply to the QR code image container.
 * @param {string} [props.className] - Optional. The CSS class name to apply to the QR code image container.
 *
 * @returns {JSX.Element} JSX element representing the QR code image.
 *
 * @example
 * // Example usage of QrView
 * <QrView
 *    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA..."
 *    width={200}
 *    height={200}
 *    id="qr-code-image"
 *    style={{ border: '2px solid black' }}
 *    className="qr-code"
 * />
 *
 * @see QrViewModel for the interface defining the properties of this component.
 * @see initialState for the default property values.
 *
 * @remarks
 * This component is responsible for displaying a QR code image, which is typically provided in base64 format by an API.
 * Ensure the `src` attribute is a valid base64 string to properly display the QR code image.
 *
 * @version 1.0.0
 * @since 2024
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img} for more about the HTML img element.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Properties} for CSS properties.
 *
 * @author
 * Gregor Schalm
 */
const QrView = (props: QrViewModel = initialState) => {
    return (
        <>
            <img
                id={props.id}
                style={props.style}
                className={props.className}
                src={props.src}
                width={props.width}
                height={props.height}
            />
        </>
    );
};

export default QrView;
