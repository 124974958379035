import { requests } from '../../../../../@WUM/core/RestFullApi/api';
import { QrApiDetailType } from "./ApiType";

/**
 * Object containing methods for interacting with QR code-related API endpoints.
 *
 * @const
 * @namespace qrroutes
 *
 * @property {Function} updatePostQR - Method to update or create a new QR code.
 *
 *
 * @remarks
 * The `qrroutes` object provides an interface for making requests to the QR code API.
 * The `updatePostQR` method is specifically used to send a POST request to create or update a QR code.
 * The response is expected to be an array of `QrApiDetailType` objects.
 *
 * @author
 * Gregor Schalm
 */
export const qrroutes = {
    /**
     * Sends a POST request to create or update a QR code.
     *
     * @function updatePostQR
     * @memberof qrroutes
     *
     * @param {any} post - The data to be sent in the request body. This can include information such as the QR code details.
     * @returns {Promise<QrApiDetailType[]>} A promise that resolves to an array of `QrApiDetailType` objects.
     *
     * @throws Will throw an error if the request fails.
     *
     * @example
     * // Example usage of updatePostQR
     * const postData = {
     *    id: 123,
     *    url: "https://example.com"
     * };
     * qrroutes.updatePostQR(postData)
     *    .then(response => {
     *        console.log('QR code updated successfully:', response);
     *    })
     *    .catch(error => {
     *        console.error('Error updating QR code:', error);
     *    });
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise} for more on Promises.
     * @see {@link QrApiDetailType} for the structure of the response.
     */
    updatePostQR: (post: any): Promise<QrApiDetailType[]> =>
        requests.post(`qr/code/generator/new/`, JSON.stringify(post)),
}
