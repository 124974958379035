import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./checkoutForm";
import {useEffect, useState} from "react";
import {ApiRoot} from "../RestFullApi/api";
import {BasicAuth} from "../RestFullApi/api";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
/** TODO: Einabu von WK  priavtekey           */

/**
 * @description Retrieves the Stripe publishable key based on the current deployment environment.
 *
 * @function PKstripe
 * @return {string} The Stripe publishable key for the current environment.
 */
const PKstripe = () => {
    let StripePK: string = ''
    if (process.env.REACT_APP_MODE === 'LOCAL') {
        StripePK = process.env.REACT_APP_API_STRIPE_LOCAL as string
    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
        StripePK = process.env.REACT_APP_API_STRIPE_DEVELOPMENT as string
    } else if (process.env.REACT_APP_MODE === 'LIVE') {
        StripePK = process.env.REACT_APP_API_STRIPE_LIVE as string
    }
    return StripePK
}

/**
 * @type {Promise<Stripe | null>}
 * @description A promise that resolves to a Stripe instance or null if Stripe fails to load.
 */
const stripePromise = loadStripe(PKstripe());

/**
 * @typedef {Object} user - Represents a user object.
 * @property {number} userID - The unique identifier for the user.
 * @property {string} email - The user's email address.
 * @property {string} firstname - The user's first name.
 * @property {string} lastname - The user's last name.
 */
interface user {
    userID: number,
    email: string,
    firstname: string,
    lastname: string
}

/**
 * @description Renders a payment form using Stripe Elements for processing payments.
 *              Fetches the payment intent from the server based on user ID and stored prices
 *              in localStorage.
 *
 * @function PaymentForm
 * @return {JSX.Element | null} The payment form component, or null if the client secret
 *                               is not yet available.
 */
export  function PaymentForm(props:{backUrl:string,id:any,class:string,StripeMethod:any,NoElements?:any, LinkAGB?: string, LinkDataProtection?: string}) {
    /**
     * @type {string | null}
     * @private
     */
    const [clientSecret, setClientSecret] = useState(null);
    /**
     * @type {Object | null}
     * @private
     */
    const user = JSON.parse(localStorage.getItem("User") as string);
    /**
     * @type {number}
     * @private
     */
    const [price, setPrice] = useState(0)

    /**
     * Logs the user ID to the console for debugging purposes.
     *
     * @private
     */


    /**
     * @type {string | null}
     * @private
     */
    let priceWebsite = localStorage.getItem("priceWebsite");
    /**
     * @type {string | null}
     * @private
     */
    let priceHosting = localStorage.getItem("priceHosting");
    /**
     * @type {string | null}
     * @private
     */
    let voucher = localStorage.getItem("priceVoucher");
    let p;

    /**
     * Fetches the payment intent from the server based on user ID and stored prices.
     * Sets the client secret and price in state upon successful retrieval.
     *
     * @private
     */

    useEffect(() => {


        if (clientSecret === null && priceWebsite && priceHosting) {

            setPrice(parseInt(priceWebsite) + parseInt(priceHosting) * 100)

            /*console.log(price)
            console.log(parseInt(priceWebsite))
            console.log(parseInt(priceHosting))*/
            fetch(ApiRoot() + 'stripe/indent/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Basic " + BasicAuth()

                },
                body: JSON.stringify({
                    total: parseInt(priceWebsite) + parseInt(priceHosting) * 100,
                    currency: 'eur',
                    "methods": props.StripeMethod,
                    "class": props.class,
                    "id": props.id
                }),
                /* class refers to table, where the payment-intent is stored and id should be the id of the respective table. */
            })
            .then(((response) => response.json()))
            .then((result: any) =>
            {
                localStorage.setItem("PaymentIntent", JSON.stringify(result));
                setClientSecret(result.secret);
            }
            ) .catch((e:any) => {
                console.error('Error fetching payment intent:', e.statusText);
            })
        }
    }, [clientSecret,props.id]);

    /**
     * Conditionally renders the Stripe Elements component and the CheckoutForm
     * only if the client secret is available.
     *
     * @return {JSX.Element | null}
     */

    //TODO Achtung: Da clientSecret nicht vorhanden ist, wird hier null ausgegeben.
    if(props.NoElements !== undefined) {
        return(
            <></>
        )
    } else {
        return (
            clientSecret ? (
                <Elements stripe={stripePromise} options={{clientSecret: clientSecret}}>
                    <CheckoutForm backUrl={props.backUrl} LinkAGB={props.LinkAGB} LinkDataProtection={props.LinkDataProtection} />
                </Elements>
            ) : null
        );
    }

}


/**
 * For Example
 *  return (
 *         <div className={props.value !== 'visibleNon' ? "Order pb-5 bpage" : "Order backendStep noneSidebar"}
 *              style={props.value === 'visibleNon' ? {backgroundImage: backgroundImage} : {backgroundColor: '#ffffff'}}>
 *             {props.value === 'visibleNon' ?
 *                 <img className="wkLogo" src={'/Logo_Webkom24.png'} alt={'Webkommunikation24 GmbH'}/> : ''}
 *             {props.value === 'visibleNon' ?
 *                 <h1>Wähle deine Zahlungsmöglichkeit</h1> :
 *                 <h1>Wähle deine Zahlungsmöglichkeit</h1>}
 *             <PaymentForm/>
 *
 *         </div>
 *     )
 *
 */
