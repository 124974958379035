import React, {useEffect, useState} from "react";
import {PersonalInfo} from "../elements/personalInfo";
import {UniCol, UniRow} from "../../../../../@WUM/core/Elements";
import {InstitutionalInfo} from "../elements/InstitutionalInfo";
import {NameBadge} from "../elements/NameBadge";
import {RegistrationDetails} from "../elements/RegistrationDetails";

interface DetaillListingInterface {
    Store: any,
    country: any,
    HasUser: any,
    Pronouns: any,
    ResponseParticipantTypeHolder: any
}


const DetaillListing = (props: DetaillListingInterface):JSX.Element => {

    const [isDisabled, setIsDisabled] = useState(true); // Initialize isDisabled state

    useEffect(() => {
        // Disable fields on mount (only once)
        if (isDisabled) {
            $(':input').attr('readonly', 'readonly');
            $(':input').attr('disabled', 'true');
            $('select').attr('disabled', 'true');
            setIsDisabled(false); // Prevent re-disabling on subsequent renders
        }
    }, [isDisabled]);


    return (
        <>
            <div className={"m-3"}>
                <h3>Congratulations! You successfully registered for ICYMARE 2024. Here you can see the data, you sent us.</h3>
                <UniRow class={"mb-5"}>
                    <UniCol lg={6} sm={12} >
                        <div className={"m-3 p-2 bg-primary-subtle shadow h-100"}>
                            <PersonalInfo Store={props.Store} Country={props.country} UserDataOld={props.HasUser}/>
                        </div>
                    </UniCol>
                    <UniCol lg={6} sm={12} >
                        <div className={"m-3 p-2 bg-primary-subtle shadow h-100"}>
                            <InstitutionalInfo Store={props.Store} Country={props.country} UserDataOld={props.HasUser}/>
                        </div>
                    </UniCol>
                </UniRow>
                <UniRow>
                    <UniCol lg={6} sm={12} >
                        <div className={"m-3 p-2 bg-primary-subtle shadow h-100"}>
                            <div>
                                <NameBadge Store={props.Store} Pronouns={props.Pronouns} UserDataOld={props.HasUser} PDFRender={true}/>
                            </div>
                        </div>
                    </UniCol>
                    <UniCol lg={6} sm={12} >
                        <div className={"m-3 p-2 bg-primary-subtle shadow h-100"}>
                            <RegistrationDetails Store={props.Store} UserDataOld={props.HasUser} participantTypes={props.ResponseParticipantTypeHolder}/>
                        </div>
                    </UniCol>
                </UniRow>

            </div>
        </>
);
};

export default DetaillListing;
