import {PaymentPageConfigModel} from "../../../../../@WUM/core/stripe/PaymentSuccess/Model/PaymentPageConfigModel";

export function PaymentPageConfiguration() {
    const configuration: PaymentPageConfigModel = {
        contactEmail: 'info@icymare.com',
        successSmiley: 'celebration',
        canceledSmiley: 'contact_support',
        processingSmiley: 'face',
        requires_actionSmiley: 'face',
        requires_captureSmiley: 'face',
        requires_confirmationSmiley: 'face',
        requires_payment_methodSmiley: 'contact_support',
        unexpectedSmiley:  'contact_support',
        nonactiveSmiley: 'contact_support',
        automaticForward: true,
        automaticForwardAim: '/survey/new/1',
        automaticForwardTime: 10000,
        callback: "WUM\\conferenceBundle\\Controller\\RegistrationController",
        callbackFunc: "assingEvent",

    };
    return configuration;
}