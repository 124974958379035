/**
 * Maps properties from props to the initialState object, overwriting existing properties.
 *
 * @param {Object} props - An object containing properties to map to the initialState.
 * @param {Object} initialState - The initial state object to be mutated.
 * @returns {Object} A new object with the initialState properties overwritten by props.
 *
 * @example
 * ```javascript
 * const initialState = { name: '', age: 0 };
 * const props = { name: 'John Doe', age: 30 };
 *
 * const State = MappingPropsToInitialState(props, initialState);
 * console.log(State); // { name: 'John Doe', age: 30 }
 * ```
 */
export const MappingPropsToInitialState = (props: any, initialState: any) => {
    const newInitialState = { ...initialState };
    for (const propName in props) {
        if (initialState.hasOwnProperty(propName) && props[propName] !== undefined) {
            newInitialState[propName] = props[propName];
        }
    }
    return newInitialState;
};