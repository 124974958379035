// src/components/PDFUniTableView.tsx
import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../css/InvoiceTable.css';
import { calculateColumnWidths } from '../../../Controller/PDFUniTableController/PDFUniTableController';
import {PDFUniTableViewModel} from "../../../Model/Elements/PDFUniTableModel";

/**
 * Renders a table view for PDF documents.
 *
 * @param {PDFUniTableViewProps} props - The properties for the table, including headers, rows, and optional widths.
 * @returns {JSX.Element} A React component that represents the table view.
 *
 * @example
 * <PDFUniTableView header={['Total invoice sum', '29,75 €']} rows={[]} widths={["84%", "16%"]} />
 */
export const PDFUniTableView = (props: PDFUniTableViewModel) => {
        const { header, rows, widths } = props;
        const columnWidths = calculateColumnWidths(header, widths);
        const stylesHeader = props.stylesHeader
        const stylesRows = props.stylesRows


    return (
        <View>
            {/* Table Header */}
            <View style={styles.tableRowHeader}>
                {header.map((col, index) => (
                    <View key={index} style={[styles.tableHeader, { width: columnWidths[index] }]}>
                        {stylesHeader && stylesHeader[index] ? (
                            <Text style={stylesHeader[index]}>{col}</Text>
                        ) : (
                            <Text style={styles.tableCell}>{col}</Text>
                        )}
                    </View>
                ))}
            </View>

            {/* Table Rows */}
            {rows.map((row, rowIndex) => (
                <View key={rowIndex} style={styles.tableRow}>
                    {row.map((col, colIndex) => (
                        <View key={colIndex} style={[styles.tableCol, { width: columnWidths[colIndex] }]}>
                            {stylesRows && stylesRows[colIndex] ? (
                                <Text style={stylesRows[colIndex]}>{col}</Text>
                            ) : (
                                <Text style={styles.tableCell}>{col}</Text>
                            )}
                        </View>
                    ))}
                </View>
            ))}
        </View>
    );
};
