import IntlMessage from '../../../../../../@WUM/core/component/const/IntlMessage';
import React from "react";

/**
 *
 * @memberOf blog
 * @prop conf
 * @prop response
 * @prop page
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
function configuration(response:any,endpoint:any='') {

    return {
        tableBody: {
            data: response,
            dataID: 'uid',
            underdata: true,
            keys: [
                'id',
                'price',
                'discount',
                'mail',
                'name',
                'invoiceDate',
                'invoiceNumber',
                'comment'
            ],
            type: ['string', 'string', 'string', "string", "string", "string", "string", 'string'],
        },
        tableHeader: [
            'ID',
            'Price',
            'Discount',
            'Mail',
            'Name',
            'Invoice Date',
            'Invoice Number',
            'Comment'
        ],
        tableHeaderWidth: ['auto', 'auto','auto','auto','auto','auto','auto'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menutriggerDelete: true,
            menuItem: [
                'Show'
            ],
            menuroute: ['/registration/Detail'+endpoint],
            menuParams: ['', 'uid', 'uid'],
            menuIcons: ['pencil'],
        },
    }
}

export default configuration;
