import { PDFUniTextView, PDFUniRow } from "./Elements";
import { styles } from "./css/CompanyInfo.css";
import { initialState, CompanyInfoModel } from "../Model/CompanyInfoModel";
import {MappingPropsToInitialState} from "../../../../../@WUM/core/Function/MappingPropsToInitialState/MappingPropsToInitialState";

/**
 * Renders company information within a PDF document.
 *
 * @param {CompanyInfoModel} props - An object containing company information.
 *        If not provided, defaults to the `initialState` defined in `CompanyInfo.interface.js`.
 *
 * @returns {JSX.Element} A JSX element representing the company information section.
 */
const CompanyInfoView = (props: CompanyInfoModel = initialState): JSX.Element => {
    /**
     * Merges props with initial state, ensuring only defined props are used.
     *
     * @param {CompanyInfoModel} props - An object containing company information.
     * @param {CompanyInfoModel} initialState - The default state for company information.
     *
     * @returns {CompanyInfoModel} A new object with merged props and initial state.
     */
    const State = MappingPropsToInitialState(props, initialState);

    return (
        <PDFUniRow classRow={styles.companyInfo}>
            <PDFUniTextView text={State.company} />
            <PDFUniTextView text={State.street} />
            <PDFUniTextView text={State.city} />
        </PDFUniRow>
    );
};

export default CompanyInfoView;
