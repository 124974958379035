import {useEffect, useState} from "react";
import {abstractApiDetail} from "../request/abstractAPI.interface";
import {session} from "../request/session";
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";

export const ResponseSession = (limit:any,searchValue:any) => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await session.getPosts(limit,searchValue)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [limit,searchValue])


    return isError ? (posts ) : (posts);

}

export const ResponseSessionSingle = (id:any) => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await session.getAPost(id)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [id])


    return isError ? (posts ) : (posts);

}

export const ResponseSessionSingleDeleted = (id:any) => {
    const [posts, setPosts] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await session.deletePost(id)
                .then((data) => {
                    checkLogin(data)
                    Notification({type:'success', mode:'classic', title:{text:'Success'}, text:{text: 'Successfully deleted'}, insert:'top',duration:4})
                    setPosts(true);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [id])

    return isError ? (posts ) : (posts);

}


export const ResponseAuthor = () => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await session.getPostsAuhtor()
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [])

    return isError ? (posts ) : (posts);

}
