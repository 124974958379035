import React from 'react';
import RegistrationNew from "./modules/Pages/new";
import RegistrationOverview from "./modules/Pages/overview";
import RegistrationDetail from "./modules/Pages/detail";
import {useSelector} from "react-redux";
import PaymentStatusPage from "../../../@WUM/core/stripe/PaymentSuccess/Controller/PaymentPageController";
import {PaymentPageConfiguration} from "./modules/configuration/PaymentPageConfiguration";

export const RegistrationConferenceConfig = (value: any) => {
    const Store = useSelector((state: any) => state)
    let Role = Store.user.roles

    switch (Role) {
        case 'user':
            return [
                {
                    path: '/registration/new/:page',
                    elements: <RegistrationNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/detail/:id',
                    elements: <RegistrationDetail value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/success/:id',
                    elements: <PaymentStatusPage value={value} configuration={PaymentPageConfiguration()} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/bypass/success/:id',
                    elements: <PaymentStatusPage value={value} configuration={PaymentPageConfiguration()} NonStripePath={true} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ];
        case 'host':
            return [
                {
                    path: '/registration/new/:page',
                    elements: <RegistrationNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/detail/:id',
                    elements: <RegistrationDetail value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/success/:id',
                    elements: <PaymentStatusPage value={value} configuration={PaymentPageConfiguration()} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/bypass/success/:id',
                    elements: <PaymentStatusPage value={value} configuration={PaymentPageConfiguration()} NonStripePath={true} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ];
        case 'icymare':
            return [
                {
                    path: '/registration/overview/:page',
                    elements: <RegistrationOverview value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/new',
                    elements: <RegistrationNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/new/:page',
                    elements: <RegistrationNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/detail/:id',
                    elements: <RegistrationDetail value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/success/:id',
                    elements: <PaymentStatusPage value={value} configuration={PaymentPageConfiguration()} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/bypass/success/:id',
                    elements: <PaymentStatusPage value={value} configuration={PaymentPageConfiguration()} NonStripePath={true} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ];
        case 'superadmin':

            return [
                {
                    path: '/registration/overview/:page',
                    elements: <RegistrationOverview value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/new',
                    elements: <RegistrationNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/new/:page',
                    elements: <RegistrationNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/detail/:id',
                    elements: <RegistrationDetail value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/success/:id',
                    elements: <PaymentStatusPage value={value} configuration={PaymentPageConfiguration()} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/bypass/success/:id',
                    elements: <PaymentStatusPage value={value} configuration={PaymentPageConfiguration()} NonStripePath={true} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ];
        default:
            return [
                {
                    path: '/registration/new',
                    elements: <RegistrationNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/new/:page',
                    elements: <RegistrationNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/detail/:id',
                    elements: <RegistrationDetail value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/success/:id',
                    elements: <PaymentStatusPage value={value} configuration={PaymentPageConfiguration()} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/registration/bypass/success/:id',
                    elements: <PaymentStatusPage value={value} configuration={PaymentPageConfiguration()} NonStripePath={true} />,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ];
    }

}

export const RegistrationmenuConfig = (value: any) => {
    const Store = useSelector((state: any) => state)
    let Role = Store.user.roles

    switch (Role) {
        case 'user':
            return [
                {
                    name: 'Registration',
                    route: '/registration/new/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ]
        case 'host':
            return [
                {
                    name: 'Registration',
                    route: '/registration/new/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ]
        case 'icymare':
            return [
                {
                    name: 'Registration Overview',
                    route: '/registration/overview/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                // {
                //     name: 'Registration Overview',
                //     route: '/registration/overview-user/1',
                //     icon: 'dashboard',
                //     roles: [
                //         'superadmin',
                //         'admin',
                //         'host',
                //         'user',
                //         'icymare'
                //     ]
                // },
                {
                    name: 'Registration',
                    route: '/registration/new/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ]
        case 'superadmin':
            return [
                {
                    name: 'Registration Overview',
                    route: '/registration/overview/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    name: 'Registration',
                    route: '/registration/new/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ]
        default:
            return [
                {
                    name: 'Registration',
                    route: '/registration/new/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ]
    }

}

export const RegistrationMainMenuConfig = (value: any) => {
    return [
        {
            header: 'Registration',
            icon: 'how_to_reg',
            menuitem: [
                ...RegistrationmenuConfig(value)
            ],
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
    ];
}
