import {useEffect, useState} from "react";
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";
import {error} from "../../../../../@WUM/core/Elements/index";
import {participantInfo, profilApiType} from "../Request/profilAPI.interface";
import {profil} from "../Request/profil";
import {InvoiceDataModel} from "../../../../components/Elements/pdf/Model/InvoiceDataModel";
import {invoiceAPIOverview} from "./invoiceAPI.interface";
import {invoice} from "./invoice";
// @ts-ignore
import {Notification} from "create-notifications/notfication";



export const ResponseAbstractsOverviewAll = (year:any, offset:any, page: any) => {
    const [posts, setPosts] = useState<InvoiceDataModel[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await profil.getAbstractsOverviewAll(year, offset, page)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [year, offset, page])


    return isError ? (posts ) : (posts);

}
