import {FormatDatesLongEnglishController} from "../../MuiTabpanel/Controller/FormatDatesLongEnglish.Controller";

interface HeaderProps {
    header: IcyEvent
}

interface IcyEvent {
    name: string;
    date: string; // Du könntest hier auch ein Date-Objekt verwenden
    dateEnd: string; // Ebenso hier
    scheduleName: string;
}

const HeaderView: React.FC<HeaderProps> = (props:HeaderProps) => {

    const dateInfo = FormatDatesLongEnglishController(props.header.date, props.header.dateEnd)

    return (
        <>
            <div className={"text-white event-calendar-header"}>
                <div className={"text-center"}>
                    {props.header.scheduleName}
                </div>
                <div className={"text-center event-calendar-header-date"}>
                    {dateInfo}
                </div>
            </div>
        </>
    )
}

export default HeaderView;