import { styles } from "../../View/css/Elements/InitialState.css";
import React from "react";

/**
 * @author Gregor Schalm
 * @typedef {Object} PDFUniHorizontalLineModel - An interface defining the properties of a horizontal line element in the PDF.
 * @property {string} [width] - Optional width of the line. Defaults to "1".
 * @property {string} [begin] - Optional x-coordinate of the line's starting point. Defaults to "0".
 * @property {number} [strokeWidth] - Optional stroke width of the line. Defaults to 1.
 * @property {string} [color] - Optional color of the line in hex format. Defaults to "#153A67".
 * @property {string} [length] - Optional length of the line. Defaults to "377" (Recommendation: Use numbers with units like millimeters).
 * @property {boolean} [debug] - Optional flag to enable debug mode. Defaults to false.
 * @property {CSSStyleDeclaration} [style] - Optional inline styles for the line.
 * @property {string} [class] - Optional CSS class name for the line.
 */

export interface PDFUniHorizontalLineModel {
    width?: string;
    begin?: string;
    strokeWidth?: number;
    color?: string;
    length?: string; // Consider using string with unit suffix (e.g., "1mm")
    debug?: boolean;
    style?: React.CSSProperties;
    class?: any;
}

/**
 * @constant {PDFUniHorizontalLineModel} initialState - The default initial state for the PDFUniHorizontalLine component.
 *  - Contains default values for width, begin point, stroke width, color, length, and debug flag.
 */

export const initialState: PDFUniHorizontalLineModel = {
    width: "1",
    begin: "0",
    strokeWidth: 1,
    color: "#31718f",
    length: "377", // Recommendation: Use numbers with units
    debug: false,
    class: styles.empty,
    style: { fontSize: 'inherit' },
};
