import {useSelector} from "react-redux";
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import {ApiURL, UniCol, UniForm, UniRow} from "../../../../../@WUM/core/Elements";
import React, { useState } from "react";
import {init2} from "../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {getToken} from "../../../../../@WUM/core/component/const/getToken";
import {Survey} from "../elements/Survey";
import {ResponseACountry, ResponseHasUser, ResponsePronouns} from "../../API/request/abstract";
import {Button} from "react-bootstrap";

const SurveyNew = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)

    const [RegistrationID, setRegistrationID] = useState(0)
    //console.log(RegistrationID + ' RegistrationID start')

    // prepares holder which contain all countries for select fields
    let Country = ResponseACountry() /*<-- Einbinden des Ergebnisses */
    let CountryHolder = []; /* <-- Bildung des Holders */
    if (Country !== true && typeof Country !== "boolean" && Country?.length > 0) { /* <-- Sicherheitsabfrage ob Route durch ist */
        for (let us = 0; us < Country?.length; us++) { /* <-- Durchgang durch ergebniss  */
            let text = Country[us].valueEN
            CountryHolder.push( /* <-- Push ins Array des Fetches */
                {value:Country[us].id,text:text}
            )
        }
    }
    // prepares holder containing pronouns
    let Pronouns = ResponsePronouns() /*<-- Einbinden des Ergebnisses */
    let PronounsHolder:any = []; /* <-- Bildung des Holders */
    if (Pronouns !== true && typeof Pronouns !== "boolean" && Pronouns?.length > 0) { /* <-- Sicherheitsabfrage ob Route durch ist */
        for (let us = 0; us < Pronouns?.length; us++) { /* <-- Durchgang durch ergebniss  */
            PronounsHolder.push( /* <-- Push ins Array des Fetches */
                {value:Pronouns[us].id,text:Pronouns[us].pronoun}
            )
        }
    }


    const send = (e: any) => {
        const form = e.currentTarget
        e.preventDefault() /* <-- Unterbrechen des normalen Form Event und Reloads */
        let Data = init2(form) /* <-- Einbinden der Init2 methode die automatisch alle Form Felder mit name parse */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());/* <-- Header mit Bearer Token **/

        var raw = JSON.stringify(
            {
                "social": (Data.social !== undefined && Data.social !== ''  ? 1 : 0),
                "recommendation": (Data.recommendation !== undefined && Data.recommendation !== ''  ? 1 : 0),
                "sciencespeedmeeting": (Data.sciencespeedmeeting !== undefined && Data.sciencespeedmeeting !== ''  ? 1 : 0),
                "others": (Data.others !== undefined && Data.others !== ''  ? 1 : 0),
                "monday": (Data.monday !== undefined && Data.monday !== ''  ? 1 : 0),
                "tuesday": (Data.tuesday !== undefined && Data.tuesday !== ''  ? 1 : 0),
                "wednesday": (Data.wednesday !== undefined && Data.wednesday !== ''  ? 1 : 0),
                "thursday": (Data.thursday !== undefined && Data.thursday !== ''  ? 1 : 0),
                "friday": (Data.friday !== undefined && Data.friday !== ''  ? 1 : 0),
                "nonbinary": (Data.nonbinary !== undefined && Data.nonbinary !== ''  ? 1 : 0),
                "woman": (Data.woman !== undefined && Data.woman !== ''  ? 1 : 0),
                "man": (Data.man !== undefined && Data.man !== ''  ? 1 : 0),
                "declined": (Data.declined !== undefined && Data.declined !== ''  ? 1 : 0),
                "othersSex": (Data.othersSex !== undefined && Data.othersSex !== ''  ? 1 : 0),
                "u18": (Data.u18 !== undefined && Data.u18 !== ''  ? 1 : 0),
                "_18to35": (Data.f18to35 !== undefined && Data.f18to35 !== ''  ? 1 : 0),
                "o35": (Data.o35 !== undefined && Data.o35 !== ''  ? 1 : 0),
                "bsc": (Data.bsc !== undefined && Data.bsc !== ''  ? 1 : 0),
                "msc": (Data.msc !== undefined && Data.msc !== ''  ? 1 : 0),
                "phd": (Data.phd !== undefined && Data.phd !== ''  ? 1 : 0),
                "othersEducation": (Data.othersEducation !== undefined && Data.othersEducation !== ''  ? 1 : 0),
                "otherSexText": Data.otherSexText,
                "otherDegreeText": Data.otherDegreeText,
                "countryOrigin": Data.countryOrigin,
                "currentCountry": Data.countryCurrent,
                "year": (new Date() as Date).getFullYear()
            }
        );

        var requestOptions = {
            method: 'POST', /* <-- gewählte Methode */
            headers: myHeaders, /* <-- Anbindung Headers */
            body: raw, /* <-- Anbindung der oben gebildeten Objeckt an den Body */
        };

        fetch(ApiURL() +"conf/survey/0/", requestOptions) /* <-- Route mittels APIURL und endpoint sowie pbergabe der RequestOptions mit method, Header und Body */
            .then(response => response.json())
            .then(result => {SuccesRegistrion(result)}) /* <-- Funktion die bei Success ausgeführt wird. Obacht hier ein beispiel kann auch eine andere eigene oder oder hin. wen keine Funktion einfach console.log(result) rein */
            .catch(error => console.log(error));/* <-- Funktion die bei einen error fall ausgelöst wird selbiges wie oben bei keiner funktion einfach console.log(error) rein */

    }
    //ToDo austauschen gegen andere Seite.
    const SuccesRegistrion = (result:any) =>  {
        window.location.href = "/"
    }

    if (1 === 1) {

        if (Country !== true && typeof Country !== "boolean" && Country?.length > 0 &&
            Pronouns !== true && typeof Pronouns !== "boolean" && Pronouns?.length > 0 &&
            process.env.REACT_APP_REGISTRATION_ACTIVE !== undefined && process.env.REACT_APP_REGISTRATION_ACTIVE === 'True') {
            return (
                <>
                    <UniForm validated={false} Submit={(e: any)=> send(e)}
                             className="p-3 pb-5"

                    >
                                <Survey Store={Store} Country={CountryHolder}/>
                        <Button variant="primary" className={'float-end'} id={'sendButton'} type="submit">
                             Send
                        </Button>



                    </UniForm>

                </>)
        } else {
            return (
                <>
                    <div className={"m-3"}>
                        <h1>Registration is closed!</h1>
                        <p>Hi there!</p>
                        <p>Registration is closed at the moment
                        </p>
                    </div>
                </>
            );
        }
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default SurveyNew


