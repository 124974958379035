import { View } from "@react-pdf/renderer";
import { initialState, PDFUniRowModel } from "../../../Model/Elements/PDFUniRowModel";

export const PDFUniRow = (props: PDFUniRowModel = initialState) => {
    const combinedStyles = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: props.alignItems,
        justifyContent: props.justifyContent,
        flexWrap: props.wrap,
        ...props.styleRow,
    };

    return (
        <View style={[props.classRow, combinedStyles]} debug={props.debug} fixed={props.fixed}>
            {props.children}
        </View>
    );
};
