import React from 'react';
import {styles} from "../../View/css/Elements/InitialState.css";

/**
 * @typedef {Object} PDFUniRowModel - Interface for PDFUniRow component props.
 * @property {string} [alignItems] - Flexbox alignment for items (defaults to 'flex-start').
 * @property {string} [justifyContent] - Flexbox justification for items (defaults to 'flex-start').
 * @property {'wrap' | 'nowrap'} [wrap] - Controls whether flex items wrap (defaults to 'wrap').
 * @property {React.ReactNode} children - The content to be rendered within the View component.
 * @property {string} [classRow] - Optional class name for styling the View component (from CSS).
 * @property {React.CSSProperties} [styleRow] - Optional inline styles for the View component.
 * @property {boolean} [debug] - Optional state of debug mode for row component.
 */

export interface PDFUniRowModel {
    alignItems?: 'flex-start' | 'flex-end' | 'center';
    justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
    wrap?: 'wrap' | 'nowrap';
    children: React.ReactNode;
    classRow?: any,
    styleRow?: React.CSSProperties;
    debug?: boolean;
    fixed?: boolean
}

/**
 * @constant {PDFUniRowModel} initialState - Default initial state for the PDFUniRow component.
 */

export const initialState: PDFUniRowModel = {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    wrap: 'wrap',
    children: null,
    classRow: styles.empty,
    styleRow: { fontSize: 'inherit' },
    debug: false,
    fixed: false
};