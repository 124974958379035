/**
 * Sets the read-only state and value of a target input field based on the source value.
 *
 * @param {Event} e The event object triggered by the source element change.
 * @param {any} sourceValue The value to compare with the source element's value.
 * @param {string} targetID The ID of the target HTMLInputElement to be modified.
 * @returns {void}
 */
export const OtherSet = (e:any, sourceValue:any | any[], targetID:string): void => {
    /* Initialize target field */
    const Field = document.getElementById(targetID) as HTMLInputElement;
    const valueToCheck = e.target.value;

    /* Check if source value equals the search value */
    if (e.target.value == sourceValue) {
        /* Set target readOnly to false */
        Field.readOnly = false;
    } else {
        /* Set target readOnly to true */
        Field.readOnly = true;
        /* Clear target value */
        Field.value = '';
    }
    return;
};

/**
 * Sets the readOnly property of a target input field based on a comparison
 * with a source value or an array of source values (all assumed to be strings or numbers).
 *
 * **Note:** Due to the use of `any` for parameter types, the function's behavior
 * might be unexpected for non-string or non-numeric values.
 *
 * @param {any} e The event object triggered by a change in an input field.
 *                 (Ideally should be ChangeEvent<HTMLInputElement>)
 * @param {any} sourceValues The source value (string or number) or an array of source values (strings or numbers) to compare with the input value.
 * @param {string} targetID The ID of the target input field whose readOnly property will be modified.
 * @returns {void}
 */
export const OtherSet2 = (
    e: any,
    sourceValues: any,
    targetID: string
): void => {
    /* Initialize target field */
    const Field = document.getElementById(targetID) as HTMLInputElement;

    const valueToCheck = parseFloat(e.target.value);

    /* Check if source value is an array or a string */
    if (Array.isArray(sourceValues)) {
        // Multiple source values comparison
        //console.log("is array")
        if (sourceValues.includes(valueToCheck)) {
            Field.readOnly = false;
            //console.log("included " + valueToCheck)
        } else {
            /* Set target readOnly to true */
            Field.readOnly = true;
            /* Clear target value */
            Field.value = '';
            //console.log("not included " + valueToCheck)
        }
    } else {
        // Single source value comparison
        if (valueToCheck === sourceValues) {
            Field.readOnly = false;
        } else {
            /* Set target readOnly to true */
            Field.readOnly = true;
            /* Clear target value */
            Field.value = '';
        }
    }

    return;
};

/**
 * Sets the readOnly property of a target field based on the checked state of a checkbox.
 *
 * @param {string} targetID - The ID of the target field (HTMLInputElement).
 * @param {string} checkboxId - The ID of the checkbox element.
 * @returns {void}
 */

export const OtherSetCheckbox = (targetID: string, checkboxId: string): void => {
    const Field = document.getElementById(targetID) as HTMLInputElement;
    const Checkbox = document.getElementById(checkboxId) as HTMLInputElement;

    if (Checkbox.checked) {
        Field.readOnly = false;
    } else {
        Field.readOnly = true;
        Field.value = "";
    }
};


