import {StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    // Rand für die gesamte Seite
    page: {
        paddingLeft: '20mm',
        paddingRight: '6mm',
        paddingTop:'10mm',
        paddingBottom: '6mm',
    },
    columnContent: {
        width: "140mm",
        paddingRight: "8mm"
    }

});
