import {CommentInvoiceModel, initialState} from "../Model/CommentInvoiceModel";

/**
 * @author Gregor Schalm
 * Generates a comment based on the payment status and optional bypass indication.
 * @param {string} status The status of the payment.
 * @param {boolean} [NonStripePath] Indicates whether the payment bypassed Stripe (optional).
 * @returns {string} The comment describing the payment method and status.
 */
const CommentInvoiceController = (props: CommentInvoiceModel = initialState): string => {
    if (props.NonStripePath === true) {
        return (
            "Coupon - " + props.status
        );
    } else {
        return (
            "Payment via stripe - " + props.status
        );
    }
}

export default CommentInvoiceController;
