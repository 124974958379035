import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import React from "react";


const Authors = (props:{author:any,readonly?:any,mode?:any,addElement?:any}) => {

    let author = props.author;
    const deleteElement = (e:any) => {
        let element = e.target.parentElement.parentElement
        element.remove();
    }
    if(author === undefined) {
        return (<><InputGroup className="mb-3 inputGroupExtra form-control w250" >
            <InputGroup.Text>
                1
            </InputGroup.Text>
            <Form.Control
                readOnly={(props.readonly !== undefined && props.readonly !== null ? (props.mode === 'icymare' ? false :props.readonly) : (props.mode === 'icymare' ? false : true))}
                name={'Authors0'}
                placeholder="No Author find"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                className={'inputNoBorder'}
            />

        </InputGroup></>)
    }

    let authorContainer = [];

    for(let a=0;a<author.length;a++){
    authorContainer.push(
        <>
            <InputGroup className="mb-3 inputGroupExtra form-control w250" >
                <InputGroup.Text>
                    {a+1}
                </InputGroup.Text>
                <Form.Control
                    readOnly={(props.readonly !== undefined && props.readonly !== null ? (props.mode === 'icymare' ? false :props.readonly) : (props.mode === 'icymare' ? false : true))}
                    name={'Authors'+a}
                    placeholder="Author"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                    defaultValue={author[a].data[0].name}
                />
                {props.mode !== undefined && props.mode === 'icymare' ?
                    <>
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                 onClick={()=>props.addElement(false,'4','Authors','w250',true)}
                >
                                <span className="material-icons">
                                    add
                                </span>
                </InputGroup.Text>
                    <InputGroup.Text id="basic-addon2" className={'IconPlus'}>
                 <span
                     className="material-symbols-outlined" style={{color: '#317391'}} onClick={(e) => deleteElement(e)}>
                    delete
                    </span>
                    </InputGroup.Text></>
                    :<></>
                }
            </InputGroup>
        </>
    )
    }


    return (
        <Row id={'Authors'}>
            <div className={'col-4'}>
                {authorContainer}
            </div>
        </Row>
    )

}

export default Authors;
