import { CSSProperties } from "react";

/**
 * Interface representing the model for the QrView component.
 *
 * @interface QrViewModel
 * @property {string} src - The source of the QR code image in base64 format.
 * @property {number} width - The width of the QR code image in pixels.
 * @property {number} height - The height of the QR code image in pixels.
 * @property {string} [className] - Optional. The CSS class name for the QR code image container.
 * @property {CSSProperties} [style] - Optional. Inline styles to apply to the QR code image container.
 * @property {string} [id] - Optional. The identifier for the QR code image element.
 *
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img} for more about the HTML img element.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Properties} for CSS properties.
 *
 * @version 1.0.0
 * @since 2024
 *
 * @author
 * Gregor Schalm
 */
export interface QrViewModel {
    src: string;
    width: number;
    height: number;
    className?: string;
    style?: CSSProperties;
    id?: string;
}

/**
 * The initial state for the QrView component.
 *
 * @constant
 * @type {QrViewModel}
 * @default
 * @property {string} src - An empty string as the default source of the QR code image.
 * @property {number} width - Default width set to 200 pixels.
 * @property {number} height - Default height set to 200 pixels.
 * @property {string} [className] - Default class name is undefined.
 * @property {CSSProperties} [style] - Default style is undefined.
 * @property {string} [id] - Default identifier is undefined.
 *
 * @example
 * // Example usage of initialState
 * const initialQrViewState = initialState;
 * console.log(initialQrViewState.width); // Outputs: 200
 *
 * @see QrViewModel for the structure of the initial state.
 */
export const initialState: QrViewModel = {
    src: '',
    width: 200,
    height: 200
};
