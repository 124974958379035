import React from 'react';
import RegistrationNew from "./modules/Pages/new";
import RegistrationOverview from "./modules/Pages/overview";
import RegistrationDetail from "./modules/Pages/detail";
import {useSelector} from "react-redux";
import SurveyNew from "./modules/Pages/new";

export const SurveyConferenceConfig = (value: any) => {
    const Store = useSelector((state: any) => state)
    let Role = Store.user.roles

    switch (Role) {
        case 'user':
            return [
                {
                    path: '/survey/new',
                    elements: <SurveyNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/survey/new/:page',
                    elements: <SurveyNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ];
        case 'host':
            return [
                {
                    path: '/survey/new',
                    elements: <SurveyNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ];
        case 'icymare':
            return [
                {
                    path: '/survey/overview/:page',
                    elements: <RegistrationOverview value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/survey/new',
                    elements: <SurveyNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/survey/new/:page',
                    elements: <SurveyNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ];
        case 'superadmin':

            return [
                {
                    path: '/survey/overview/:page',
                    elements: <RegistrationOverview value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/survey/new',
                    elements: <SurveyNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/survey/new/:page',
                    elements: <SurveyNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ];
        default:
            return [
            ];
    }

}

export const SurveyMenuConfig = (value: any) => {
    const Store = useSelector((state: any) => state)
    let Role = Store.user.roles

    switch (Role) {
        case 'user':
            return [
            ]
        case 'host':
            return [
            ]
        case 'icymare':
            return [
                {
                    name: 'Survey Results',
                    route: '/survey/overview/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    name: 'Survey',
                    route: '/survey/new/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ]
        case 'superadmin':
            return [
                {
                    name: 'Survey Results',
                    route: '/survey/overview/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    name: 'Survey',
                    route: '/survey/new/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ]
        default:
            return [
            ]
    }

}

export const SurveyMainMenuConfig = (value: any) => {
    return [
        {
            header: 'Survey',
            icon: 'poll',
            menuitem: [
                ...SurveyMenuConfig(value)
            ],
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
    ];
}
