// Import German messages for the ticketing system from the JSON file
import deTicketsystemMessages from "../modules/ticketsystem/modules/localization/locales/de_DE.json";

// Import German messages for the CMS from the JSON file
import deIcymareMessages from "../modules/CMS/localization/locales/de_DE.json"

// Import English messages for the ticketing system from the JSON file
import enTicketsystemMessages from "../modules/ticketsystem/modules/localization/locales/en_US.json";

// Import English messages for the CMS from the JSON file
import enIcymareMessages from "../modules/CMS/localization/locales/en_US.json"

/**
 * Registers German messages by combining messages from the ticketing system and CMS.
 * @constant
 * @type {Object}
 */
export const RegisterLanguageDE = {
    ...deTicketsystemMessages,
    ...deIcymareMessages
}

/**
 * Registers English messages by combining messages from the ticketing system and CMS.
 * @constant
 * @type {Object}
 */
export const RegisterLanguageEN = {
    ...enTicketsystemMessages,
    ...enIcymareMessages
}

/**
 * German language object containing all messages and the locale information.
 * @constant
 * @type {Object}
 */
const deLang = {
    messages: {
        ...RegisterLanguageDE
    },
    locale: 'de',
};

/**
 * English language object containing all messages and the locale information.
 * @constant
 * @type {Object}
 */
const EnLang = {
    messages: {
        ...RegisterLanguageEN
    },
    locale: 'en',
};

/**
 * Contains the language configurations for the application.
 * The key names represent language codes and the values are the respective language objects.
 * @constant
 * @type {Object.<string, {messages: Object, locale: string}>}
 */
export const AppLocale: any = {
    "de": deLang,
    "en": EnLang
};
