import {httpClient, httpClientWithAuth, responseBody} from "../../../../@WUM/core/RestFullApi/api";
import {configAxiosUnauth} from "./configAxiosUnauth";

export const requestsUnauth = {
    get: (url: string) =>
        (process.env.REACT_APP_AUTH !== undefined
            ? httpClientWithAuth
            : httpClient
        )
            .get(url, configAxiosUnauth)
            .then(responseBody),
    post: (url: string, body: {}) =>
        (process.env.REACT_APP_AUTH !== undefined
            ? httpClientWithAuth
            : httpClient
        )
            .post(url, body, configAxiosUnauth)
            .then(responseBody),
    put: (url: string, body: {}) =>
        (process.env.REACT_APP_AUTH !== undefined
            ? httpClientWithAuth
            : httpClient
        )
            .put(url, body, configAxiosUnauth)
            .then(responseBody),
    patch: (url: string, body: {}) =>
        (process.env.REACT_APP_AUTH !== undefined
            ? httpClientWithAuth
            : httpClient
        )
            .patch(url, body, configAxiosUnauth)
            .then(responseBody),
    delete: (url: string) =>
        (process.env.REACT_APP_AUTH !== undefined
            ? httpClientWithAuth
            : httpClient
        )
            .delete(url, configAxiosUnauth)
            .then(responseBody)
}
