import React from 'react';
import SessionOverview from "./Pages/overview";
import SessionDetail from "./Pages/detail";
import SessionNew from "./Pages/new";
import RewriteDeleted from "./Pages/deleted";

export const EducationPagesConfig = (value: any) => {

    return [
        {
            path: '/Education/overview/:page',
            elements: <SessionOverview value={value}/>,
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
        {
            path: '/Education/Detail/:id',
            elements: <SessionDetail value={value}/>,
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
        {
            path: '/Education/Delete/:page/:deleteID',
            elements: <RewriteDeleted value={value}/>,
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
        {
            path: '/Education/new/:page',
            elements: <SessionNew value={value}/>,
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
    ];
}

export const EducationMenuConfig = (value: any) => {
    return [
        {
            name: 'Education Level',
            route: '/Education/overview/1',
            icon: 'school',
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        }
    ]
}
