import {StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    logo: {
        width: "45.1mm"
    },
    highlightText: {
        fontFamily: "Days One",
        fontSize: '8pt',
        marginTop: '13mm'
    },
    standardText: {
        fontFamily: "Raleway",
        fontSize: '8pt',
        width: '45.1mm'
    },
    highlightBluetextBold: {
        fontFamily: "Raleway",
        fontSize: '8pt',
        fontWeight: "bold",
        color: "#153A67"
    },
    highlightBluetext: {
        fontFamily: "Raleway",
        fontSize: '8pt',
        color: "#153A67"
    }

});
