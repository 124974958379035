/**
 * Imports necessary components and styles.
 *
 * @typedef {Object} CompanyInfoModel - Interface defining the structure of CompanyInfo data.
 * @property {string} company - The company name.
 * @property {string} street - The company street address.
 * @property {string} city - The company city.
 */
export interface CompanyInfoModel {
    company?: string,
    street?: string,
    city?: string
}

export const initialState:CompanyInfoModel = {
    company: "ICYMARE  |  ",
    street: "Bahnhofsplatz 13  |  ",
    city: "28195 Bremen"
}