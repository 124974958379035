import {abstractApiDetail} from "./abstractAPI.interface";
import {requests} from '../../../../../@WUM/core/RestFullApi/api';

export const session = {
    getPosts: (limit: any, searchValue: any): Promise<abstractApiDetail[]> => requests.get(`Education/Levels/All/${limit}/${searchValue}`),
    getAPost: (id: string | undefined): Promise<abstractApiDetail[]> => requests.get(`Conf/Levels/${id}/`),
    createPost: (post: abstractApiDetail, id: number): Promise<abstractApiDetail> =>
        requests.post(`Conf/Levels/${id}/`, post),
    updatePost: (post: any, id: number): Promise<abstractApiDetail> =>
        requests.post(`Conf/Levels/${id}/`, post),
    deletePost: (id: string): Promise<void> => requests.delete(`Conf/Levels/${id}/`),
    getLevel: (): Promise<abstractApiDetail[]> => requests.get(`Education/Levels/`),
    getPrasentationType: (): Promise<abstractApiDetail[]> => requests.get(`Presentation/Types/`),
    getSession: (): Promise<abstractApiDetail[]> => requests.get(`Conf/Sessions/`),
    getPostsAuhtor: (): Promise<abstractApiDetail[]> => requests.get(`User/Author/`),
};
