import {PDFUniTextView, PDFUniRow} from "./Elements/index";
import {styles} from "./css/CustomerAddress.css";
import {CustomerAddressModel, initialState} from "../Model/CustomerAddressModel";
import {View} from "@react-pdf/renderer";


const CustomerAddress = (props: CustomerAddressModel = initialState) => {
    // console.log(props.data) // shows response
    return (
        <View style={styles.customerContainer}>
            <PDFUniTextView text={props.data.name} classText={styles.customer}/>
            <PDFUniTextView text={props.data.invoiceAddress} classText={styles.customer}/>
            <PDFUniTextView text={props.data.city + ", " + props.data.countryName.valueEN} classText={styles.customer}/>
        </View>
    );
};

export default CustomerAddress;
