import {Font} from "@react-pdf/renderer";
// @ts-ignore
import Regular from "./DaysOne-Regular.ttf";

const DaysOneFontsRegister = () => {
    Font.register({
        family: 'Days One',
        fonts: [
            {
                src: Regular,
                fontWeight: "normal"
            },
        ]
    })
}

export default DaysOneFontsRegister;