import React from 'react';
import PresentationDetail from "./Pages/detail";
import PresentationOverview from "./Pages/overview";

export const PresentationSettingsPagesConfig = (value: any) => {

    return [
        {
            path: '/settings/presentation/overview/:page',
            elements: <PresentationOverview value={value}/>,
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
        {
            path: '/settings/presentation/Detail/:id',
            elements: <PresentationDetail value={value}/>,
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
    ];
}

export const PresentationSettingsMenuConfig = (value: any) => {
    return [
            {
                name: 'Presentation Overview',
                route: '/settings/presentation/overview/1',
                icon: 'co_present',
                roles: [
                    'superadmin',
                    'admin',
                    'host',
                    'user',
                    'icymare'
                ]
            }
        ]
}
