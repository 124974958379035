import {abstractApiDetail} from "./abstractAPI.interface";
import {requests} from '../../../../../@WUM/core/RestFullApi/api';
import {HasUserInterface} from "./HasUser.interface";

export const abstract = {
    getPosts: (limit: any, searchValue: any): Promise<abstractApiDetail[]> => requests.get(`Conf/registration/All/${limit}/${searchValue}`),
    getPostsUser: (limit: any, searchValue: any,userid:any): Promise<abstractApiDetail[]> => requests.get(`Conference/Presentations/User/${limit}/${userid}/${searchValue}`),
    getPostsHost: (limit: any, searchValue: any,userid:any): Promise<abstractApiDetail[]> => requests.get(`Conference/Presentations/host/${limit}/${userid}/${searchValue}`),
    getAPost: (id: string | undefined): Promise<abstractApiDetail[]> => requests.get(`Conference/Presentation/${id}/`),
    getHasUser: (id: string | undefined,years:string | undefined): Promise<HasUserInterface[]> => requests.get(`Conf/registration/user/details/${id}/${years}/`),
    createPost: (post: abstractApiDetail, id: number): Promise<abstractApiDetail> =>
        requests.post(`Conference/Presentation/${id}/`, post),
    updatePost: (post: any, id: number): Promise<abstractApiDetail> =>
        requests.post(`Conference/Presentation/${id}/`, post),
    deletePost: (id: string): Promise<void> => requests.delete(`SingleBlog/on/delete/${id}/`),
    getLevel: (): Promise<abstractApiDetail[]> => requests.get(`Education/Levels/`),
    getPrasentationType: (): Promise<abstractApiDetail[]> => requests.get(`Presentation/Types/`),
    getSession: (): Promise<abstractApiDetail[]> => requests.get(`Conf/Sessions/?year=2024`),
    getACountry: (): Promise<abstractApiDetail[]> => requests.get(`conf/country/all/`),
    getPronouns: (): Promise<abstractApiDetail[]> => requests.get(`conf/pronouns/all/`),
    getParticipantType: (): Promise<abstractApiDetail[]> => requests.get(`conf/participanttype/all/`),
    getSurveys: (year: any): Promise<abstractApiDetail[]> => requests.get(`conf/survey/all/${year}/`),

};
