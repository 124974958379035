/**
 * @author WUM Solution UG <Gregor Schalm>
 * @typedef {Object} InvoiceStatusModel - Model representing the invoice status.
 * @property {string} status - The status of the invoice.
 * @property {boolean} [NonStripePath] - Indicates whether the non-Stripe path is active (optional).
 */
export interface InvoiceStatusModel {
    status: string;
    NonStripePath?: boolean;
}

/**
 * Initial state for the invoice status model.
 */
export const initialState: InvoiceStatusModel = {
    NonStripePath: false, // Default to Stripe path
    status: '', // Initial status is empty
};
