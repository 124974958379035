/**
 * @author Gregor Schalm
 * @typedef {Object} InvoiceTableModel - Interface defining the structure of invoice table data.
 *
 * @property {any[]} data - An array containing invoice table data.
 * The specific data types within the array might vary depending on usage.
 */
export interface InvoiceTableModel {
    data: any;
    totalRow: string[]
    rows: string[][]
}

/**
 * @constant {InvoiceTableModel} initialState - Default initial state for the InvoiceTable component (likely).
 *
 * Contains an empty array for the `data` property to be populated with invoice table data.
 */
export const initialState: InvoiceTableModel = {
    data: [],
    totalRow: [],
    rows: [['']]
};
