import {Font} from "@react-pdf/renderer";
// @ts-ignore
import Thin from "./Raleway-Thin.ttf";
// @ts-ignore
import Extralight from "./Raleway-ExtraLight.ttf";
// @ts-ignore
import Light from "./Raleway-Light.ttf";
// @ts-ignore
import Regular from "./Raleway-Regular.ttf";
// @ts-ignore
import Medium from "./Raleway-Medium.ttf";
// @ts-ignore
import MediumItalic from "./Raleway-MediumItalic.ttf";
// @ts-ignore
import Semibold from "./Raleway-SemiBold.ttf";
// @ts-ignore
import Bold from "./Raleway-Bold.ttf";
// @ts-ignore
import Extrabold from "./Raleway-ExtraBold.ttf";

const RalewayFontsRegister = () => {
    Font.register({
        family: 'Raleway',
        fonts: [
            {
                src: Thin,
                fontWeight: "thin"
            },{
                src: Extralight,
                fontWeight: "ultralight"
            },
            {
                src: Light,
                fontWeight: "light"
            },
            {
                src: Regular,
                fontWeight: "normal"
            },
            {
                src: Medium,
                fontWeight: "medium"
            },
            {
                src: MediumItalic,
                fontWeight: "medium",
                fontStyle: "italic"
            },
            {
                src: Semibold,
                fontWeight: "semibold"
            },
            {
                src: Bold,
                fontWeight: "bold"
            },
            {
                src: Extrabold,
                fontWeight: "ultrabold"
            },
        ]
    })
}

export default RalewayFontsRegister;