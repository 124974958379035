import {DayViewProps} from '../Models/DayView.Model'
import {DailyHeaderView} from "./Elements/";
import ContentController from "../Controller/Content.Controller";
const DayView: React.FC<DayViewProps> = (props:DayViewProps) => {

    return (
        <>
            <DailyHeaderView date={props.day} tabLabel={props.tabLabel}/>
            <ContentController value={props.value} header={props.header}/>
        </>
    )
}

export default DayView