import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import StripeStatusMessageView from "../View/StripeStatusMessageView";
import StripeInactiveView from "../View/StripeInactiveView";
import {initialState, PaymentPageModel} from "../Model/PaymentPageModel";
import {parseURL} from "../../../Function/";
import {sendPaymentData} from "../API/SendPaymentDataAPI";
import {CreateInvoice} from "../API/CreateInvoiceAPI";

/**
 * @author Gregor Schalm
 * PaymentStatusPage component handles the logic and rendering for a payment status page.
 *
 * It takes an optional `props` argument of type `PaymentPageModel`.
 * If no props are provided, it defaults to the `initialState` imported from the PaymentPageModel module.
 *
 * The component fetches data based on URL parameters and Stripe configuration,
 * and conditionally renders either a Stripe status message view or an inactive view.
 *
 * It also handles sending payment data, creating comments, creating invoices,
 * and ensuring invoice creation happens only once.
 *
 * @param {PaymentPageModel} [props] - Optional props object of type PaymentPageModel. Defaults to initialState.
 * @returns {JSX.Element} - The JSX element representing the payment status page.
 */


const PaymentStatusPage = (props: PaymentPageModel = initialState): JSX.Element => {
    const navigate = useNavigate();
    useEffect(() => {
        if (props.configuration?.automaticForward && props.configuration?.automaticForwardAim) {
            const timer = setTimeout(() => {
                const targetRoute = props.configuration?.automaticForwardAim || '/'; // Stays on page, if no aim defined
                navigate(targetRoute);
            }, props.configuration.automaticForwardTime);

            // Cleanup function to clear the timer if the component unmounts
            return () => clearTimeout(timer);
        }
    }, [props.configuration?.automaticForward, navigate]);


    const Store = useSelector((state: any) => state)

    // gets ID from param
    const {id} = useParams<{ id: string }>();

    /**
     * @example
     * example URL which is called after payment.
     * localhost:3000/registration/success/14?payment_intent=pi_3PYlVs082Bdx43wb1gyhK0VW&payment_intent_client_secret=pi_3PYlVs082Bdx43wb1gyhK0VW_secret_IVgdbEEdTPEol0TkmQ7nGb9oa&redirect_status=succeeded
     */
    const url = window.location.href;

    const parsedObject = parseURL({url});

    // Set redirect status, if Stripe was bypassed
    let redirect_status = ''
    if (props.NonStripePath === true) {
        redirect_status = 'bypass'
    } else {
        redirect_status = parsedObject.redirect_status
    }

    const payment_intent_client_secret = parsedObject.payment_intent_client_secret



    //ensure, that createInvoice is only run once:
    // to ensure, that create invoice is only run once
    const [invoiceCreated, setInvoiceCreated] = useState(false);

    const handleInvoiceCreation = () => {
        CreateInvoice({status: redirect_status, id: id, NonStripePath: props.NonStripePath});
        setInvoiceCreated(true);
    };

    useEffect(() => {
        if (!invoiceCreated) {
            handleInvoiceCreation();
        }
    }, [invoiceCreated]);



    // if loop to activate/deactivate based on registry value.
    if ( process.env.REACT_APP_API_STRIPE_ACTIVE !== undefined && process.env.REACT_APP_API_STRIPE_ACTIVE !== '' ) {
        if (props.NonStripePath !== true) {
            sendPaymentData({redirect_status: redirect_status, RegistrationId: id, payment_intent_client_secret: payment_intent_client_secret})
            console.log("here")
        } else if (props.NonStripePath === true) {
            let payment_intent_client_secret_fromLocalStorage
            if(payment_intent_client_secret === null || payment_intent_client_secret_fromLocalStorage === undefined) {
                payment_intent_client_secret_fromLocalStorage = (JSON.parse(localStorage.getItem('PaymentIntent') as any) ).secret
            }
            sendPaymentData({redirect_status: 'bypass', RegistrationId: id, payment_intent_client_secret: payment_intent_client_secret_fromLocalStorage, callbackFunc: props.configuration?.callbackFunc, callback: props.configuration?.callback})

        }
        return (
            <>
                <StripeStatusMessageView status={redirect_status} Store={Store} configuration={props.configuration}/>
            </>
        )
    } else {
        return (
            <>
                <StripeInactiveView Store={Store} />
            </>
        );
    }
}

export default PaymentStatusPage