import {StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    headline: {
        fontSize: '11pt',
        fontWeight: 'medium',
        color: '#153A67',
        fontFamily: "Raleway",
        textTransform: "uppercase"
    },
    text: {
        fontFamily: "Raleway",
        fontWeight: 'medium',
        fontSize: '11pt'
    },
    legalText: {
        fontSize: "10pt",
        textAlign: "right",
        fontStyle: "italic",
        fontFamily: "Raleway",
        fontWeight: "medium"
    }

});
