import React from 'react';
import DashbaordOverview from "./Pages/overview";

export const DashbaordPagesConfig = (value: any) => {

    return [
        {
            path: '/',
            elements: <DashbaordOverview value={value}/>,
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
    ];
}

export const DashbaordMenuConfig = (value: any) => {
    return [
            {
                name: 'Dashboard',
                route: '/',
                icon: 'dashboard',
                roles: [
                    'superadmin',
                    'admin',
                    'host',
                    'user',
                    'icymare'
                ]
            },
        ]
}
