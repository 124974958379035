import {PDFUniRow, PDFUniTextView} from "./Elements";
import {View} from "@react-pdf/renderer";
import {styles} from "./css/Footer.css";

/**
 * @author Gregor Schalm
 * @function Footer - Functional React component for rendering the main view of an Footer of a PDF document.
 * @returns {JSX.Element} - React JSX element representing the invoice PDF document.
 */

const Footer = () => {

    return (
        <>
            <PDFUniRow styleRow={{bottom: "5mm", left: "20mm", position: "absolute"}} debug={false} classRow={styles.text}>
                <View style={{width: "47mm"}}>
                    <PDFUniTextView text={"ICYMARE"} styleText={styles.footerHeadline}/>
                    <PDFUniTextView text={"International Conference for YOUNG Marine Researchers"} styleView={{width: "35mm" }}/>
                    <PDFUniTextView text={"Sitz: Bremen"} />
                    <PDFUniTextView text={"St-Nr.: 460/145/01019"} />
                </View>
                <View style={{width: "75mm"}}>
                    <PDFUniTextView text={"Vorstand"}  styleText={styles.footerHeadline}/>
                    <PDFUniTextView text={"Dr. Hans-Konrad Nettmann, 1. Vorsitzender"} />
                    <PDFUniTextView text={"Dr. Michael Stiller, Stellvertretender Vorsitzender"} />
                    <PDFUniTextView text={"Prof. Dr, Jens Lehmann, Stellvertretender Vorsitzender"} />
                </View>
                <View style={{width: "47mm"}}>
                    <PDFUniTextView text={"Bankverbindung"}  styleText={styles.footerHeadline}/>
                    <PDFUniTextView text={"Norddeutsche Landesbank"} />
                    <PDFUniTextView text={"IBAN: DE62 2905 0000 2002 2311 17"} />
                    <PDFUniTextView text={"BIC: NOLADE2HXXX"} />
                    <PDFUniTextView text={"ICYMARE.COM"} classText={styles.link} styleText={{marginTop: "3mm"}}/>
                </View>

            </PDFUniRow>

        </>
    );
};

export default Footer;
