import React from 'react';
import AbstractNew from "./modules/Pages/new";
import AbtractOverview from "./modules/Pages/overview";
import AbstractDetail from "./modules/Pages/detail";
import AbstractDetailUser from "./modules/Pages/detailUser";
import AbtractOverviewUser from "./modules/Pages/overviewUser";
import {useSelector} from "react-redux";
import {CMSRouteConfig} from "../index";
import {TicketPagesConfig} from "../../ticketsystem/modules";
import {SettingsRouteConfig} from "../../setting";
import {DashbaordMenuConfig} from "../dashboard/modules";

export const SessionHostPagesConfig = (value: any) => {
    const Store = useSelector((state: any) => state)
    let Role = Store.user.roles

    switch (Role) {
        case 'user':
            return [
                {
                    path: '/SessionHost/overviewUser/:page',
                    elements: <AbtractOverviewUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/SessionHost/new',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/SessionHost/new/:page',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/SessionHost/DetailUser/:id',
                    elements: <AbstractDetailUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ];
        case 'icymare':
            return [
                {
                    path: '/SessionHost/overview/:page',
                    elements: <AbtractOverview value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/SessionHost/new',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/SessionHost/new/:page',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/SessionHost/Detail/:id',
                    elements: <AbstractDetail value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                }
            ];
        case 'superadmin':

            return [
                {
                    path: '/SessionHost/overview/:page',
                    elements: <AbtractOverview value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/SessionHost/overviewUser/:page',
                    elements: <AbtractOverviewUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/SessionHost/new',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/SessionHost/new/:page',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/SessionHost/Detail/:id',
                    elements: <AbstractDetail value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/SessionHost/DetailUser/:id',
                    elements: <AbstractDetailUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                }
            ];
        default:
            return [
                {
                    path: '/SessionHost/overviewUser/:page',
                    elements: <AbtractOverviewUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/SessionHost/new',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/SessionHost/new/:page',
                    elements: <AbstractNew value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    path: '/SessionHost/DetailUser/:id',
                    elements: <AbstractDetailUser value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ];
    }

}

export const SessionHostMenuConfig = (value: any) => {
    const Store = useSelector((state: any) => state)
    let Role = Store.user.roles

    switch (Role) {
        case 'user':
            return [
                {
                    name: 'Overview',
                    route: '/SessionHost/overviewUser/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ]
        case 'icymare':
            return [
                {
                    name: 'Overview ICYMARE',
                    route: '/SessionHost/overview/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ]
        case 'superadmin':
            return [
                {
                    name: 'Overview ICYMARE',
                    route: '/SessionHost/overview/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
                {
                    name: 'Overview User',
                    route: '/SessionHost/overviewUser/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ]
        default:
            return [
                {
                    name: 'Overview',
                    route: '/SessionHost/overviewUser/1',
                    icon: 'dashboard',
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ]
    }

}
export const SessionHostMainMenuConfig = (value: any) => {
    return [
        {
            header: 'Session Submission',
            icon: 'schedule_send',
            menuitem: [
                ...SessionHostMenuConfig(value)
            ],
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
    ];
}
