import { Page, Document, View } from '@react-pdf/renderer';
import { styles } from './css/invoicePdf.css';
import FontsRegister from "../../../../../PDFFonts/FontsRegister";
import HeaderView from "./HeaderView";
import { PDFUniRow } from "./Elements";
import { SidebarView } from "./SidebarView";
import { InvoicePDFModel } from "../Model/InvoicePDFModel";
import {TextareaView} from "./TextareaView";
import Footer from "./FooterView";

/**
 * @author Gregor Schalm
 * @function InvoicePDFView - Functional React component for rendering the main view of an invoice PDF document.
 * @param {InvoicePDFModel} props - Component properties containing invoice data.
 * @returns {JSX.Element} - React JSX element representing the invoice PDF document.
 */

const InvoicePDFView = (props: InvoicePDFModel) => {
    FontsRegister({raleway: true, daysone: true });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <PDFUniRow>
                    <View style={styles.columnContent}>
                        <HeaderView data={props.response} />
                        <TextareaView data={props.response} rows={props.rows} message={props.message} totalRow={props.totalRow}/>
                    </View>
                    <View>
                        <SidebarView data={props.response} />
                    </View>
                </PDFUniRow>
                <Footer />
            </Page>
        </Document>
    );
};

export default InvoicePDFView;
