import {IntlMessageValue, UniCol, UniForm, UniRow} from "../../../../../@WUM/core/Elements"
import React, {useState} from "react";
import {HeadlineHelp} from "../../../../components/Elements/headlineHelp";
import { HasUserInterface } from "../../API/reponse/HasUser.interface";
import Form from 'react-bootstrap/Form';

interface PersonalInfoInterface {
    Store: any,
    Country:any
    UserDataOld: HasUserInterface | undefined
}

export const PersonalInfo = (props: PersonalInfoInterface) => {

    return (
        <>
            <h2>Personal details</h2>
            {/* Necessary to transfer the data to the correct ID of personal_details  */}
            <UniForm.FormControl type="hidden" name={'personal_details_id'} defaultValue={(props.UserDataOld?.personal_details.id !== 0 && props.UserDataOld !== undefined ? props.UserDataOld.personal_details.id : '0')}/>
            <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                <UniForm.InputGroupText class={"mb-md-3"}
                                        text={IntlMessageValue('de', 'icy.title', props.Store)}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessageValue('de', 'icy.title.explanation.registration', props.Store)
                                        }}/>
                <UniForm.FormControl type="text" name={'title'} placeholder="Enter Title" defaultValue={props.UserDataOld?.personal_details.title}/>
            </UniForm.InputGroup>

            <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                <UniForm.InputGroupText class={"mb-md-3"}
                                        text={IntlMessageValue('de', 'icy.first.name', props.Store)}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessageValue('de', 'icy.fist.name.explanation.registration', props.Store)
                                        }}/>
                <UniForm.FormControl type="text" name={'first_name'} placeholder="Enter first name" defaultValue={props.UserDataOld?.personal_details.first_name}/>
            </UniForm.InputGroup>

            <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                <UniForm.InputGroupText class={"mb-md-3"}
                                        text={IntlMessageValue('de', 'icy.middle.name', props.Store)}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessageValue('de', 'icy.middle.name.explanation.registration', props.Store)
                                        }}/>
                <UniForm.FormControl type="text" name={'MiddleName'} placeholder="Enter middle name" id={'MiddleName'} defaultValue={props.UserDataOld?.personal_details.middle_name}/>
            </UniForm.InputGroup>

            <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                <UniForm.InputGroupText class={"mb-md-3"}
                                        text={IntlMessageValue('de', 'icy.last.name', props.Store)}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessageValue('de', 'icy.last.name.explanation.registration', props.Store)
                                        }}/>
                <UniForm.FormControl type="text" name={'last_name'} placeholder="Enter last name" id={'last_name'} defaultValue={props.UserDataOld?.personal_details.last_name}/>
            </UniForm.InputGroup>
            <HeadlineHelp Store={props.Store} headType={"h2"} headlineText={"icy.personal.address"}
                          descriptionText={"icy.personal.address.explanation"}/>
            <UniRow>
                <UniCol sm={12} md={6}>
                    <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                        <UniForm.InputGroupText class={"mb-md-3"}
                                                text={IntlMessageValue('de', 'icy.street', props.Store)}
                                                InfoButton={{
                                                    display: true,
                                                    text: IntlMessageValue('de', 'icy.street.explanation.registration', props.Store)
                                                }}/>
                        <UniForm.FormControl type="text" name={'street'} placeholder="Enter street" id={'street'} defaultValue={props.UserDataOld?.personal_details.street}/>
                    </UniForm.InputGroup>
                </UniCol>
                <UniCol sm={12} md={6}>
                    <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                        <UniForm.InputGroupText class={"mb-md-3"}
                                                text={IntlMessageValue('de', 'icy.street.number', props.Store)}
                                                InfoButton={{
                                                    display: true,
                                                    text: IntlMessageValue('de', 'icy.street.number.explanation.registration', props.Store)
                                                }}/>
                        <UniForm.FormControl type="text" name={'streetNumber'} placeholder="Enter street number" id={'streetNumber'} defaultValue={props.UserDataOld?.personal_details.streetNumber}/>
                    </UniForm.InputGroup>
                </UniCol>
            </UniRow>
            <UniRow>
                <UniCol sm={12} md={6}>
                    <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                        <UniForm.InputGroupText class={"mb-md-3"}
                                                text={IntlMessageValue('de', 'icy.zipcode', props.Store)}
                                                InfoButton={{
                                                    display: true,
                                                    text: IntlMessageValue('de', 'icy.zipcode.explanation.registration', props.Store)
                                                }}/>
                        <UniForm.FormControl type="text" name={'zip'} placeholder="Enter postal code" id={'zip'} defaultValue={props.UserDataOld?.personal_details.zip}/>
                    </UniForm.InputGroup>
                </UniCol>
                <UniCol sm={12} md={6}>
                    <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                        <UniForm.InputGroupText class={"mb-md-3"}
                                                text={IntlMessageValue('de', 'icy.city', props.Store)}
                                                InfoButton={{
                                                    display: true,
                                                    text: IntlMessageValue('de', 'icy.city.personal.explanation', props.Store)
                                                }}/>
                        <UniForm.FormControl type="text" name={'cityPersonal'} placeholder="Enter city" id={'cityPersonal'} defaultValue={props.UserDataOld?.personal_details.cityPersonal}/>
                    </UniForm.InputGroup>
                </UniCol>
            </UniRow>


            <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                <UniForm.InputGroupText class={"mb-md-3"}
                                        text={IntlMessageValue('de', 'icy.country', props.Store)}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessageValue('de', 'icy.country.personal.explanation.registration', props.Store)
                                        }}/>
                <UniForm.Select freeValue={true} freeValueLabel={'Please choose'} name={'CountyAdressPersonal'}
                                items={
                                    // Country Holder
                                    props.Country
                                } defaultValue={props.UserDataOld?.personal_details.countryAdressPersonal}
                />
            </UniForm.InputGroup>
        </>
    )
}
