import {IntlMessageValue, MIcon, UniForm} from "../../../../../@WUM/core/Elements"
import React, {ChangeEvent} from "react";
import {HeadlineHelp} from "../../../../components/Elements/headlineHelp"
import {Form, FormCheck } from "react-bootstrap";
import {ResponseParticipantType, ResponsePronouns} from "../../API/request/abstract";
import {HasUserInterface} from "../../API/reponse/HasUser.interface";
import CheckboxWithChecking from "../../../../components/Elements/CheckboxWithChecking/CheckboxWithChecking";
import {OtherSet, OtherSet2} from "../../../../../@WUM/core/Function/OtherSet/OtherSet";
import UniChecks from "../../../../../@WUM/core/Elements/Form/Elements/Check";

interface RegistrationDetailsInterface {
    Store: any
    UserDataOld: HasUserInterface | undefined
    participantTypes: any
}

interface Event {
    label: string;
    id: string;
    defaultValue: any
}

export const RegistrationDetails = (props: RegistrationDetailsInterface) => {

    const events: Event[] = [
        {
            label: 'Icebreaker (Monday evening, 16th of September 2024)',
            id: 'icebreaker',
            defaultValue: props.UserDataOld?.icebreaker
        },
        {
            label: 'Postersession (Tuesday evening, 17th of September 2024)',
            id: 'postersession',
            defaultValue: props.UserDataOld?.postersession
        },
        {
            label: 'Science Speed Meeting (Wednesday evening, 18th of September 2024)',
            id: 'sciencespeedmeeting',
            defaultValue: props.UserDataOld?.sciencespeedmeeting
        },
        /*{
            label: 'Science Communication Evening: “Exploring together: The Power of Citizen Science” (Thursday evening, 19th of September 2024)',
            id: 'sciencecommunicationevening',
            defaultValue: props.UserDataOld?.sciencespeedmeeting
        },*/
        {
            label: 'Post Conference Party (Friday evening, 20th of September 2024)',
            id: 'postconferenceparty',
            defaultValue: props.UserDataOld?.postconferenceparty
        },
    ];


    const handleParticipantChange = (event: ChangeEvent<HTMLSelectElement>) => {
        OtherSet2(event,[3,4,5],'registerCode');
    };


    return (
        <>
            <HeadlineHelp Store={props.Store} headType={'h4'} headlineText={'icy.register.type.headline'} />
            <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                <UniForm.InputGroupText
                    class={"mb-md-3"}
                    text={IntlMessageValue('de', 'icy.register.as', props.Store)}
                    InfoButton={{
                        display: true,
                        text: IntlMessageValue('de', 'icy.register.as.explanation', props.Store)
                    }}/>
                <UniForm.Select freeValue={true} freeValueLabel={'Please choose'} name={'registrationVariant'} id={'RegistrationVariant'}
                                items={
                                    props.participantTypes
                                }
                                defaultValue={props.UserDataOld?.confParticipantType_id}
                                required={true}
                                onChange={handleParticipantChange}
                />
                <UniForm.FormControl type="text" name={'registerCode'} id={'registerCode'} placeholder="Code" readOnly={true}/>
            </UniForm.InputGroup>

            <HeadlineHelp Store={props.Store} headType={"h4"} headlineText={"event.participation"} descriptionText={"event.participation.explanation"} />

            {events.map((event) => (
                <FormCheck
                key={event.id}
                type={'checkbox'}
                id={event.id}
                defaultChecked={(event.defaultValue== 1 ? true :false)}
                label={event.label}
                name={event.id}
        />
            ))}

            <h3>Data protection</h3>

            <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                <FormCheck
                    key={"acceptDataStorage"}
                    type={'checkbox'}
                    id={"acceptDataStorage"}
                    defaultChecked={(props.UserDataOld?.acceptDataStorage == 1 ? true :false)}
                    label={
                        <>
                            {IntlMessageValue('de', 'icy.data.accept', props.Store)}
                            <a href="/documents/data-storage.pdf" target="_blank" rel="noopener noreferrer"
                               className="ms-2">
                                (English)
                            </a>
                            <a href="/documents/data-storage-ger.pdf" target="_blank" rel="noopener noreferrer"
                               className="ms-2">
                                (German)
                            </a>
                        </>
                    }
                    name={"acceptDataStorage"}
                />
            </UniForm.InputGroup>

            <UniForm.InputGroup class={"mb-md-0 mb-3"}>
            <FormCheck
                    key={"acceptPhoto"}
                    type={'checkbox'}
                    id={"acceptPhoto"}
                    defaultChecked={(props.UserDataOld?.acceptPhoto== 1 ? true :false)}
                    label={
                        <>
                            {IntlMessageValue('de', 'icy.photo.accept', props.Store)}
                            <a href="/documents/foto-allowance.pdf" target="_blank" rel="noopener noreferrer" className="ms-2">
                                    photo allowance
                            </a>
                        </>
                    }
                    name={"acceptPhoto"}
                />
            </UniForm.InputGroup>


        </>
    )
}
