import {ValidatorFields} from "../../../../../@WUM/core/component/const/Validator";

/**
 * @constant {ValidatorFields[]} FieldNamesPersonal - An array of `ValidatorFields` objects for personal information fields.
 */
const FieldNamesNamebadge:ValidatorFields[] = [
    {element:'nameBadgeName',error:'Fehler',valid:null},
    {element:'nameBadgeAff',error:'Error',valid:null},
    {element:'nameBadgePronoun',error:'Error 3',valid:null},
    {element:'researchInt',error:'Error 5',valid:null},
];

export default FieldNamesNamebadge
