import {convertToTimeFromIso} from "../../Controller/ConvertToTimeFromIso.Controller";

interface TimeFrameViewProps {
    start: string,
    end: string
}

export const TimeFrameView = (props: TimeFrameViewProps) => {

    const startTime = convertToTimeFromIso(props.start)
    const endTime = convertToTimeFromIso(props.end)


    return (
        <div className={"event-calendar-timeframe-box"} >
            <div>{startTime} - {endTime}</div>
        </div>
    )
}
