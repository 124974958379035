import {PDFViewer} from "@react-pdf/renderer";
import InvoicePDFController from "../../../components/Elements/pdf/Controller/InvoiceController";
import {ResponseInvoiceDetail, ResponseParticipantInfo} from "../API/Response/profil";
import React, {useEffect, useState} from "react";
import {SpinnerJSX} from "../../../../@WUM/core/component/const/Spinner";
import {useSelector} from "react-redux";
import QrController from "../../../../@WUM/core/QrCode/Controller/QrController";
import {error, UniButton, UniCol, UniRow} from "../../../../@WUM/core/Elements";
import {useParams} from "react-router-dom";
import {ApiRoot} from "../../../../@WUM/core/RestFullApi/api";
import {sendStorno} from "../API/Request/Storno";
import {StornoInterface} from "../API/Request/storno.interface";
import {profil} from "../API/Request/profil";


const InvoiceDetail = (props:{value:any}) => {

    const Store = useSelector((state: any) => state)

    const {id} = useParams<{ id: string }>();


    const [invoiceDetail, setInvoiceDetail] = useState<any>(null);
    const [participantInfo, setParticipantInfo] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false)

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                // Abruf der Rechnungsdetails
                const invoiceData = await profil.getInvoiceDetail(Number(id));

                if (invoiceData && invoiceData.userId) {
                    // Abruf der Teilnehmerinformationen, wenn Rechnungsdaten erfolgreich abgerufen wurden
                    const participantData = await profil.getParticipant(invoiceData.userId);
                    setInvoiceDetail(invoiceData);
                    setParticipantInfo(participantData);
                } else {
                    throw new Error("Invoice details not found or missing userId");
                }
            } catch (err) {
                console.error("Error fetching data: ", err);
                error(err)
                setIsError(true)
            } finally {
                setLoading(false);
            }
        };

        fetchDetails();
    }, [id]);


    const UserId = localStorage.getItem("userid")


    const InvoiceDetail = invoiceDetail

    const ParticipantInfo = participantInfo

    const handleButtonClick = () => {
        const props: StornoInterface = { id: id };
        sendStorno(props);
    };

    if (typeof InvoiceDetail !== "boolean" && InvoiceDetail !== undefined && InvoiceDetail !== null && participantInfo !== true && typeof participantInfo !== "boolean" && participantInfo !== null) {

        // construction of the link to register for lin
        const participantId = ParticipantInfo.id;
        const routeStart = 'checkin/'
        const user = ParticipantInfo["user"]
        const string = UserId + user.first_name + user.last_name

        function toBase64UTF8(string: any) {
            // Encode the string in UTF-8 before applying btoa
            return btoa(unescape(encodeURIComponent(string))).replace(/=/g, '');
        }

        // the trailing = have to be removed to avoid issues with url. They don't matter in base64.
        const hash = toBase64UTF8(string);
        console.log(hash)
        const url = ApiRoot() + routeStart + '24/' + participantId + "/" + hash

        return (
            <>
                <div id={'download'}/><div/>
                <UniRow >
                    <h4>Registration PW</h4>

                    <UniCol xs={12}>
                        <QrController url={url} width={200} height={200}/>
                        {props.value === "manage" ?
                            <UniCol md={12} class={"mt-5"}>
                                <UniButton onClick={handleButtonClick}>
                                    Send storno
                                </UniButton>
                            </UniCol>
                                :
                                <></>}

                    </UniCol>
                    <PDFViewer
                        width={"auto"}
                        height={"750px"}
                        className={"col-8"}
                    >
                        <InvoicePDFController response={InvoiceDetail}/>
                    </PDFViewer>

                </UniRow>



            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default InvoiceDetail;
