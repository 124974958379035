import {PDFViewer} from "@react-pdf/renderer";
import InvoicePDFController from "../../../components/Elements/pdf/Controller/InvoiceController";
import {ResponseInvoiceDetail, ResponseParticipantInfo} from "../API/Response/profil";
import React, {useEffect, useState} from "react";
import {SpinnerJSX} from "../../../../@WUM/core/component/const/Spinner";
import {useSelector} from "react-redux";
import QrController from "../../../../@WUM/core/QrCode/Controller/QrController";
import {error, UniButton, UniCol, UniRow} from "../../../../@WUM/core/Elements";
import {useParams} from "react-router-dom";
import {ApiRoot} from "../../../../@WUM/core/RestFullApi/api";
import {sendStorno} from "../API/Request/Storno";
import {StornoInterface} from "../API/Request/storno.interface";
import {profil} from "../API/Request/profil";


const QrCodeSinglePage = (props:{value:any}) => {
    const Store = useSelector((state: any) => state)




    const UserId = localStorage.getItem("userid")
    const FirstName = localStorage.getItem("first_name")
    const LastName = localStorage.getItem("last_name")


    if (UserId != undefined && UserId != null && FirstName != undefined && FirstName != null && LastName != null && LastName != undefined) {
        // construction of the link to register for lin
        const participantId = UserId;
        const routeStart = 'checkin/'
        const string = UserId + FirstName + LastName

        function toBase64UTF8(string: any) {
            // Encode the string in UTF-8 before applying btoa
            return btoa(unescape(encodeURIComponent(string))).replace(/=/g, '');
        }

        // the trailing = have to be removed to avoid issues with url. They don't matter in base64.
        const hash = toBase64UTF8(string);
        console.log(hash)
        const url = ApiRoot() + routeStart + '24/' + participantId + "/" + hash

        return (
            <>
                <div id={'download'}/><div/>
                <UniRow >
                    <h4>Registration QR Code</h4>
                    <UniCol xs={12}>
                        <QrController url={url} width={200} height={200}/>
                    </UniCol>
                </UniRow>
            </>
        )
    } else if (UserId == undefined || UserId == null){
        return <>User ID not defined</>
    } else if (FirstName == undefined && FirstName == null){
        return <>First name not defined</>
    } else if (LastName == null && LastName == undefined) {
        return <>Last name not defined</>
    } else
    {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default QrCodeSinglePage;
