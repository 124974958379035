import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup'
import '../css/dashboard.css'
import {useSelector} from "react-redux";
import ReactDOM from "react-dom";
import {ResponseLevel, ResponsePrasentationType, ResponseSession} from "../../API/request/abstract";
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import {useNavigate} from "react-router-dom";
import {btn_back} from "../../../../../@WUM/core/component/const/btn_back";
import SunEditor from "suneditor-react";
import {apiRoot} from "../../../../../@WUM/core/RestFullApi/ApiHook";
import token from "../../../../ticketsystem/modules/components/Token";

const AbstractNew = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    let authorint = 0;
    let authorIconInt = 2;
    let keywordIconInt = 2;
    let keywordsint = 0;
    let affiliationsint = 0;
    const Level = ResponseLevel();
    const history = useNavigate();
    const back =  btn_back(history,"common.back","btnBack",Store)
    const PresentationType = ResponsePrasentationType();

    const Session = ResponseSession();
    const SunEditorConfig = () => {
        return [
            // Default
            ['undo', 'redo','italic','subscript', 'superscript','removeFormat','fullScreen'],
        ]
    }
    const send = (e:any) => {
        e.preventDefault();

        let newFile1:any;

        let newFile2:any;

        let formfile = (document.getElementById('fileuploadAttachment1') as HTMLInputElement).files
        let allowedType
        if(formfile !== null) {
            for (let f = 0; f < formfile?.length; f++) {
                let formDatas = new FormData()
                allowedType = [
                    { type: 'application/pdf', size: 5000000 }
                ]
                let checkType = 0
                let checkSize = 0
                if (allowedType !== undefined && allowedType.length > 0) {
                    for (let type = 0; type < allowedType.length; type++) {
                        // @ts-ignore
                        if (allowedType[type].type === formfile[f].type) {
                            checkType = checkType + 1
                        }
                        // @ts-ignore
                        if (formfile[f].size < allowedType[type].size) {
                            checkSize = checkSize + 1
                        }
                    }
                }
                if (checkType === 0 && checkSize === 0) {
                    return;
                }
                formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
                formDatas.append('context', 'secure')
                // @ts-ignore
                formDatas.append(`file${0}`, formfile[f])
                var requestOptions = {
                    method: 'POST',
                    body: formDatas
                }
                let link
                if (process.env.REACT_APP_MODE === 'LOCAL') {
                    link = process.env.REACT_APP_CDN_API_URL_LOCAL
                } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
                    link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
                } else if (process.env.REACT_APP_MODE === 'LIVE') {
                    link = process.env.REACT_APP_CDN_API_URL_LIVE
                }

                fetch(link + 'upload/', requestOptions)
                    .then((response) => response.text())
                    .then((result) => {

                        fetch(ApiURL()+'upload/secure/', {
                            method: 'POST',
                            body: result,
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + token()
                            }
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                newFile1 = data.id
                                let formfile2 = (document.getElementById('fileuploadAttachment2') as HTMLInputElement).files
                                if(formfile2 !== null) {
                                    for (let f = 0; f < formfile2?.length; f++) {
                                        let formDatas = new FormData()
                                        allowedType = [
                                            { type: 'application/pdf', size: 5000000 }
                                        ]
                                        let checkType = 0
                                        let checkSize = 0
                                        if (allowedType !== undefined && allowedType.length > 0) {
                                            for (let type = 0; type < allowedType.length; type++) {
                                                // @ts-ignore
                                                if (allowedType[type].type === formfile2[f].type) {
                                                    checkType = checkType + 1
                                                }
                                                // @ts-ignore
                                                if (formfile2[f].size < allowedType[type].size) {
                                                    checkSize = checkSize + 1
                                                }
                                            }
                                        }
                                        if (checkType === 0 && checkSize === 0) {
                                            return;
                                        }
                                        formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
                                        formDatas.append('context', 'secure')
                                        // @ts-ignore
                                        formDatas.append(`file${0}`, formfile2[f])
                                        var requestOptions = {
                                            method: 'POST',
                                            body: formDatas
                                        }
                                        let link
                                        if (process.env.REACT_APP_MODE === 'LOCAL') {
                                            link = process.env.REACT_APP_CDN_API_URL_LOCAL
                                        } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
                                            link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
                                        } else if (process.env.REACT_APP_MODE === 'LIVE') {
                                            link = process.env.REACT_APP_CDN_API_URL_LIVE
                                        }

                                        fetch(link + 'upload/', requestOptions)
                                            .then((response) => response.text())
                                            .then((result) => {

                                                fetch(ApiURL()+'upload/secure/', {
                                                    method: 'POST',
                                                    body: result,
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        Authorization: 'Bearer ' + token()
                                                    }
                                                })
                                                    .then((response) => response.json())
                                                    .then((data) => {
                                                        newFile2 = data.id
                                                        let datas = new FormData(e.target);
                                                        let value = Object.fromEntries(datas.entries());
                                                        delete value.file1;
                                                        delete value.file2;
                                                        let files = {file1:newFile1,file2:newFile2}
                                                        let merged = {...value, ...files};
                                                        let raw = JSON.stringify(merged);
                                                        let root = ApiURL();
                                                        let requestOptionss = {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-Type': 'application/json',
                                                                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
                                                            },
                                                            body: raw,
                                                        };
                                                             fetch(root+"Conference/CfS/0/", requestOptionss)
                                                             .then(response => response.text())
                                                             .then(result => Notification({rewrite:true,landingpages:'/',type:'success', mode:'classic', title:{text:'Success'}, text:{text: 'Saved successfully'}, insert:'center right',duration:4}))
                                                             .catch(error => Notification({rewrite:true,landingpages:'/',type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Sorry, an error has occurred'}, insert:'center right',duration:4}));
                                                             let Button = document.getElementById('sendButton') as HTMLButtonElement;
                                                             Button.setAttribute('disabled','disabled');
                                                             Button.style.background = 'gray'
                                                    })
                                                    .catch((e:any) => {
                                                        console.log(e)
                                                    })


                                            })
                                            .catch((e:any) => {
                                                console.log(e)
                                            })
                                    }
                                }
                            })
                            .catch((e:any) => {
                                console.log(e)
                            })


                    })
                    .catch((e:any) => {
                        console.log(e)
                    })
            }
        }

    }


    const addElement = (row:any = false,col:any,id:any,addClass:any='',numberIcon:any=false) => {
        let counter;
        if(id==='Authors') {
            authorint++;
            counter= authorint;
        }
        if(id==='Keywords'){
            if(keywordsint ===4) {
                alert('You may not enter more than 5 keywords')
                return (<></>)
            }
            keywordsint++;
            counter= keywordsint;
        }
        let preHolderAffiliations = <></>;
        if(id==='Affiliations') {
            affiliationsint++;
            counter= affiliationsint;
            let preHolderAffiliationsAuthorOption = [];

            for(let pre =1;pre<authorIconInt;pre++) {
                preHolderAffiliationsAuthorOption.push(<option value={pre}>{pre}</option>)
            }

            preHolderAffiliations = <><InputGroup.Text>
                Host:
            </InputGroup.Text>
            <Form.Select className={"col-1 AffiliationAuthor"} aria-label="Default select example" name={'affiliationText'+counter} id={'AffiliationAuthor'}  required={true}>
                {preHolderAffiliationsAuthorOption}
            </Form.Select>
            <InputGroup.Text>
                Affiliations:
            </InputGroup.Text></>
        }
        let numberIconHolder = <></>;
        if(numberIcon !== false) {
            numberIconHolder = <InputGroup.Text >
                {(id==='Keywords' ? keywordIconInt : (id==='Authors' ? authorIconInt : 0))}
            </InputGroup.Text>

            if(id==='Keywords') {
                keywordIconInt++;
            }
            if(id==='Authors') {
                let select = document.getElementsByClassName('AffiliationAuthor') as HTMLSelectElement;
                for(let s = 0;s<select.length;s++){
                    var opt = document.createElement('option');
                    opt.value = authorIconInt.toString();
                    opt.innerHTML = authorIconInt.toString();
                    select[s].appendChild(opt);
                }

                authorIconInt++;
            }
        }

        if(row === true) {
            let newElement = (<><div className={'col-'+col}><InputGroup className={"mb-3 inputGroupExtra form-control "+addClass}>
                {numberIconHolder}
                {preHolderAffiliations}
                <Form.Control
                    name={id+''+counter}
                    placeholder={id}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                />
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                 onClick={()=>addElement(row,col,id,addClass,numberIcon)}>
                                <span className="material-icons">
                                    add
                                </span>
                </InputGroup.Text>
            </InputGroup></div></>);
            let temp = document.createElement('div');
            temp.classList.add('row')
            ReactDOM.render(newElement, temp);
            let ele =  document.getElementById(id);
            if(ele !== null) {
                ele.appendChild(temp)
            }
        } else {
            let newElement = (<><InputGroup className={"mb-3 inputGroupExtra form-control "+addClass}>
                {numberIconHolder}
                {preHolderAffiliations}
                <Form.Control
                    name={id+''+counter}
                    placeholder={(id === 'Authors' ? 'Host' : id)}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                />
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                 onClick={()=>addElement(row,col,id,addClass,numberIcon)}>
                                <span className="material-icons">
                                    add
                                </span>
                </InputGroup.Text>
            </InputGroup></>);
            let temp = document.createElement('div');
            temp.classList.add('col-'+col)
            ReactDOM.render(newElement, temp);
            let ele =  document.getElementById(id);
            if(ele !== null) {
                ele.appendChild(temp)
            }
        }

    }

    if(Level.length > 0 && PresentationType.length > 0 && Session.length > 0) {
        let optionLevel = [];

        let optionPresentationType = [];

        let optionSession = [];

        for(let l=0;l<Level.length;l++){
            optionLevel.push(
                <>
                    <option value={Level[l].id}>{Level[l].type}</option>
                </>
            )
        }

        for(let l=0;l<PresentationType.length;l++){
            optionPresentationType.push(
                <>
                    <option value={PresentationType[l].id}>{PresentationType[l].type}</option>
                </>
            )
        }

        for(let l=0;l<Session.length;l++){
            optionSession.push(
                <>
                    <option value={Session[l].id}>{Session[l].title}</option>
                </>
            )
        }
if (process.env.REACT_APP_SESSION_SUBMISSION_ACTIVE !== undefined && process.env.REACT_APP_SESSION_SUBMISSION_ACTIVE === 'True') {
    return (<>
        <Row className={''}>
            <Row>
                <Col xs={4} md={4} lg={4} style={{background: 'transparent linear-gradient(270deg, #0CB4CE 0%, #317391 100%) 0% 0% no-repeat padding-box',color:'white',maxHeight:'840px'}}>
                    <br />
                    <p className={'text-center'}><img src={'https://cdn.wum-solution.de//icymareKonfDev/2023/12/18/jU/ICYMARE-Logo-wide-screen.png'} /></p>
                    <br />
                    <h2>Session Submission ICYMARE 2024 Bremen</h2>
                    <p> <img src={"https://cdn.wum-solution.de//icymareKonfDev/2023/12/18/Ma/ICYMARE-2024-BREMEN-CallForSessions.jpg"} /></p>
                    <br />
                    <p></p>
                </Col>
                <Col style={{background: '#FFFFFF 0% 0% no-repeat padding-box',maxHeight:'840px',overflowX:'hidden',overflowY:'scroll'}}>  <Form id={'form01'} onSubmit={(e)=> send(e)}>
                    <Row> <h3>Session Holder</h3></Row><Row>
                    <Col>
                        <Form.Control type="hidden" name={'UserId'}  defaultValue={Store.user.id}/>
                        <Form.Group className="mb-3" controlId="Title">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" name={'academic_title'} placeholder="Enter Title" translate="no"/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="FirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" name={'FirstName'} readOnly={true} placeholder="Enter first name" defaultValue={Store.user.first_name}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="LastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" name={'LastName'} readOnly={true} placeholder="Enter last name" defaultValue={Store.user.last_name}/>
                        </Form.Group>
                    </Col>
                </Row>
                    <Row>

                        <Col>
                            <Form.Group className="mb-3" controlId="Emailaddress">
                                <Form.Label>Email address:</Form.Label>
                                <Form.Control type="email" name={'Emailaddress'} readOnly={true} placeholder="Enter email adress" defaultValue={Store.user.email}/>
                            </Form.Group>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row><Col><Form.Group className="mb-3" controlId="Mainaffiliation">
                        <Form.Label>Main affiliation</Form.Label>
                        <Form.Control type="text" name={'mainAffiliation'} placeholder="Enter main affiliation"  required={true}/>
                    </Form.Group></Col></Row>


                    <Row><h3>Education Level</h3>
                      </Row> <Row>

                    <Col>
                        <Form.Label>Level</Form.Label>
                        <Form.Select aria-label="Default select example" name={'Level'}  required={true}>
                            {optionLevel}
                        </Form.Select>
                    </Col>
                    <Col><Form.Group className="mb-3" controlId="Year">
                        <Form.Label>Year</Form.Label>
                        <Form.Control type="number" name={'Year'} min="1900" max="2099" placeholder="Enter Year"  required={true}/>
                    </Form.Group></Col>
                </Row>

                    <Row><h3>Sessions Title</h3>
                    </Row>
                    {/*<Row>
                        <Col>
                            <Form.Select aria-label="Default select example" name={'Sessions'}  required={true}>
                                {optionSession}
                            </Form.Select>
                        </Col>
                        <Col>
                            <Form.Select aria-label="Default select example" name={'PresentationType'}  required={true}>
                                {optionPresentationType}
                            </Form.Select>
                        </Col>
                    </Row>*/}
                    <br />
                    <Row>
                        <Form.Group className="mb-3" controlId="PresentationTitle">
                            <Form.Label>Session title</Form.Label>
                            {/*<Form.Control type="text" name={'PresentationTitle'} placeholder="Enter Session title"  required={true}/>*/}
                            <SunEditor
                                key={'test'}
                                name={'Title'}
                                placeholder="Enter Session title"
                                setOptions={{
                                    buttonList: SunEditorConfig(),
                                    attributesWhitelist: {
                                        div: 'id',
                                        p: 'id',
                                        h: 'id'
                                    }
                                }}
                                height={'50px'}
                            />
                        </Form.Group>
                    </Row>

                    <Row><h3>Additional Session Hosts:</h3>
                        <p>Please add all Host (including yourself) contributing to your Session in the right order. Use the + for adding more arrays (click on the field).</p>
                    </Row>
                    <Row id={'Authors'}>
                       <div className={'col-4'}>
                        <InputGroup className="mb-3 inputGroupExtra form-control w250" >
                            <InputGroup.Text>
                                1
                            </InputGroup.Text>
                            <Form.Control
                                name={'Authors0'}
                                placeholder="Host"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                className={'inputNoBorder'}
                                required={true}
                            />
                            <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                            onClick={()=>addElement(false,'4','Authors','w250',true)}
                            >
                                <span className="material-icons">
                                    add
                                </span>
                            </InputGroup.Text>
                        </InputGroup>
                        </div>
                    </Row>
                    <br />
                    <p>Please fill in all affiliations of the contributing authors according to the numbers stated above. Use the + for adding more arrays (click on the field).</p>
                    <Row id={'Affiliations'}>
                        <Row>
                        <div className={'col-12'}>
                        <InputGroup className="mb-3 inputGroupExtra form-control">
                            <InputGroup.Text>
                                Host:
                            </InputGroup.Text>
                            <Form.Select className={"col-1 AffiliationAuthor"} name={'affiliationText0'} aria-label="Default select example" id={'AffiliationAuthor'}  required={true}>
                                <option value={1}>1</option>
                            </Form.Select>
                            <InputGroup.Text>
                                Affiliations:
                            </InputGroup.Text>
                            <Form.Control
                                name={'Affiliations0'}
                                placeholder="Affiliations"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                className={'inputNoBorder'}
                                required={true}
                            />
                            <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                             onClick={()=>addElement(true,'12','Affiliations','')}>
                                 <span className="material-icons">
                                    add
                                </span>
                            </InputGroup.Text>
                        </InputGroup>
                        </div>
                        </Row>
                    </Row>
                    <br />
                    <br />
                    <Row><h3>Social Media Handles</h3>
                        <p>Please provide us with your social media handles (“@...”) to enable us to tag you:</p>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="Twitter">
                                <Form.Label>Twitter</Form.Label>
                                <Form.Control type="text"  name={'Twitter'} placeholder="Enter Twitter account" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="Instagram">
                                <Form.Label>Instagram</Form.Label>
                                <Form.Control type="text" name={'Instagram'} placeholder="Enter Instagram account"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="Facebook">
                                <Form.Label>Facebook</Form.Label>
                                <Form.Control type="text" name={'Facebook'} placeholder="Enter Facebook account"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="LinkedIn">
                                <Form.Label>LinkedIn</Form.Label>
                                <Form.Control type="text" name={'LinkedIn'} placeholder="Enter LinkedIn account"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row><h4>Social Media Handles (Institution)</h4>
                        <p>Please provide us with the social media handles of your first affiliation to enable us to tag the institution (preferably “@...”, otherwise also “#...” is possible):</p>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="InstitutionTwitter">
                                <Form.Label>Twitter</Form.Label>
                                <Form.Control type="text" name={'InstitutionTwitter'} placeholder="Enter Twitter account"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="InstitutionInstagram">
                                <Form.Label>Instagram</Form.Label>
                                <Form.Control type="text" name={'InstitutionInstagram'} placeholder="Enter Instagram account"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="InstitutionFacebook">
                                <Form.Label>Facebook</Form.Label>
                                <Form.Control type="text" name={'InstitutionFacebook'} placeholder="Enter Facebook account"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="InstitutionLinkedIn">
                                <Form.Label>LinkedIn</Form.Label>
                                <Form.Control type="text" name={'InstitutionLinkedIn'} placeholder="Enter LinkedIn account"/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row><h3>Keywords</h3>
                        <p>Please provide max. 5 keywords about your presentation topic. Terms should not be already part the title but should also not be too general.</p>
                    </Row>
                    <Row id={'Keywords'}>
                        <Row>
                        <Col>
                            <InputGroup className="mb-3 inputGroupExtra form-control">
                                <InputGroup.Text>
                                    1
                                </InputGroup.Text>
                                <Form.Control
                                    name={'Keywords0'}
                                    placeholder="Keyword"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    className={'inputNoBorder'}
                                    required={true}
                                />
                                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                                 onClick={()=>addElement(true,'12','Keywords','',true)}>
                                     <span className="material-icons">
                                    add
                                </span>
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        </Row>
                    </Row>

                    <Row><h3>Abstract</h3>
                        <p>Please insert your abstract here. It can have 300 words at maximum. Please to not use citations, references, figures, etc.</p>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="Abstract">
                            <SunEditor
                                key={'test'}
                                name={'Abstract'}
                                placeholder="Enter Abstract"
                                setOptions={{
                                    buttonList: SunEditorConfig(),
                                    attributesWhitelist: {
                                        div: 'id',
                                        p: 'id',
                                        h: 'id'
                                    }
                                }}
                                height={'250px'}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="formFileMultiple" className="mb-3">
                                <Form.Label>CV</Form.Label>
                                <Form.Control id={'fileuploadAttachment1'} name={'file1'} type="file" accept=".pdf"/>
                            </Form.Group>
                            <Form.Group controlId="formFileMultiple" className="mb-3">
                                <Form.Label>letter of motivation</Form.Label>
                                <Form.Control id={'fileuploadAttachment2'} name={'file2'} type="file" accept=".pdf"/>
                            </Form.Group>
                        </Col>
                    </Row>

<Row>
    <Col>
        {back}
                    <Button variant="primary" className={'float-end'} id={'sendButton'} type="submit"
                    style={{background: '#307593 0% 0% no-repeat padding-box',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '34px',
                        marginBottom:'20px',
                        opacity: 1,
                        float:'right',
                        height: '67px',
                        width: '350px'}}>
                        <span style={{
                            font: 'normal normal bold 30px/40px Segoe UI',
                            letterSpacing: '0px',
                            color: '#FFFFFF',
                            opacity: 1
                        }}>
                             Send
                        </span>
                    </Button>
                    <br />
                    <br />
    </Col>
</Row>
                </Form></Col>
            </Row>

        </Row>
    </>)
} else {
    return (
        <>
            <h1>Session submission is closed!</h1>
            <p>Sorry, but session submission is closed already. Please consider to hand in an abstract for a presentation or Talk!</p>
        </>
    );
}
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }

}

export default AbstractNew;
