import React from "react";

interface SplashscreenProps {
    onContinue: () => void;
}

export const SplashscreenView: React.FC<SplashscreenProps> = ({ onContinue }) => {
    return (
        <>
            <div className={"splashscreen"}>
                <h2>Welcome to the</h2>
                <div className="title">
                    <h1 className="title-top">ICYMARE</h1>
                    <h1 className="title-middle">2024</h1>
                    <h1 className="title-bottom">BREMEN</h1>
                </div>
                <h2>Schedule</h2>

                <div className="content">
                    <p>You can tap on each section to open a <span>detailed view</span> of that event.</p>
                    <p>Tapping the <span>address bar</span> inside the detailed view will open a <span>map of the selected location.</span>
                    </p>
                    <p>Tapping on a <span>session</span> will open the <span>list of talks</span>, then tapping on a
                        talk will open the <span>abstract view.</span></p>
                </div>
                <button onClick={onContinue}>Tap to continue</button>
            </div>
        </>
    )
};