import {abstractApiDetail} from "./abstractAPI.interface";
import {requests} from '../../../../../@WUM/core/RestFullApi/api';

export const abstract = {
    getPosts: (limit: any, searchValue: any): Promise<abstractApiDetail[]> => requests.get(`Conferences/CfS/${limit}/${searchValue}`),
    getPostsUser: (limit: any, searchValue: any,userid:any): Promise<abstractApiDetail[]> => requests.get(`Conference/CfS/User/${limit}/${userid}/${searchValue}`),
    getPostsHost: (limit: any, searchValue: any,userid:any): Promise<abstractApiDetail[]> => requests.get(`Conference/CfS/host/${limit}/${userid}/${searchValue}`),
    getAPost: (id: string | undefined): Promise<abstractApiDetail[]> => requests.get(`Conference/CfS/${id}/`),
    createPost: (post: abstractApiDetail, id: number): Promise<abstractApiDetail> =>
        requests.post(`Conference/CfS/${id}/`, post),
    updatePost: (post: any, id: number): Promise<abstractApiDetail> =>
        requests.post(`Conference/CfS/${id}/`, post),
    deletePost: (id: string): Promise<void> => requests.delete(`SingleBlog/on/delete/${id}/`),
    getLevel: (): Promise<abstractApiDetail[]> => requests.get(`Education/Levels/`),
    getPrasentationType: (): Promise<abstractApiDetail[]> => requests.get(`Presentation/Types/`),
    getSession: (): Promise<abstractApiDetail[]> => requests.get(`Conf/Sessions/`)
};
