import { Form } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import React from 'react'
const FetchSelect = (props: {
    Data: any
    id: any
    id2?: any
    value: any
    value2?: any
    Uid: number
    label: string
    asCol?: any
    onChanges?: any
    controlId?: any
    name?: any
    required?: any
}) => {
    let Users = []
    let selected = []
    let id
    if (props.Data !== undefined) {
        for (let u = 0; u < props.Data.length; u++) {
            if (props.id2 !== undefined) {
                id = props.Data[u][props.id] + ' ' + props.Data[u][props.id2]
            } else {
                id = props.Data[u][props.id]
            }

            Users.push(
                <>
                    <option value={id}>
                        {props.Data[u][props.value]}{' '}
                        {props.value2 !== undefined
                            ? props.Data[u][props.value2]
                            : ''}
                    </option>
                </>
            )
        }
        if (props.asCol === undefined) {
            if (props.onChanges !== undefined) {
                selected.push(
                    <Form.Group
                        as={Col}
                        controlId={
                            props.controlId !== undefined
                                ? props.controlId
                                : 'formGrid' + props.label.replaceAll(' ', '_')
                        }
                    >
                        <Form.Label>{props.label}</Form.Label>
                        <Form.Select
                            defaultValue={props.Uid}
                            required={
                                props.name !== undefined
                                    ? props.required
                                    : false
                            }
                            name={props.name !== undefined ? props.name : ''}
                            onChange={(e) => props.onChanges(e.target.value)}
                        >
                            <option />
                            {Users}
                        </Form.Select>
                    </Form.Group>
                )
            } else {
                selected.push(
                    <Form.Group
                        as={Col}
                        controlId={
                            props.controlId !== undefined
                                ? props.controlId
                                : 'formGrid' + props.label.replaceAll(' ', '_')
                        }
                    >
                        <Form.Label>{props.label}</Form.Label>
                        <Form.Select
                            defaultValue={props.Uid}
                            required={
                                props.name !== undefined
                                    ? props.required
                                    : false
                            }
                            name={props.name !== undefined ? props.name : ''}
                        >
                            <option />
                            {Users}
                        </Form.Select>
                    </Form.Group>
                )
            }
        } else if (props.asCol !== true) {
            if (props.onChanges !== undefined) {
                selected.push(
                    <Form.Group
                        as={Col}
                        controlId={
                            props.controlId !== undefined
                                ? props.controlId
                                : 'formGrid' + props.label.replaceAll(' ', '_')
                        }
                    >
                        <Form.Label>{props.label}</Form.Label>
                        <Form.Select
                            defaultValue={props.Uid}
                            required={
                                props.name !== undefined
                                    ? props.required
                                    : false
                            }
                            name={props.name !== undefined ? props.name : ''}
                            onChange={(e) => props.onChanges(e.target.value)}
                        >
                            <option />
                            {Users}
                        </Form.Select>
                    </Form.Group>
                )
            } else {
                selected.push(
                    <Form.Group
                        as={Col}
                        controlId={
                            props.controlId !== undefined
                                ? props.controlId
                                : 'formGrid' + props.label.replaceAll(' ', '_')
                        }
                    >
                        <Form.Label>{props.label}</Form.Label>
                        <Form.Select
                            defaultValue={props.Uid}
                            required={
                                props.name !== undefined
                                    ? props.required
                                    : false
                            }
                            name={props.name !== undefined ? props.name : ''}
                        >
                            <option />
                            {Users}
                        </Form.Select>
                    </Form.Group>
                )
            }
        } else {
            if (props.onChanges !== undefined) {
                selected.push(
                    <Form.Group
                        className="mb-3"
                        controlId={
                            props.controlId !== undefined
                                ? props.controlId
                                : 'formGrid' + props.label.replaceAll(' ', '_')
                        }
                    >
                        <Form.Label>{props.label}</Form.Label>
                        <Form.Select
                            defaultValue={props.Uid}
                            required={
                                props.name !== undefined
                                    ? props.required
                                    : false
                            }
                            name={props.name !== undefined ? props.name : ''}
                            onChange={(e) => props.onChanges(e.target.value)}
                        >
                            <option />
                            {Users}
                        </Form.Select>
                    </Form.Group>
                )
            } else {
                selected.push(
                    <Form.Group
                        className="mb-3"
                        controlId={
                            props.controlId !== undefined
                                ? props.controlId
                                : 'formGrid' + props.label.replaceAll(' ', '_')
                        }
                    >
                        <Form.Label>{props.label}</Form.Label>
                        <Form.Select
                            defaultValue={props.Uid}
                            required={
                                props.name !== undefined
                                    ? props.required
                                    : false
                            }
                            name={props.name !== undefined ? props.name : ''}
                        >
                            <option />
                            {Users}
                        </Form.Select>
                    </Form.Group>
                )
            }
        }
    } else {
        selected.push(
            <Form.Group
                className="mb-3"
                controlId={
                    props.controlId !== undefined
                        ? props.controlId
                        : 'formGrid' + props.label.replaceAll(' ', '_')
                }
            >
                <Form.Label>{props.label}</Form.Label>
                <Form.Select
                    defaultValue={props.Uid}
                    name={props.name !== undefined ? props.name : ''}
                >
                    <option />
                </Form.Select>
            </Form.Group>
        )
    }
    return <>{selected}</>
}

export default FetchSelect
