import {useNavigate, useParams} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useSelector} from "react-redux";
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import {ResponseAuthor, ResponsePresentationSingle} from "../../API/response/presentation";
import Button from "react-bootstrap/Button";
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import FetchSelect from "../../../../../@WUM/core/component/const/Fetchselect";
import {btn_back} from "../../../../../@WUM/core/component/const/btn_back";
import {IntlMessageValue, UniForm} from '../../../../../@WUM/core/Elements';
import {init2} from "../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import React from "react";
import {removeHTMLTags} from "../../../../EventCalendar/modules/Controller/RemoveHTML.Controller";

const PresentationDetail = (props: { value: any }) => {
    const {id} = useParams<{ id: string }>();
    const Store = useSelector((state: any) => state)
    const response = ResponsePresentationSingle(id);
    const author = ResponseAuthor();
    const history = useNavigate();
    const back = btn_back(history, "common.back", "btnBack", Store)
    const send = (e: any) => {
        e.preventDefault();
        const form = e.currentTarget
        let Data = init2(form)
        console.log(Data)
        var raw2 = JSON.stringify(
            {
                "id": id,
                "userId": Data.userId,
                "title": Data.title,
                "date": Data.start,
                "dateEnd": Data.end,
                "extends": {
                    "locationHeadline": Data.locationHeadline,
                    "location": Data.location,
                    "locationLink": Data.locationLink,
                    "description": Data.description,
                    "secSpeaker": Data.secSpeaker,
                    "shortDescription": Data.shortDescription,
                    "color": Data.color,
                    "state": Number(Data.state),
                    "sessionNumber": Data.sessionNumber,
                    "changeReason": Data.changeReason
                }
            }
        )

        console.log(raw2)
        let root = ApiURL();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw2,
        };
        fetch(root + "Conference/Presentation/update/" + id + "/", requestOptions)
            .then(response => response.text())
            .then(result => Notification({
                type: 'success',
                mode: 'classic',
                title: {text: 'Success'},
                text: {text: 'Saved successfully'},
                insert: 'center right',
                duration: 4
            }))
            .catch(error => Notification({
                type: 'danger',
                mode: 'classic',
                title: {text: 'Error'},
                text: {text: 'Sorry, an error has occurred'},
                insert: 'center right',
                duration: 4
            }));
    }
    console.log(response)
    if (response !== null && response !== undefined) {

        return (<>
            <Row className={"px-2"}>
                <Form id={'form01'} onSubmit={(e) => send(e)}>
                    <Row>
                        <Col>
                            <h1>Presentation-Settings</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="Title">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" name={'title'} placeholder="Enter Title"
                                              defaultValue={removeHTMLTags(response.presentationTitle)} readOnly={true} disabled={true}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="abstract">
                                <Form.Label>Abstract</Form.Label>
                                <Form.Control as="textarea" name={'abstract'} placeholder="Enter Title"
                                              defaultValue={removeHTMLTags(response.abstract)} readOnly={true} disabled={true} rows={8}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="secSpeaker">
                                <Form.Label>Alternative presenter</Form.Label>
                                <Form.Control type="text" name={'secSpeaker'} placeholder="Enter Name"
                                              defaultValue={response.extends?.secSpeaker}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="start">
                                <Form.Label>start time</Form.Label>
                                <Form.Control type="datetime-local" name={'start'} placeholder="Enter year"
                                              defaultValue={response.date}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="end">
                                <Form.Label>end time</Form.Label>
                                <Form.Control type="datetime-local" name={'end'} placeholder="Enter year"
                                              defaultValue={response.dateEnd}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="locationHeadline">
                                <UniForm.Label class={"mb-md-3"}
                                               text={IntlMessageValue('de', 'icy.event.calendar.locationHeadline', Store)}
                                               InfoButton={{
                                                   display: true,
                                                   text: IntlMessageValue('de', 'icy.event.calendar.locationHeadline.expl', Store)
                                               }}/>
                                <Form.Control type="text" name={'locationHeadline'}
                                              placeholder="Enter location headline"
                                              defaultValue={response.extends?.locationHeadline}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="locationLink">
                                <UniForm.Label class={"mb-md-3"}
                                               text={IntlMessageValue('de', 'icy.event.calendar.locationLink', Store)}
                                               InfoButton={{
                                                   display: true,
                                                   text: IntlMessageValue('de', 'icy.event.calendar.locationLink.expl', Store)
                                               }}/>
                                <Form.Control type="text" name={'locationLink'}
                                              placeholder="Enter location link (https://)"
                                              defaultValue={response.extends?.locationLink}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="location">
                                <UniForm.Label class={"mb-md-3"}
                                               text={IntlMessageValue('de', 'icy.event.calendar.location', Store)}
                                               InfoButton={{
                                                   display: true,
                                                   text: IntlMessageValue('de', 'icy.event.calendar.location.expl', Store)
                                               }}/>
                                <Form.Control type="text" name={'location'} placeholder="Enter location address"
                                              defaultValue={response.extends?.location}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="description">
                                <UniForm.Label class={"mb-md-3"}
                                               text={IntlMessageValue('de', 'icy.event.calendar.description', Store)}
                                               InfoButton={{
                                                   display: true,
                                                   text: IntlMessageValue('de', 'icy.event.calendar.description.expl', Store)
                                               }}/>
                                <Form.Control as="textarea" name={'description'} placeholder="Enter long description"
                                              defaultValue={response.extends?.description} rows={10}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="shortDescription">
                                <UniForm.Label class={"mb-md-3"}
                                               text={IntlMessageValue('de', 'icy.event.calendar.shortDescription', Store)}
                                               InfoButton={{
                                                   display: true,
                                                   text: IntlMessageValue('de', 'icy.event.calendar.shortDescription.expl', Store)
                                               }}/>
                                <Form.Control as="textarea" name={'shortDescription'}
                                              placeholder="Enter short description"
                                              defaultValue={response.extends?.shortDescription} rows={10}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3}>
                            <UniForm.InputGroup class={"mb-md-0 mb-3"} >
                                <UniForm.InputGroupText class={"mb-md-3"}
                                                        text={IntlMessageValue('de', 'icy.event-calendar.state', Store)}
                                                        InfoButton={{
                                                            display: true,
                                                            text: IntlMessageValue('de', 'icy.state.expl', Store)
                                                        }}/>
                                <UniForm.Select freeValue={true} freeValueLabel={'Please choose'}
                                                name={'state'}
                                                items={
                                                    [
                                                        {
                                                            value: '1', text: 'Active'
                                                        },
                                                        {
                                                            value: '2', text: 'Changed'
                                                        },
                                                        {
                                                            value: '3', text: 'Cancelled'
                                                        },
                                                    ]
                                                }
                                                defaultValue={response.extends?.state}
                                />
                            </UniForm.InputGroup>
                        </Col>
                        <Col>
                            <UniForm.InputGroup class={"mb-md-0 mb-3"} >
                                <UniForm.InputGroupText class={"mb-md-3"}
                                               text={IntlMessageValue('de', 'icy.event.calendar.changeReason', Store)}
                                               InfoButton={{
                                                   display: true,
                                                   text: IntlMessageValue('de', 'icy.event.calendar.changeReason.expl', Store)
                                               }}/>
                                <Form.Control type="text" name={'changeReason'}
                                              placeholder="Enter Change reason"
                                              defaultValue={response.extends?.changeReason} />
                            </UniForm.InputGroup>
                        </Col>
                        <Col lg={2}>
                            <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                                <UniForm.InputGroupText class={"mb-md-3"}
                                                        text={IntlMessageValue('de', 'icy.event.calendar.color', Store)}
                                                        InfoButton={{
                                                            display: true,
                                                            text: IntlMessageValue('de', 'icy.color.expl', Store)
                                                        }}/>

                                <Form.Control
                                    type="color"
                                    id="color"
                                    defaultValue={response.extends?.color ?? '#FDFDFD'}
                                    title="Choose your color"
                                    name={"color"}
                                />
                            </UniForm.InputGroup>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            {back}
                            <Button variant="primary" className={'float-end'} type="submit"
                                    style={{
                                        background: '#307593 0% 0% no-repeat padding-box',
                                        boxShadow: '0px 3px 6px #00000029',
                                        borderRadius: '34px',
                                        marginBottom: '20px',
                                        opacity: 1,
                                        float: 'right',
                                        height: '67px',
                                        width: '350px'
                                    }}>
                        <span style={{
                            font: 'normal normal bold 30px/40px Segoe UI',
                            letterSpacing: '0px',
                            color: '#FFFFFF',
                            opacity: 1
                        }}>
                             Save
                        </span>
                            </Button></Col>
                    </Row>
                    <Row>
                        <Col>
                            <br/> <br/>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </>)
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default PresentationDetail;
