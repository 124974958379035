import {sortByStart, sortByStartAndLocation} from "./SortByStart";
import {SessionOverviewView, EventOverviewView} from "../Views/Elements/";

interface ContentControllerProps {
    value?: any;
    header?: any
}


const ContentController: React.FC<ContentControllerProps> = (props:ContentControllerProps) => {

    const sortedEvents = sortByStartAndLocation(props.value)

    return (
        <div>
            {sortedEvents.map((event: { type: string; id: any; }) => {
                if (event.type === "session") {
                    return <SessionOverviewView key={event.id} {...event} header={props.header}  />;
                } else if (event.type === "event") {
                    return <EventOverviewView key={event.id} {...event} header={props.header}   />;
                } else {
                    return null;
                }
            })}
        </div>
    )
}

export default ContentController