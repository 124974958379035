import {TimeFrameView} from "./";
import React from "react";
import {removeHTMLTags} from "../../Controller/RemoveHTML.Controller";
import {Link} from "react-router-dom";
export const SinglePresentationsView = (props: any) => {
    if (props.title !== undefined && props.title !== null) {
        const sanitizedHtml = removeHTMLTags(props.title);
        console.log(props.sessionId)
        return (
            <Link to={"/unbound/event-calendar/presentation-details/" + props.id} className={"href-no-highlight"} state={{header: props.header, value: props , sessionId: props.sessionId}}
            >
                <div>
                    <TimeFrameView start={props.start} end={props.end}/>
                    <div className={"py-5"}>
                        {props.state === 2 && (
                            <div className="event-changed-warning">{props.changeReason ? props.changeReason : "The time/venue has been updated"}</div>
                        )}
                        {props.state === 3 && (
                            <div className="event-changed-removed">{props.changeReason ? props.changeReason : "The presentation was cancelled"}</div>
                        )}
                        <h3 className={"session-headline"}>{sanitizedHtml}</h3>
                        <p className={"session-description"}>{props.secondSpeaker || props.speaker}</p>
                    </div>
                </div>
            </Link>
        );
    } else {
        return <></>
    }
};