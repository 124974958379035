export function sortByStart(arrayOfObjects: any[]) {
    return arrayOfObjects.sort((a, b) => {
        // Convert ‘start’ values into date objects for precise comparison
        const dateA = new Date(a.start);

        const dateB = new Date(b.start);

        // Compares date objects
        return dateA.getTime() - dateB.getTime();
    });
}

export function sortByStartAndLocation(arrayOfObjects: any[]) {
    return arrayOfObjects.sort((a, b) => {
        // Convert ‘start’ values into date objects for precise comparison and compare them first
        const dateA = new Date(a.start);
        const dateB = new Date(b.start);
        const dateComparison = dateA.getTime() - dateB.getTime();

        // If start is identical, compare by locationHeadline
        if (dateComparison === 0) {
            // Compare headline, but position null on the end
            if (a.locationHeadline === null && b.locationHeadline === null) {
                return 0;
            } else if (a.locationHeadline === null) {
                return 1;
            } else if (b.locationHeadline === null) {
                return -1;
            } else {
                return a.locationHeadline.localeCompare(b.locationHeadline);
            }
        } else {
            return dateComparison;
        }
    });
}