import {StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    page: {
        paddingLeft: '6mm',
        paddingRight: '6mm',
        paddingTop:'3mm',
        paddingBottom: '1mm',
        textAlign: "center"
    },
    name: {
        fontFamily: "Days One",
        fontSize: "18pt",
        color: "#31718F",
        marginBottom: "2mm"
    },
    pronouns: {
        fontFamily: "Raleway",
        fontWeight: "medium",
        color: "#31718F",
        fontSize: "12pt",
        marginBottom: "4mm"
    },
    affiliation: {
        fontFamily: "Raleway",
        fontWeight: "medium",
        color: "#153A67",
        fontSize: "11pt",
        fontStyle: "italic",
        marginBottom: "4mm"
    },
    interests: {
        fontFamily: "Raleway",
        fontWeight: "medium",
        color: "#153A67",
        fontSize: "12pt",
        whiteSpace: 'nowrap', // CSS-Äquivalent, falls Unterstützung gegeben ist
    }
});
