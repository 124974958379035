import React from "react";
import { InvoiceTableModel, initialState } from "../Model/InvoiceTableModel";
import {PDFUniTableView} from "./Elements/PDFUniTableView/PDFUniTableView";
import {PDFUniTextView} from "./Elements";
import {styles} from './css/InvoiceTable.css';

export const InvoiceTableView = (props: InvoiceTableModel = initialState) => {
    const headerText = ['Pos', 'Service', 'Amount', 'Sum']

    return (
        <>
            <PDFUniTableView
                header={headerText}
                widths={["15%", "50%", "19%", "16%"]}
                rows={props.rows}
                stylesRows={[styles.tableCell,styles.tableCell,styles.tableCell, styles.tableCellEnd]}
            />
            <PDFUniTableView header={props.totalRow} rows={[]} widths={["84%", "16%"]} stylesHeader={[styles.tableCell, styles.tableCellEnd]}/>
            <PDFUniTextView text={"The participation fees of this scientific conference are subject to the tax exemption after § 4 Nr. 22 Buchst. a UStG"} classText={styles.legalText}/>
        </>
    );
};