import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";


const Authors = (props:{author:any,readonly?:any}) => {

    let author = props.author;

    if(author === undefined) {
        return (<><InputGroup className="mb-3 inputGroupExtra form-control w250" >
            <InputGroup.Text>
                1
            </InputGroup.Text>
            <Form.Control
                readOnly={(props.readonly !== undefined && props.readonly !== null ? props.readonly : true)}
                name={'Authors0'}
                placeholder="No Author find"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                className={'inputNoBorder'}
            />

        </InputGroup></>)
    }

    let authorContainer = [];

    for(let a=0;a<author.length;a++){
    authorContainer.push(
        <>
            <InputGroup className="mb-3 inputGroupExtra form-control w250" >
                <InputGroup.Text>
                    {a+1}
                </InputGroup.Text>
                <Form.Control
                    readOnly={(props.readonly !== undefined && props.readonly !== null ? props.readonly : true)}
                    name={'Authors'+a}
                    placeholder="Author"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                    defaultValue={author[a].data[0].name}
                />

            </InputGroup>
        </>
    )
    }


    return (
        <Row id={'Authors'}>
            <div className={'col-4'}>
                {authorContainer}
            </div>
        </Row>
    )

}

export default Authors;
