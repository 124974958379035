import {useNavigate, useParams} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useSelector} from "react-redux";
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import {ResponseEventSingle} from "../../API/response/event";
import Button from "react-bootstrap/Button";
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import FetchSelect from "../../../../../@WUM/core/component/const/Fetchselect";
import {btn_back} from "../../../../../@WUM/core/component/const/btn_back";
import {IntlMessageValue, UniForm} from '../../../../../@WUM/core/Elements';
import {init2} from "../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import React from "react";

const EventDetail = (props: { value: any }) => {
    const {id} = useParams<{ id: string }>();
    const Store = useSelector((state: any) => state)
    const response = ResponseEventSingle(id);
    const history = useNavigate();
    const back = btn_back(history, "common.back", "btnBack", Store)
    const send = (e: any) => {
        e.preventDefault();
        const form = e.currentTarget
        let Data = init2(form)
        var raw2 = JSON.stringify(
            {
                "id": id,
                "name": Data.name,
                "date": Data.start,
                "dateEnd": Data.end,
                "schedule": Data.schedule,
                "schedulename": Data.schedulename,
                "tag": Data.tag,
            }
        )

        let root = ApiURL();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw2,
        };
        fetch(root + "event/" + id + "/", requestOptions)
            .then(response => response.text())
            .then(result => Notification({
                type: 'success',
                mode: 'classic',
                title: {text: 'Success'},
                text: {text: 'Saved successfully'},
                insert: 'center right',
                duration: 4
            }))
            .catch(error => Notification({
                type: 'danger',
                mode: 'classic',
                title: {text: 'Error'},
                text: {text: 'Sorry, an error has occurred'},
                insert: 'center right',
                duration: 4
            }));
    }



    if (response !== null && response !== undefined) {
        return (<>
            <Row className={"px-2"}>
                <Form id={'form01'} onSubmit={(e) => send(e)}>
                    <Row>
                        <Col>
                            <h1>Settings of the general Events</h1>
                            <p className={"bg-danger text-white"}>Settings here can also have influence on other settings, such as the conference duration, invoice and more. Please be carefull!</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                                <UniForm.InputGroupText class={"mb-md-3"}
                                                        text={"Name"}
                                />
                                <Form.Control type="text" name={'name'} placeholder="Enter year"
                                              defaultValue={response.name}/>
                            </UniForm.InputGroup>

                        </Col>
                        <Col>
                            <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                                <UniForm.InputGroupText class={"mb-md-3"}
                                                        text={"Tag"}
                                />

                                <Form.Control
                                    type="text"
                                    defaultValue={response.tag}
                                    name={"tag"}
                                    readOnly={true}
                                    disabled={true}
                                />
                            </UniForm.InputGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="date">
                                    <Form.Label>start time</Form.Label>
                                    <Form.Control type="date" name={'start'} placeholder="Enter year"
                                                  defaultValue={response.date}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="dateEnd">
                                    <Form.Label>end time</Form.Label>
                                    <Form.Control type="date" name={'end'} placeholder="Enter year"
                                                  defaultValue={response.dateEnd}/>
                                </Form.Group>
                            </Col>
                        </Row>

                    </Row>
                    <Row>
                        <Col>
                            <UniForm.InputGroup class={"mb-md-0 mb-3"} >
                                <UniForm.InputGroupText class={"mb-md-3"}
                                                        text={IntlMessageValue('de', 'icy.event-calendar.choose-active', Store)}
                                                        InfoButton={{
                                                            display: true,
                                                            text: IntlMessageValue('de', 'icy.choose-active.expl', Store)
                                                        }}/>
                                <UniForm.Select freeValue={true} freeValueLabel={'Please choose'}
                                                name={'schedule'}
                                                items={
                                                    [
                                                        {
                                                            value: '1', text: 'Active'
                                                        },
                                                        {
                                                            value: '2', text: 'Inactive'
                                                        },
                                                    ]
                                                }
                                                defaultValue={response.schedule}
                                />
                            </UniForm.InputGroup>
                        </Col>
                        <Col>
                            <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                                <UniForm.InputGroupText class={"mb-md-3"}
                                                        text={"Schedule Name"}
                                />

                                <Form.Control
                                    type="text"
                                    defaultValue={response.schedulename}
                                    name={"schedulename"}
                                />
                            </UniForm.InputGroup>
                        </Col>

                    </Row>

                    <Row>
                        <Col>
                            {back}
                            <Button variant="primary" className={'float-end'} type="submit"
                                    style={{
                                        background: '#307593 0% 0% no-repeat padding-box',
                                        boxShadow: '0px 3px 6px #00000029',
                                        borderRadius: '34px',
                                        marginBottom: '20px',
                                        opacity: 1,
                                        float: 'right',
                                        height: '67px',
                                        width: '350px'
                                    }}>
                        <span style={{
                            font: 'normal normal bold 30px/40px Segoe UI',
                            letterSpacing: '0px',
                            color: '#FFFFFF',
                            opacity: 1
                        }}>
                             Save
                        </span>
                            </Button></Col>
                    </Row>
                    <Row>
                        <Col>
                            <br/> <br/>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </>)
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default EventDetail;
