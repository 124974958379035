import { IntlMessageValue } from "./IntlMessage";
import floatingLabel from "../../Elements/Form/Elements/FloatingLabel";

/**
 * @typedef {Object} ValidatorFields - Interface defining the structure of a field object for validation.
 * @property {string} element - The name of the HTML element associated with the field.
 * @property {boolean|null|string} [error] - An error message to display if validation fails, or null if there's no error, or a string to indicate pending validation.
 * @property {boolean|null|string} [valid] - A validation result: true for valid, false for invalid, null for unvalidated, or a string to indicate pending validation.
 */
export interface ValidatorFields {
    element:string
    error:boolean|null|string
    valid:boolean|null|string
    checked?:boolean
}

/**
 * Validates a set of form fields based on provided configuration.
 *
 * @param {Array} fieldNames - An array of objects containing configuration for each field to validate.
 *   - Each object should have the following properties:
 *     - `element` (string): The name of the form element to validate (matches the `name` attribute).
 *     - `error` (string | null): An error message to display if the field is invalid.
 *     - `valid` (string | null): A message to display if the field is valid (optional).
 * @param {Store} store - An optional store object used for internationalization (IntlMessageValue).
 * @returns {boolean} True if all fields are valid, false otherwise.
 */
const Validator = (fieldNames:any, store:any) => {
    let validCount = 0; // Use a more descriptive variable name

    for (let f = 0; f < fieldNames.length; f++) {
        const fieldName = fieldNames[f]; // Destructure for better readability
        const element = document.getElementsByName(fieldName.element)[0];

        if (!element) {
            console.error(`Element with name "${fieldName.element}" not found.`);
            continue;
        }

        // Remove existing icon container (if any)
        const existingIconContainer = element.parentNode?.querySelector('.icon-container');
        existingIconContainer?.remove();

        const iconContainer = document.createElement('span'); // Create a container for the icon

        // Add classes for styling and positioning
        iconContainer.classList.add('material-icons-outlined');
        iconContainer.classList.add('icon-container');
        iconContainer.classList.add('mt-2');
        iconContainer.classList.add('ml-2');

        element.parentNode?.insertBefore(iconContainer, element.nextSibling);

        element.classList.remove("invalid");
        element.classList.remove("valid");

        // Recheck Valid Data

        let checkValue;
        if(fieldName.checked === true) {
            checkValue = (element as HTMLFormElement).checked
        } else {
            checkValue = (element as HTMLFormElement).value
        }

        if (checkValue === (fieldName.checked === true ? false : '')) {
            validCount++;
            element.classList.add("invalid");
            iconContainer.innerHTML = 'error'; // Set invalid icon (Font Awesome)
            if (fieldName.error !== undefined && fieldName.error !== null) {
                iconContainer.title = IntlMessageValue('de', fieldName.error, store);
                element.title = IntlMessageValue('de', fieldName.error, store);
            }
            iconContainer.style.color = 'red'; // Set invalid icon color
        } else {
            element.classList.add("valid");
            iconContainer.innerHTML = 'check_circle'; // Set valid icon (Font Awesome)
            if (fieldName.valid !== undefined && fieldName.valid !== null) {
                iconContainer.title = IntlMessageValue('de', fieldName.valid, store);
                element.title = IntlMessageValue('de', fieldName.valid, store);
            }
            iconContainer.style.color = 'green'; // Set valid icon color
        }
    }

    return validCount === 0; // Check if all fields are valid
};

export default Validator;

/**
 * Example usage of fieldNames configuration
 *
 * const fieldNames = [
 *         {element:'title',error:'Fehler',valid:null},
 *         // ... other field configurations
 *     ];
 * */
