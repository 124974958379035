import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import React from "react";


const Keywords = (props:{keywords:any,readonly?:any,mode?:any,addElement?:any}) => {

    const deleteElement = (e:any) => {
        let element = e.target.parentElement.parentElement
        element.remove();
    }

    let keywords = props.keywords;

    if(keywords === undefined) {
        return (<><InputGroup className="mb-3 inputGroupExtra form-control" >
            <Form.Control
                readOnly={(props.readonly !== undefined && props.readonly !== null ? (props.mode === 'icymare' ? false :props.readonly) : (props.mode === 'icymare' ? false : true))}
                name={'Keywords0'}
                placeholder="No Keywords find"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                className={'inputNoBorder'}
            />
            {props.mode !== undefined && props.mode === 'icymare' ?
                <>
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                 onClick={()=>props.addElement(true,'12','Keywords','',true)}>
                                     <span className="material-icons">
                                    add
                                </span>
                </InputGroup.Text>
            <InputGroup.Text id="basic-addon2" className={'IconPlus'}>
                 <span
                     className="material-symbols-outlined" style={{color: '#317391'}} onClick={(e) => deleteElement(e)}>
                    delete
                    </span>
            </InputGroup.Text></>
                :
                <></>
            }
        </InputGroup></>)
    }

    let keywordsContainer = [];

    for(let a=0;a<keywords.length;a++){
        keywordsContainer.push(
        <>
            <InputGroup className="mb-3 inputGroupExtra form-control" >
                <InputGroup.Text>
                    {a+1}
                </InputGroup.Text>
                <Form.Control
                    readOnly={(props.readonly !== undefined && props.readonly !== null  ? (props.mode === 'icymare' ? false :props.readonly) : (props.mode === 'icymare' ? false : true))}
                    name={'Keywords'+a}
                    placeholder="Keywords"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                    defaultValue={keywords[a].tag}
                />
                {props.mode !== undefined && props.mode === 'icymare' ?
                    <>
                        <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                         onClick={()=>props.addElement(true,'12','Keywords','',true)}>
                                     <span className="material-icons">
                                    add
                                </span>
                        </InputGroup.Text>
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}>
                 <span
                     className="material-symbols-outlined" style={{color: '#317391'}} onClick={(e) => deleteElement(e)}>
                    delete
                    </span>
                </InputGroup.Text></>
                    :
                    <></>
                }
            </InputGroup>
        </>
    )
    }


    return (
        <Row id={'Keywords'}>
            <Row>
                <Col>
                {keywordsContainer}
                </Col>
            </Row>
        </Row>
    )

}

export default Keywords;
