import React from "react";
import {useSelector} from "react-redux";
import OverviewAbstracts from "./modules/overview";


export const StatisticsConfig = (value:any) => {
    const Store = useSelector((state: any) => state)
    let Role = Store.user.roles

    switch (Role) {
        case 'user':
            return [
            ];
        case 'icymare':
            return [
                {
                    path: '/statistics/overview-abstracts/:page',
                    elements: <OverviewAbstracts value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                },
            ];
        case 'superadmin':
            return [
                {
                    path: '/statistics/overview-abstracts/:page',
                    elements: <OverviewAbstracts value={value}/>,
                    roles: [
                        'superadmin',
                        'admin',
                        'host',
                        'user',
                        'icymare'
                    ]
                }
            ];
        default:
            return [
    ]
    }
}

    export const StatisticsMenuConfig = (value: any) => {
        const Store = useSelector((state: any) => state)
        let Role = Store.user.roles
        switch (Role) {
            case 'user':
                return [
                ]
            case 'host':
                return [
                ]
            case 'icymare':
                return [
                    {
                        name: 'Presentation Overview',
                        route: '/statistics/overview-abstracts/1',
                        icon: 'payments',
                        roles: [
                            'superadmin',
                            'admin',
                            'host',
                            'user',
                            'icymare'
                        ]
                    }
                ]
            case 'superadmin':
                return [
                    {
                        name: 'Presentation Overview',
                        route: '/statistics/overview-abstracts/1',
                        icon: 'payments',
                        roles: [
                            'superadmin',
                            'admin',
                            'host',
                            'user',
                            'icymare'
                        ]
                    },
                ]
            default:
                return [
                    {
                    }
                ]
        }
    }

    export const StatisticsMainMenuConfig = (value: any) => {
        return [
            {
                header: 'Statistics',
                icon: 'person',
                menuitem: [
                    ...StatisticsMenuConfig(value)
                ],
                roles: [
                    'superadmin',
                    'admin',
                    'icymare'
                ]
            },
        ];
    }

    export const Profilsettings = () => [
        {
            title: 'Statistics',
            href: '/statistics/overview-abstracts/1',
            undertitle: 'Profil',
            icon: 'payments',
            text: 'Profil'
        },
        {
            title: 'Certificate',
            href: '/statistics/overview-abstracts/1',
            undertitle: 'Profil',
            icon: 'receipt_long',
            text: 'Profil'
        }
    ]