/**
 * Removes all HTML tags from a given string.
 *
 * This function uses a regular expression to match all HTML tags and replaces them with an empty string.
 * While this provides a basic solution for removing HTML tags, it might not handle all edge cases or complex HTML structures.
 * For more advanced HTML sanitization, consider using libraries like DOMPurify.
 *
 * @param {string} str - The input string containing HTML tags.
 * @returns {string} The string with all HTML tags removed.
 */
export function removeHTMLTags(str:string) {
    // A simple regular expression to match HTML tags
    return str.replace(/<[^>]*>/g, '');
}