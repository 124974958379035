import {StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    customer: {
        fontFamily: "Raleway",
        fontSize: '11pt'
    },
    customerContainer: {
        marginTop: '6mm'
    }
});
