import IntlMessage from '../../../../../../@WUM/core/component/const/IntlMessage';
import React from "react";

/**
 *
 * @memberOf blog
 * @prop conf
 * @prop response
 * @prop page
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
function configuration(response:any,endpoint:any='') {

    return {
        tableBody: {
            data: response,
            dataID: 'id',
            underdata: true,
            keys: [
                'id',
                'confSession.title',
                'presentationTitle',
                'user.first_name',
                'user.last_name',
                'user.email',
                'presentationType.type'
            ],
            type: ['string','string','string', 'string', 'string', 'string', 'string'],
        },
        tableHeader: [
            'ID',
            'Session title',
            'Presentation title',
            'First name',
            'Last name',
            'E-Mail',
            'Type'
        ],
        tableHeaderWidth: ['auto','auto','auto', 'auto','auto','auto','auto'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: true,
            menutriggerDelete: true,
            menuItem: [
                'Show'
            ],
            menuroute: ['/Abstract/Detail'+endpoint],
            menuParams: ['', 'id', 'id'],
            menuIcons: ['pencil'],
        },
    }
}

export default configuration;
