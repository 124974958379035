import { ParseURLParams, ParsedURLData } from './ParseURLModel';

/**
 * Parses a URL string and returns an object containing key-value pairs from the query string.
 *
 * @param {ParseURLParams} params - The parameters containing the URL to parse.
 * @returns {ParsedURLData} An object containing key-value pairs from the query string.
 *
 * @example
 * // Example usage of parseURL
 * const params = { url: 'https://example.com/page?name=max&age=30' };
 * const parsedData = parseURL(params);
 * console.log(parsedData); // { name: "max", age: "30" }
 */
export function parseURL({ url }: ParseURLParams): ParsedURLData {

    // Extract the query string part of the URL
    const urlParams = new URLSearchParams(url.split('?')[1]);

    // Initialize an empty object to store the parsed data
    const parsedData: ParsedURLData = {};

    // Iterate over each key-value pair in the query string
    for (const [key, value] of urlParams.entries()) {
        parsedData[key] = value; // Add the key-value pair to the parsed data object
    }

    // Return the parsed data object
    return parsedData;
}
