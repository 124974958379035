/**
 * Interface representing a mapping between a user role and a discount code.
 *
 * @typedef {Object} discountCodeMapping
 * @property {string} role - The user role associated with the discount code.
 * @property {string} code - The discount code for the specified role.
 */
interface doscountCodeMapping {
    role:string,
    code:string
}
/**
 * An array of objects mapping user roles to discount codes.
 *
 * This array contains objects with properties `role` and `code`. The `role`
 * property specifies a user role (e.g., "Host", "Sponsor", "Team"), and the
 * `code` property specifies the corresponding discount code for that role.
 *
 * @type {discountCodeMapping[]}
 */
export const discountCodeMap:doscountCodeMapping[] = [
    {role:"3",code:'ICY_host_24'},
    {role:"4",code:'ICY_friend_24'},
    {role:"5",code:'ICY_family_24'},
]