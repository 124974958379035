import { PaymentPageConfigModel } from "./PaymentPageConfigModel";

/**
 * @author WUM Solution UG <Gregor Schalm>
 * Interface defining the structure of a Stripe status message model.
 */
export interface StripeStatusMessageModel {
    /**
     * The store associated with the Stripe status message. Necessary for translation
     */
    Store: any;

    /**
     * The current status of the Stripe transaction.
     */
    status: string;

    /**
     * Optional configuration object for the payment page (type: PaymentPageConfigModel).
     */
    configuration?: PaymentPageConfigModel;
}

/**
 * Defines the initial state of the Stripe status message model as an empty object with the specified properties.
 */
export const initialState: StripeStatusMessageModel = {
    Store: '',
    status: '',
};
