import {ValidatorFields} from "../../../../../@WUM/core/component/const/Validator";

/**
 * @constant {ValidatorFields[]} FieldNamesPersonal - An array of `ValidatorFields` objects for personal information fields.
 */
const FieldNamesRegistrationDetails:ValidatorFields[] = [
    {element:'registrationVariant',error:'Not provided',valid:null},
    {element:'acceptDataStorage',error:'Not provided',valid:null,checked:true},
    {element:'acceptPhoto',error:'Not provided',valid:null,checked:true},
];

export default FieldNamesRegistrationDetails
