import { FetchQrModel } from "../../Model/FetchQrModel";
import { useEffect, useState } from 'react';
import { qrroutes } from "../request/QrRoute";
import { error } from "../../../../../@WUM/core/Elements/index";

/**
 * Fetches QR code data based on the given properties and updates the component state.
 *
 * @function ResponsefetchQR
 *
 * @param {FetchQrModel} props - The properties for fetching QR code data.
 * @param {string} props.url - The URL to fetch the QR code data from.
 * @param {any} props.id - The unique identifier for the QR code. Changes to this value will trigger a data fetch.
 * @param {boolean} [props.urlWithId] - Optional. Indicates if the ID should be appended to the URL.
 *
 * @returns {FetchQrModel[] | undefined} An array of `FetchQrModel` objects if the fetch is successful; otherwise, undefined.
 *
 * @example
 * // Example usage of ResponsefetchQR
 * const fetchQrProps: FetchQrModel = {
 *    url: "https://example.com/api/qr",
 *    id: "12345"
 * };
 * const qrData = ResponsefetchQR(fetchQrProps);
 *
 * @see {@link FetchQrModel} for the structure of the props.
 *
 * @remarks
 * This function uses the `useEffect` hook to perform an asynchronous fetch operation
 * whenever the `id` in the `props` changes. The fetched data is stored in the component's state.
 * In case of an error during the fetch, an error state is set and logged.
 *
 * @since 2024
 *
 * @throws {Error} Will throw an error if the fetch operation fails.
 *
 * @author
 * Gregor Schalm
 */
export const ResponsefetchQR = (props: FetchQrModel) => {
    // State to hold the fetched QR data.
    const [posts, setPosts] = useState<FetchQrModel[]>();

    // State to indicate if an error occurred during the fetch.
    const [isError, setIsError] = useState<boolean>(false);

    // Log the ID to the console for debugging purposes.
    console.log(`${props.id} id`);

    useEffect(() => {
        (async () => {
            try {
                // Attempt to fetch QR data.
                const data = await qrroutes.updatePostQR({ url: props.url });

                // Update the state with the fetched data.
                setPosts(data);
                console.log(`${data} data`);
            } catch (err) {
                // Log the error and set the error state.
                error(err);
                setIsError(true);
                console.log("Error fetching QR data");
            }
        })();
    }, [props.id]); // Dependency array to re-run effect when `id` changes.

    // Return the fetched data or an error indication.
    return isError ? undefined : posts;
};
