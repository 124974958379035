import {LocationBarView, TimeFrameView} from "./";
import {Link} from "react-router-dom";
import React from "react";
import {removeHTMLTags} from "../../Controller/RemoveHTML.Controller";
import {truncateText} from "../../Controller/Truncate.Controller";


export const SessionOverviewView = (props: any ) => {


    const name = removeHTMLTags(props.name)
    const shortenedName = truncateText({ str: name, maxLength: 40 });

    return (
            <div style={{background: props.color}}>
                <TimeFrameView start={props.start} end={props.end}/>
                {props.state === 2 && (
                    <div className="event-changed-warning">{props.changeReason ? props.changeReason : "The time/venue has been updated"}</div>
                )}
                {props.state === 3 && (
                    <div className="event-changed-removed">{props.changeReason ? props.changeReason : "The session was cancelled"}</div>
                )}
                <LocationBarView locationLink={props.locationLink} locationAddress={props.locationAddress} locationHeadline={props.locationHeadline}/>
            <Link to={"/unbound/event-calendar/session-details/" + props.id} className={"href-no-highlight"} state={props.header}>

                <div className={props.shortDescription ? "py-4" : "py-3"} onClick={() => props.onDetailClick({ id: props.id })}>
                    <h3 className={"session-headline pb-2"}>Session: {props.sessionNumber}</h3>
                    <div className={"session-headline"} style={{fontSize: "14px"}}> {name}</div>
                    {props.shortDescription && (
                    <div className={"session-description pt-2"}>{props.shortDescription}</div>
                    )}
                </div>
            </Link>
            </div>
    )
}
