import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import '../css/dashboard.css'
import {useSelector} from "react-redux";
import {Cards, IntlMessage} from "../../../../../@WUM/core/Elements/index";
import {MergeTwoObjecttoArray} from "../../../../../@WUM/core/Function/index";
import {ShowDialog} from "../../../../../@WUM/core/sidebar/sidebar";
import {Profilsettings} from "../../../profil";

const DashbaordOverview = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)


    let dashboard = [];
    let conf = configruation(Store);
    for(let x=0;x<conf.length;x++){
        if(conf[x].onClick !== undefined) {
            dashboard.push(
                <>
                    <Col lg={2}><Cards title={conf[x].title}
                                       href={conf[x].href}
                                       icon={conf[x].icon}
                                       undertitle={conf[x].undertitle}
                                       text={conf[x].text} Store={Store}
                                       onClick={conf[x].onClick}/></Col>
                </>
            )
        } else {
            dashboard.push(
                <>
                    <Col lg={2}><Cards title={conf[x].title}
                                       href={conf[x].href}
                                       icon={conf[x].icon}
                                       undertitle={conf[x].undertitle}
                                       text={conf[x].text} Store={Store}/></Col>
                </>
            )
        }

    }
    dashboard.push(
        <>
            <Col lg={2}><Cards title={'Contact IcyMare'}
                               href="mailto:support@icymare.com"
                               icon={'contact_support'}
                               undertitle={'contact'}
                               loginIcon={'email'}
                               text={'Mail Contact to IcyMare'} Store={Store}/></Col>
        </>
    )
    return (<>
        <Row className={'DashbaordRow text-center'}>
            {dashboard}
        </Row>
    </>)
}

export default DashbaordOverview;

const configruation = (Store:any) => {

    let config: any = [];
    let Role = Store.user.roles

    if(process.env.REACT_APP_ABSTRACT !== undefined && process.env.REACT_APP_ABSTRACT === 'True') {
        config =  MergeTwoObjecttoArray(config,abstract(Store))
    }

    config =  MergeTwoObjecttoArray(config,SessionHost(Store))
    if(Role === 'icymare' || Role === 'superadmin') {
        config =  MergeTwoObjecttoArray(config,settings())
        config =  MergeTwoObjecttoArray(config,itSupport())
    }
    config =  MergeTwoObjecttoArray(config,Profilsettings())
    return config;



}

const settings = () => [
    {
        title: 'Session',
        href: '/Session/overview/1',
        undertitle:'Settings',
        icon: 'dialer_sip',
        text: 'Session'
    },
    {
        title: 'Education Type',
        href: '/Education/overview/1',
        undertitle:'Settings',
        icon: 'school',
        text: 'Education Type'
    },
    {
        title: 'Presentation Type',
        href: '/Presentation/overview/1',
        undertitle:'Settings',
        icon: 'co_present',
        text: 'Presentation Type'
    },
    {
        title: 'Userverwaltung',
        href: '/Member/overview/1',
        undertitle:'Settings',
        icon: 'manage_accounts',
        text: 'Userverwaltung'
    }
]


const itSupport = () => [
    {
        title: 'My Project',
        href: '/myprojects/task/all/projects/'+process.env.REACT_APP_MODE_TICKET_ID+'/'+process.env.REACT_APP_MODE_TICKET_NAME+'',
        undertitle:'IT-Support',
        icon: 'note_alt',
        text: 'menu.item.dashboard.description'
    }
]

const SessionHost = (Store:any) => {
    let Role = Store.user.roles

    switch(Role){
        case 'user':
            return[
                {
                    title: 'Session submission',
                    href: '/SessionHost/new',
                    undertitle:'Session submission',
                    icon: 'schedule_send',
                    text: 'Session New'
                },
                {
                    title: 'Session submission overview ',
                    href: '/SessionHost/overviewUser/1',
                    undertitle:'Session submission',
                    icon: 'dashboard',
                    text: 'Session submission'
                }
            ]
        case 'icymare':
            return[
                {
                    title: 'Session submission',
                    href: '/SessionHost/new',
                    undertitle:'Session submission',
                    icon: 'schedule_send',
                    text: 'Session submission'
                },
                {
                    title: 'Session submission overview',
                    href: '/SessionHost/overview/1',
                    undertitle:'Session submission',
                    icon: 'dashboard',
                    text: 'Session Overview'
                }
            ]
        case 'superadmin':
            return[
                {
                    title: 'Session submission',
                    href: '/SessionHost/new',
                    undertitle:'Session submission',
                    icon: 'schedule_send',
                    text: 'Session new'
                },
                {
                    title: 'Session submission overview',
                    href: '/SessionHost/overview/1',
                    undertitle:'Session submission',
                    icon: 'dashboard',
                    text: 'Session Overview'
                },
                {
                    title: 'Session submission overview ',
                    href: '/SessionHost/overviewUser/1',
                    undertitle:'Session submission user',
                    icon: 'dashboard',
                    text: 'Abstract Overview'
                }
            ]
        default:
            return[
                {
                    title: 'Submit Call for Session',
                    href: '/SessionHost/new',
                    undertitle:'Session submission',
                    icon: 'schedule_send',
                    text: 'Session new'
                },
                {
                    title: 'Session submission overview ',
                    href: '/SessionHost/overviewUser/1',
                    undertitle:'Session submission',
                    icon: 'dashboard',
                    text: 'Session overview'
                }
            ]
    }

}

const abstract = (Store:any) => {
    let Role = Store.user.roles

    switch(Role){
        case 'user':
            return[
                {
                    title: 'Submit Abstract',
                    href: '/Abstract/new',
                    undertitle:'Abstract',
                    icon: 'schedule_send',
                    text: 'Abstract New'
                },
                {
                    title: 'Abstract Overview ',
                    href: '/Abstract/overviewUser/1',
                    undertitle:'Abstract',
                    icon: 'dashboard',
                    text: 'Abstract Overview'
                }
            ]
        case 'host':
            return[
                {
                    title: 'Submit Abstract',
                    href: '/Abstract/new',
                    undertitle:'Abstract',
                    icon: 'schedule_send',
                    text: 'Abstract New'
                },
                {
                    title: 'Abstract Overview ',
                    href: '/Abstract/overviewSessionHost/1',
                    undertitle:'Abstract',
                    icon: 'dashboard',
                    text: 'Abstract Overview'
                }
            ]
        case 'icymare':
            return[
                {
                    title: 'Submit Abstract',
                    href: '/Abstract/new',
                    undertitle:'Abstract',
                    icon: 'schedule_send',
                    text: 'Abstract New'
                },
                {
                    title: 'Abstract Overview',
                    href: '/Abstract/overview/1',
                    undertitle:'Abstract',
                    icon: 'dashboard',
                    text: 'Abstract Overview'
                }
            ]
        case 'superadmin':
            return[
                {
                    title: 'Submit Abstract',
                    href: '/Abstract/new',
                    undertitle:'Abstract',
                    icon: 'schedule_send',
                    text: 'Abstract New'
                },
                {
                    title: 'Abstract Overview',
                    href: '/Abstract/overview/1',
                    undertitle:'Abstract',
                    icon: 'dashboard',
                    text: 'Abstract Overview'
                },
                {
                    title: 'Abstract Overview ',
                    href: '/Abstract/overviewSessionHost/1',
                    undertitle:'Abstract Session Host',
                    icon: 'dashboard',
                    text: 'Abstract Overview'
                },
                {
                    title: 'Abstract Overview ',
                    href: '/Abstract/overviewUser/1',
                    undertitle:'Abstract User',
                    icon: 'dashboard',
                    text: 'Abstract Overview'
                }
            ]
        default:
            return[
                {
                    title: 'Submit Abstract',
                    href: '/Abstract/new',
                    undertitle:'Abstract',
                    icon: 'schedule_send',
                    text: 'Abstract New'
                },
                {
                    title: 'Abstract Overview ',
                    href: '/Abstract/overviewUser/1',
                    undertitle:'Abstract',
                    icon: 'dashboard',
                    text: 'Abstract Overview'
                }
            ]
    }

}

