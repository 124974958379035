import {InfoButton, IntlMessage, IntlMessageValue, UniCol, UniForm, UniRow} from "../../../../../@WUM/core/Elements"
import React from "react";
import {HeadlineHelp} from "../../../../components/Elements/headlineHelp"
import {HasUserInterface} from "../../API/reponse/HasUser.interface";

interface InstitutionalInfoInterface {
    Store: any,
    Country: any
    UserDataOld: HasUserInterface | undefined
}

export const InstitutionalInfo = (props: InstitutionalInfoInterface) => {

    return (
        <>
            <HeadlineHelp Store={props.Store} headType={"h2"} headlineText={"icy.institution.address"}
                          descriptionText={"icy.institution.explanation"}/>
            {/* Necessary to transfer the data to the correct ID of personal_details  */}
            <UniForm.FormControl type="hidden" name={'institutional_address_id'} defaultValue={(props.UserDataOld?.institutional_address.id !== 0 && props.UserDataOld !== undefined ? props.UserDataOld.institutional_address.id : '0')}/>
            <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                <UniForm.InputGroupText class={"mb-md-3"}
                    text={IntlMessageValue('de', 'icy.name', props.Store)}
                    InfoButton={{
                        display: true,
                        text: IntlMessageValue('de', 'icy.name.institutional.explanation.registration', props.Store)
                    }}/>
                <UniForm.FormControl type="text" name={'nameAff'} placeholder="Enter institution name" id={'nameAff'} defaultValue={props.UserDataOld?.institutional_address.nameAff}/>
            </UniForm.InputGroup>

            <UniRow>
                <UniCol sm={12} md={6}>
                    <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                        <UniForm.InputGroupText class={"mb-md-3"}
                            text={IntlMessageValue('de', 'icy.street', props.Store)}
                            InfoButton={{
                                display: true,
                                text: IntlMessageValue('de', 'icy.street.institut.explanation.registration', props.Store)
                            }}/>
                        <UniForm.FormControl type="text" name={'streetAff'} placeholder="Enter street" id={'streetAff'} defaultValue={props.UserDataOld?.institutional_address.streetAff}/>
                    </UniForm.InputGroup>
                </UniCol>
                <UniCol sm={12} md={6}>
                    <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                        <UniForm.InputGroupText class={"mb-md-3"}
                            text={IntlMessageValue('de', 'icy.street.number', props.Store)}
                            InfoButton={{
                                display: true,
                                text: IntlMessageValue('de', 'icy.number.institut.expl.regist', props.Store)
                            }}/>
                        <UniForm.FormControl type="text" name={'numberAff'} placeholder="Enter number" id={'numberAff'} defaultValue={props.UserDataOld?.institutional_address.numberAff}/>
                    </UniForm.InputGroup>
                </UniCol>
            </UniRow>
            <UniRow>
                <UniCol sm={12} md={6}>
                    <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                        <UniForm.InputGroupText class={"mb-md-3"}
                            text={IntlMessageValue('de', 'icy.zipcode', props.Store)}
                            InfoButton={{
                                display: true,
                                text: IntlMessageValue('de', 'icy.zip.institut.expl.regist', props.Store)
                            }}/>
                        <UniForm.FormControl type="text" name={'zipAff'} placeholder="Enter Zip" id={'zipAff'} defaultValue={props.UserDataOld?.institutional_address.zipAff}/>
                    </UniForm.InputGroup>
                </UniCol>
                <UniCol sm={12} md={6}>
                    <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                        <UniForm.InputGroupText class={"mb-md-3"}
                            text={IntlMessageValue('de', 'icy.city', props.Store)}
                            InfoButton={{
                                display: true,
                                text: IntlMessageValue('de', 'icy.city.institut.expl.regist', props.Store)
                            }}/>
                        <UniForm.FormControl type="text" name={'cityInstitut'} placeholder="Enter city" id={'cityInstitut'} defaultValue={props.UserDataOld?.institutional_address.cityInstitut}/>
                    </UniForm.InputGroup>
                </UniCol>
            </UniRow>
            <UniRow>
                <UniCol sm={12} md={6}>
                    <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                        <UniForm.InputGroupText class={"mb-md-3"}
                            text={IntlMessageValue('de', 'icy.country', props.Store)}
                            InfoButton={{
                                display: true,
                                text: IntlMessageValue('de', 'icy.country.inst.explanation.registration', props.Store)
                            }}/>
                        <UniForm.Select freeValue={true} freeValueLabel={'Please choose'} name={'CountryAdressInstitutional'}
                                        items={
                                            // Country Holder
                                            props.Country
                                        }
                                        defaultValue={props.UserDataOld?.institutional_address.countryInstitut}
                        />
                    </UniForm.InputGroup>

                </UniCol>
                <UniCol sm={12} md={6}>
                    <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                        <UniForm.InputGroupText class={"mb-md-3"}
                            text={IntlMessageValue('de', 'icy.vat', props.Store)}
                            InfoButton={{
                                display: true,
                                text: IntlMessageValue('de', 'icy.vat.institut.expl.regist', props.Store)
                            }}/>
                        <UniForm.FormControl type="text" name={'vatInstitut'} placeholder="Enter VAT" id={'vatInstitut'} defaultValue={props.UserDataOld?.institutional_address.vatInstitut}/>
                    </UniForm.InputGroup>
                </UniCol>
            </UniRow>


            <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                <UniForm.InputGroupText class={"mb-md-3"}
                    text={IntlMessageValue('de', 'icy.select.adress', props.Store)}
                    InfoButton={{
                        display: true,
                        text: IntlMessageValue('de', 'icy.select.adress.explanation', props.Store)
                    }}/>
                <UniForm.Select freeValue={true} freeValueLabel={'Please choose'} name={'InvoicePersonal'}
                                items={
                                    // customerHolder
                                    [{value: '1', text: 'Address (Personal)'}, {
                                        value: '2',
                                        text: 'Address (Institutional)'
                                    }]
                                }
                                defaultValue={props.UserDataOld?.institutional_address.InvoicePersonal}
                />
            </UniForm.InputGroup>
        </>
    )
}
