import { CSSProperties } from "react";

/**
 * Interface representing the QR Controller model.
 *
 * @interface QrControllerModel
 *
 * @property {string} url - The base URL to generate the QR code for.
 * @property {boolean} [urlWithId] - Optional. Determines if the URL should include an ID.
 * @property {any} [id] - Optional. The ID to include in the URL if required.
 * @property {number} width - The width of the QR code.
 * @property {number} height - The height of the QR code. (Note: There is a typo; it should be "height").
 * @property {string} [className] - Optional. The CSS class to apply for styling.
 * @property {CSSProperties} [style] - Optional. Inline styles for the QR code.
 * @property {string} [ImgId] - Optional. The ID attribute to set for the QR code image element.
 *
 * @since 2024
 *
 * @example
 * // Example of QrControllerModel usage
 * const qrConfig: QrControllerModel = {
 *   url: 'https://example.com',
 *   width: 300,
 *   height: 300,
 *   className: 'qr-code',
 *   style: { border: '1px solid black' }
 * };
 *
 * @see CSSProperties for more information on style properties.
 *
 * @remarks
 * This model is used to configure the appearance and data of a QR code component.
 *
 * @typedef {object} QrControllerModel
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Properties} for CSS properties.
 *
 * @example
 * import { QrControllerModel } from './QrControllerModel';
 *
 * @author Gregor Schalm
 */
export interface QrControllerModel {
    url: string;
    urlWithId?: boolean;
    id?: any;
    width: number;
    height: number;
    className?: string | undefined;
    style?: CSSProperties | undefined;
    ImgId?: string | undefined;
}

/**
 * The initial state for the QR Controller model.
 * @type {QrControllerModel}
 *
 * @property {number} width - The default width for the QR code.
 * @property {number} height - The default height for the QR code.
 * @property {any} id - The default ID to use in the QR code URL.
 * @property {string} url - The default URL for the QR code.
 * @property {boolean} [urlWithId=true] - Specifies that the URL should include an ID by default.
 *
 * @see QrControllerModel for the interface definition.
 */
export const initialState: QrControllerModel = {
    width: 200,
    height: 200,
    id: 0,
    url: '',
    urlWithId: true
};