import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import React from "react";


const Affiliations = (props:{affiliations:any,readonly?:any,mode?:any,addElement?:any}) => {

    let affiliations = props.affiliations;

    const deleteElement = (e:any) => {
        let element = e.target.parentElement.parentElement
        element.remove();
    }

    if(affiliations === undefined) {
        return (<><InputGroup className="mb-3 inputGroupExtra form-control">
            <Form.Control
                readOnly={(props.readonly !== undefined && props.readonly !== null ? (props.mode === 'icymare' ? false :props.readonly) : (props.mode === 'icymare' ? false : true))}
                name={'Affiliations0'}
                placeholder="No Affiliations find"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                className={'inputNoBorder'}
            />
        </InputGroup></>)
    }

    let affiliationsContainer = [];

    for(let a=0;a<affiliations.length;a++){
        affiliationsContainer.push(
        <>
            <InputGroup className="mb-3 inputGroupExtra form-control">
                <InputGroup.Text>
                    Author:
                </InputGroup.Text>
                    <Form.Control className={"col-1"} type="text" name={'affiliationText'+a}  defaultValue={affiliations[a].authorAffId} readOnly={(props.readonly !== undefined && props.readonly !== null ? (props.mode === 'icymare' ? false :props.readonly) : (props.mode === 'icymare' ? false : true))}/>
                <InputGroup.Text>
                    Affiliations:
                </InputGroup.Text>
                <Form.Control
                    readOnly={(props.readonly !== undefined && props.readonly !== null ? (props.mode === 'icymare' ? false :props.readonly) : (props.mode === 'icymare' ? false : true))}
                    name={'Affiliations'+a}
                    placeholder="Affiliations"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                    defaultValue={affiliations[a].affiliationText}
                />

                {props.mode !== undefined && props.mode === 'icymare' ?
                    <>
                    <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                     onClick={()=>props.addElement(true,'12','Affiliations','')}>
                                 <span className="material-icons">
                                    add
                                </span>
                    </InputGroup.Text>
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}>
                 <span
                     className="material-symbols-outlined" style={{color: '#317391'}} onClick={(e) => deleteElement(e)}>
                    delete
                    </span>
                </InputGroup.Text></>
                    :
                    <></>
                }
            </InputGroup>
        </>
    )
    }


    return (
        <Row id={'Affiliations'}>
            <Row>
                <div className={'col-12'}>
                {affiliationsContainer}
            </div>
            </Row>
        </Row>
    )

}

export default Affiliations;
