import React, {ChangeEvent, useState} from "react";
import Image from 'react-bootstrap/Image';


interface NameBadgePreviewInterface {
    name: string,
    pronoun: string,
    affiliation: string,
    interests: string

}

export const NameBadgePreview = (props: NameBadgePreviewInterface) => {


    return (
        <>
                <div style={{width: "9cm", height: "6cm", background: "white"}} className={"shadow-sm"}>
                    <div style={{
                        paddingLeft: '6mm',
                        paddingRight: '6mm',
                        paddingTop: '3mm',
                        paddingBottom: '1mm',
                        textAlign: "center"
                    }}>
                        <div style={{
                            fontFamily: "Days One",
                            fontSize: "18pt",
                            color: "#31718F",
                            marginBottom: "1mm"
                        }}>{props.name}</div>
                        <div style={{
                            fontFamily: "Raleway",
                            fontWeight: 500,
                            color: "#31718F",
                            fontSize: "12pt",
                            marginBottom: "2.5mm"
                        }}>{props.pronoun}</div>
                        <div style={{
                            fontFamily: "Raleway",
                            fontWeight: 500,
                            color: "#153A67",
                            fontSize: "11pt",
                            fontStyle: "italic",
                            marginBottom: "2.5mm"
                        }}>{props.affiliation}</div>
                        <div style={{
                            fontFamily: "Raleway",
                            fontWeight: 500,
                            color: "#153A67",
                            fontSize: "12pt",
                        }}>{props.interests}</div>
                    </div>
                    <div style={{position: "absolute", bottom: "2mm", left: "4mm", height: "13.5mm", width: "80.6mm"}}>
                        <Image src={"/pdf-images/IcymareBadgeGraph.png"}/>
                    </div>
                </div>
        </>
    )
}
