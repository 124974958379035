import {useNavigate, useParams} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup'
import '../css/dashboard.css'
import {useSelector} from "react-redux";
import ReactDOM from "react-dom";
import {ResponseLevel, ResponsePrasentationType, ResponseSession,ResponseAbstractSingle} from "../../API/request/abstract";
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import Badge from "react-bootstrap/Badge";
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import {btn_back} from "../../../../../@WUM/core/component/const/btn_back";
import Keywords from "../helper/keywords";
import Authors from "../helper/authors";
import Affiliations from "../helper/affiliation";
import SunEditor from "suneditor-react";
import {SecureRequest} from "./detail";
const AbstractDetailUser = (props: { value: any }) => {
    const {id} = useParams<{ id: string }>();
    const Store = useSelector((state: any) => state)
    let authorint = 0;
    let keywordsint = 0;
    let affiliationsint = 0;
    const Level = ResponseLevel();
    const response = ResponseAbstractSingle(id)
    const PresentationType = ResponsePrasentationType();
    const history = useNavigate();
    const back =  btn_back(history,"common.back","btnBack",Store)
    const Session = ResponseSession();
    const SunEditorConfig = () => {
        return [
            // Default
            ['undo', 'redo','italic','subscript', 'superscript','removeFormat','fullScreen'],
        ]
    }
   const send = (e:any) => {
        e.preventDefault();
        let data = new FormData(e.target);
        let value = Object.fromEntries(data.entries());
        let raw = JSON.stringify(value);
       let root = ApiURL();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw,
        };
        fetch(root+"Conference/CfS/"+id+"/", requestOptions)
            .then(response => response.text())
            .then(result => afterSend('success'))
            .catch(error => afterSend('error'));
   }

    const afterSend = (state:any) => {
        if(state === 'success') {
            Notification({reload:true,type:'success', mode:'classic', title:{text:'Success'}, text:{text: 'Saved successfully'}, insert:'center right',duration:4})
        } else if(state === 'error'){
            Notification({reload:true,type:'success', mode:'classic', title:{text:'Success'}, text:{text: 'Saved successfully'}, insert:'center right',duration:4})
            /** Todo sobald in Nami gefix das die route wieder geht wieder den error anzeigen */
            /*Notification({reload:true,type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Sorry, an error has occurred'}, insert:'center right',duration:4})*/
        }
    }


    const addElement = (row:any = false,col:any,id:any,addClass:any='') => {
        let counter;
        if(id==='Authors') {
            authorint++;
            counter= authorint;
        }
        if(id==='Keywords'){
            keywordsint++;
            counter= keywordsint;
        }

        if(id==='Affiliations') {
            affiliationsint++;
            counter= affiliationsint;
        }
        if(row === true) {
            let newElement = (<><div className={'col-'+col}><InputGroup className={"mb-3 inputGroupExtra form-control "+addClass}>
                <Form.Control
                    name={id+''+counter}
                    placeholder="Lorem Ipsum"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                />
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                 onClick={()=>addElement(row,col,id,addClass)}>
                                <span className="material-icons">
                                    add
                                </span>
                </InputGroup.Text>
            </InputGroup></div></>);
            let temp = document.createElement('div');
            temp.classList.add('row')
            ReactDOM.render(newElement, temp);
            let ele =  document.getElementById(id);
            if(ele !== null) {
                ele.appendChild(temp)
            }
        } else {
            let newElement = (<><InputGroup className={"mb-3 inputGroupExtra form-control "+addClass}>
                <Form.Control
                    name={id+''+counter}
                    placeholder="Lorem Ipsum"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                />
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                 onClick={()=>addElement(row,col,id,addClass)}>
                                <span className="material-icons">
                                    add
                                </span>
                </InputGroup.Text>
            </InputGroup></>);
            let temp = document.createElement('div');
            temp.classList.add('col-'+col)
            ReactDOM.render(newElement, temp);
            let ele =  document.getElementById(id);
            if(ele !== null) {
                ele.appendChild(temp)
            }
        }

    }

    if(Level.length > 0 && PresentationType.length > 0 && Session.length > 0 && response.length > 0) {
        let optionLevel = [];

        let optionPresentationType = [];

        let optionSession = [];

        for(let l=0;l<Level.length;l++){
            optionLevel.push(
                <>
                    <option value={Level[l].id}>{Level[l].type}</option>
                </>
            )
        }

        for(let l=0;l<PresentationType.length;l++){
            optionPresentationType.push(
                <>
                    <option value={PresentationType[l].id}>{PresentationType[l].type}</option>
                </>
            )
        }

        for(let l=0;l<Session.length;l++){
            optionSession.push(
                <>
                    <option value={Session[l].id}>{Session[l].title}</option>
                </>
            )
        }
        let badgeColor;
        let badgeText;
        switch(response[0].sesonState) {
            case 3:
                badgeColor = 'success';
                badgeText = 'accepted by Host';
                break;
            case 2:
                badgeColor = 'warning';
                badgeText = 'returned for rework by Host';
                break;
            case 1:
                badgeColor = 'danger';
                badgeText = 'declined by Host';
                break;
            case 0:
                badgeColor = 'danger';
                badgeText = 'resubmitted';
                break;
            default:
                badgeColor = 'warning';
                badgeText = 'passed to Host';
        }
        let icybadgeColor;
        let icybadgeText;
        let state = [
            {name: 'submitted',color:'danger'},
            {name: 'Icymare: Declined ',color:'danger'},
            {name: 'Icymare: Rework by user',color:'warning'},
            {name: 'Icymare: Passed to Host',color:'warning'},
            {name: 'Host: Not suitable for session',color:'danger'},
            {name: 'Host: Revision by user',color:'warning'},
            {name: 'Icymare: Final acceptance',color:'success'}]

        for(let s =0;s<state.length;s++) {
            if(state[s].name === response[0].state){
                badgeColor = state[s].color;
                badgeText = state[s].name;
            }
        }
        return (<>
            <Row className={''}>
                <Row>
                    <Col xs={4} md={4} lg={4} style={{background: 'transparent linear-gradient(270deg, #0CB4CE 0%, #317391 100%) 0% 0% no-repeat padding-box',color:'white',maxHeight:'840px'}}>
                        <br />
                        <p className={'text-center'}><img src={'https://cdn.wum-solution.de//icymareKonfDev/2023/12/18/jU/ICYMARE-Logo-wide-screen.png'} /></p>
                        <br />
                        <h2>Abstract Submission ICYMARE 2023 Oldenburg</h2>
                        <p> <img src={"https://cdn.wum-solution.de//icymareKonfDev/2023/12/18/Ma/ICYMARE-2024-BREMEN-CallForSessions.jpg"} /></p>
                        <br />
                        <p></p>
                       {/* <p className={'text-center'}><strong>State ICYMARE:</strong> <Badge bg={icybadgeColor}> {icybadgeText}
                        </Badge></p>*/}
                        <p className={'text-center'}><strong>State:</strong> <Badge bg={badgeColor}> {badgeText}
                        </Badge></p>
                    </Col>
                    <Col style={{background: '#FFFFFF 0% 0% no-repeat padding-box',maxHeight:'840px',overflowX:'hidden',overflowY:'scroll'}}>  <Form id={'form01'} onSubmit={(e)=> send(e)}>
                        <Row> <h3>Presenting Author</h3></Row><Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="Title">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" name={'academic_title'} placeholder="Enter Title" defaultValue={response[0].academic_title}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="FirstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" name={'FirstName'} placeholder="Enter first name" defaultValue={response[0].user.first_name}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="LastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" name={'LastName'} placeholder="Enter last name" defaultValue={response[0].user.last_name}/>
                            </Form.Group>
                        </Col>
                    </Row>
                        <Row>

                            <Col>
                                <Form.Group className="mb-3" controlId="Emailaddress">
                                    <Form.Label>Email address:</Form.Label>
                                    <Form.Control type="email" name={'Emailaddress'} placeholder="Enter email adress" defaultValue={response[0].user.email}/>
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row><Col><Form.Group className="mb-3" controlId="Mainaffiliation">
                            <Form.Label>Main affiliation</Form.Label>
                            <Form.Control type="text" name={'mainAffiliation'} placeholder="Enter main affiliation" defaultValue={response[0].mainAffiliation}/>
                        </Form.Group></Col></Row>


                        <Row><h3>Education Level</h3>
                            <p>We will award the best presentations at ICYMARE 2023 Oldenburg from early career researcher (ECR). Regarding to our internal regulations, you are an ECR till up to 5 years after finishing your PhD. Please enter here your graduation level and month/year when you had completed.</p>
                        </Row> <Row>

                        <Col>
                            <Form.Label>Level</Form.Label>
                            <Form.Select aria-label="Default select example" name={'Level'} defaultValue={response[0].educationLevel.levelId}>
                                {optionLevel}
                            </Form.Select>
                        </Col>
                        <Col><Form.Group className="mb-3" controlId="Year">
                            <Form.Label>Year</Form.Label>
                            <Form.Control type="text"  name={'Year'} placeholder="Enter Year" defaultValue={response[0].educationLevel.year}/>
                        </Form.Group></Col>
                    </Row>

                        <Row><h3>Sessions</h3>
                            <p>Please choose the session in which you want to present your work and the type of presentation via the drop-down list below:</p>
                        </Row>
                        {/*<Row>
                            <Col>
                                <Form.Select aria-label="Default select example" name={'Sessions'} defaultValue={response[0].confSession.id} disabled={true}>
                                    {optionSession}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Select aria-label="Default select example" name={'PresentationType'} defaultValue={response[0].presentationTypeId}>
                                    {optionPresentationType}
                                </Form.Select>
                            </Col>
                        </Row>*/}
                        <br />
                        <Row>
                            <Form.Group className="mb-3" controlId="PresentationTitle">
                                <Form.Label>Session title</Form.Label>
                                {/*<Form.Control type="text" name={'title'} placeholder="Enter presentation title" defaultValue={response[0].title}/>*/}
                                <SunEditor
                                    key={'test'}
                                    name={'Title'}
                                    placeholder="Enter title"
                                    defaultValue={response[0].title}
                                    setOptions={{
                                        buttonList: SunEditorConfig(),
                                        attributesWhitelist: {
                                            div: 'id',
                                            p: 'id',
                                            h: 'id'
                                        }
                                    }}
                                    height={'250px'}
                                />
                              </Form.Group>
                        </Row>

                        <Row><h3>Additional Session Hosts:</h3>
                            <p>Please fill in all authors (including yourself) contributing to your work in the right order. Use the + for adding more arrays (click on the field).</p>
                        </Row>
                        <Authors author={response[0].authors} readonly={false} />
                        <br />
                        <p>Please fill in all affiliations of the contributing authors according to the numbers stated above. Use the + for adding more arrays (click on the field).</p>
                        <Affiliations affiliations={response[0].affiliates} readonly={false}  newlabel={'Host'}  />
                        <br />
                        <br />
                        <Row><h3>Social Media Handles</h3>
                            <p>Please provide us with your social media handles (“@...”) to enable us to tag you:</p>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="Twitter">
                                    <Form.Label>Twitter</Form.Label>
                                    <Form.Control type="text"  name={'Twitter'} placeholder="Enter Twitter account" defaultValue={response[0]?.socialMediaInstitution[0]?.Twitter}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="Instagram">
                                    <Form.Label>Instagram</Form.Label>
                                    <Form.Control type="text" name={'Instagram'} placeholder="Enter Instagram account" defaultValue={response[0]?.socialMediaInstitution[0]?.Instagram}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="Facebook">
                                    <Form.Label>Facebook</Form.Label>
                                    <Form.Control type="text" name={'Facebook'} placeholder="Enter Facebook account" defaultValue={response[0]?.socialMediaInstitution[0]?.Facebook}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="LinkedIn">
                                    <Form.Label>LinkedIn</Form.Label>
                                    <Form.Control type="text" name={'LinkedIn'} placeholder="Enter LinkedIn account" defaultValue={response[0]?.socialMediaInstitution[0]?.LinkedIn}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row><h4>Social Media Handles (Institution)</h4>
                            <p>Please provide us with the social media handles of your first affiliation to enable us to tag the institution (preferably “@...”, otherwise also “#...” is possible):</p>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionTwitter">
                                    <Form.Label>Twitter</Form.Label>
                                    <Form.Control type="text" name={'InstitutionTwitter'} placeholder="Enter Twitter account" defaultValue={response[0]?.socialMediaInstitution[0]?.InstitutionTwitter}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionInstagram">
                                    <Form.Label>Instagram</Form.Label>
                                    <Form.Control type="text" name={'InstitutionInstagram'} placeholder="Enter Instagram account" defaultValue={response[0]?.socialMediaInstitution[0]?.InstitutionInstagram}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionFacebook">
                                    <Form.Label>Facebook</Form.Label>
                                    <Form.Control type="text" name={'InstitutionFacebook'} placeholder="Enter Facebook account" defaultValue={response[0]?.socialMediaInstitution[0]?.InstitutionFacebook}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionLinkedIn">
                                    <Form.Label>LinkedIn</Form.Label>
                                    <Form.Control type="text" name={'InstitutionLinkedIn'} placeholder="Enter LinkedIn account" defaultValue={response[0]?.socialMediaInstitution[0]?.InstitutionLinkedIn}/>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row><h3>Keywords</h3>
                            <p>Please provide max. 5 keywords about your presentation topic. Terms should not be already part the title but should also not be too general.</p>
                        </Row>
                        <Keywords keywords={response[0].tags} readonly={false} />

                        <Row><h3>Abstract</h3>
                            <p>Please insert your abstract here. It can have 300 words at maximum. Please to not use citations, references, figures, etc.</p>
                        </Row>
                        <Row>
                            <Form.Group className="mb-3" controlId="Abstract">
                                {/*<Form.Control as="textarea" name={'Abstract'} rows={5} defaultValue={response[0].abstract}/>*/}
                                <SunEditor
                                    key={'test'}
                                    name={'Abstract'}
                                    placeholder="Enter Abstract"
                                    defaultValue={response[0].abstract}
                                    setOptions={{
                                        buttonList: SunEditorConfig(),
                                        attributesWhitelist: {
                                            div: 'id',
                                            p: 'id',
                                            h: 'id'
                                        }
                                    }}
                                    height={'250px'}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            {response[0].media1 !== null && response[0].media1 !== undefined
                                ?
                                <Col>
                                    <Form.Label>CV</Form.Label>
                                    <br/>
                                    <object
                                        data={SecureRequest(response[0].media1.cdn_url)}
                                        width={'100%'}
                                        height={'400px'}
                                        aria-label={'pdf'}
                                        className={'mb-3'}
                                    />
                                </Col>
                                :
                                <>
                                </>

                            }
                            {response[0].media2 !== null && response[0].media2 !== undefined
                                ?
                                <Col>
                                    <Form.Label>letter of motivation</Form.Label>
                                    <br/>
                                    <object
                                        data={SecureRequest(response[0].media2.cdn_url)}
                                        width={'100%'}
                                        height={'400px'}
                                        aria-label={'pdf'}
                                        className={'mb-3'}
                                    />
                                </Col>
                                :
                                <>
                                </>

                            }
                        </Row>
                        <Row>
                            <Col>
                                <br/>
                                <Form.Group className="mb-3" controlId="mending">
                                    <Form.Label>Mending Message ICYMARE </Form.Label>
                                    <Form.Control as="textarea" name={'Response'} id={'Response'} readOnly={true}  rows={5} defaultValue={response[0].icyResponse}/>
                                </Form.Group>
                                <br/>
                            </Col>
                        </Row>


                        <Row>

                            <Col className={'text-center'}>{back}</Col>
                            <Col className={'text-center'} />
                            <Col className={'text-center'}>
                                {
                                    (response[0].state === 'Icymare: Rework by user' || response[0].state === 'Host: Revision by user' || response[0].state === undefined || response[0].state === null
                                        ? <Button variant="success" type="submit">resend</Button>
                                        : <></>)                                }

                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <br/> <br/>
                            </Col>
                        </Row>
                    </Form></Col>
                </Row>

            </Row>
        </>)
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }
}

export default AbstractDetailUser;
