import React from "react";
import { styles } from "./css/Sidebar.css";
import { SidebarModel, initialState } from "../Model/SidebarModel";
import { Image } from "@react-pdf/renderer";
import { PDFUniTextView } from "./Elements";

/**
 * @author Gregor Schalm
 * @function SidebarView - Functional React component for rendering the sidebar section of an invoice PDF document.
 * @param {SidebarModel} props - Component properties containing invoice data for the sidebar.
 * @returns {JSX.Element} - React JSX element representing the invoice PDF sidebar.
 */

export const SidebarView = (props: SidebarModel = initialState) => {
    return (
        <>
            <Image debug={false} src={"/pdf-images/logo.png.jpg"} style={styles.logo} />
            <PDFUniTextView text={"ICYMARE"} classView={styles.highlightText} />
            <PDFUniTextView text={"AG Naturwissenschaftlicher"} classView={styles.standardText} />
            <PDFUniTextView text={"Verein zu Bremen von 1864"} classView={styles.standardText} />
            <PDFUniTextView text={"c/o Übersee-Museum Bremen 28915 Bremen"} classView={styles.standardText} />
            <PDFUniTextView text={"Tel (+49) 421 16038 153"} classView={styles.standardText} styleText={{ marginTop: "4mm" }} />
            <PDFUniTextView text={"E-Mail hello@icymare.com"} classView={styles.standardText} />
            <PDFUniTextView text={"Date"} classView={styles.highlightBluetextBold} styleText={{ marginTop: "19mm" }} />
            <PDFUniTextView text={"Bremen, " + props.data.invoiceDate} classView={styles.highlightBluetext} />
            <PDFUniTextView text={"Invoice number"} classView={styles.highlightBluetextBold} styleText={{ marginTop: "6mm" }} />
            <PDFUniTextView text={props.data.invoiceNumber} classView={styles.highlightBluetext} />
        </>
    );
};
