import {HasUserInterface, InstitutionalAddress, NameBadge, PersonalDetails} from "../../API/reponse/HasUser.interface";


const personal_details:PersonalDetails = {
    id: 0,
    rid: 0,
    title: '',
    middle_name: '',
    street: '',
    streetNumber: '',
    zip: '',
    first_name: '',
    last_name: '',
    cityPersonal: '',
    countryAdressPersonal: ''
}


const institutional_address:InstitutionalAddress = {
    id: 0,
    rid: 0,
    nameAff: '',
    streetAff: '',
    numberAff: '',
    zipAff: '',
    cityInstitut: '',
    countryInstitut: '',
    vatInstitut: '',
    InvoicePersonal: '',
}


const name_badge:NameBadge = {
    id: 0,
    rid: 0,
    nameBadgeName: '',
    nameBadgeAff: '',
    pronoun_id: 0,
    nameBadgePronounOther: '',
    researchInt: '',
    institutional_address: '',
}

/**
 * Initial state object representing a user retrieved from the API.
 *
 * @type {HasUserInterface}
 */
const HasUserIntialState:HasUserInterface  = {
    id: 0,
    uid: 0,
    confParticipantType_id: '',
    confSocialEvents_id: '',
    acceptDataStorage: '',
    acceptPhoto: '',
    year: 0,
    intent: '',
    personal_details: personal_details,
    institutional_address: institutional_address,
    name_badge: name_badge,
    icebreaker: '',
    postersession: '',
    sciencespeedmeeting: '',
    sciencecommunicationevening: '',
    postconferenceparty: '',
    paymentState: '',
    invocieId: ''

}

/**
 * Creates an initial state object for a user based on an API response.
 *
 * This function populates the `HasUserIntialState` object with data from the
 * first element of the `response` array, assuming the response contains an
 * array of user objects.
 *
 * @returns {HasUserInterface} The populated initial state object for a user.
 *
 * @throws {TypeError}  If the response is not an array or the first element is not an object.
 * @param repsonse
 */
export const CreateUserIS = (response:any,Setter?:any,SetValue?:any,Autofill?:any): HasUserInterface => {

    if(response[0] !== undefined && response[0].id !== undefined) {

        const {
            id,
            uid,
            confParticipantType_id,
            confSocialEvents_id,
            acceptDataStorage,
            acceptPhoto,
            year,
            intent,
            institutional_address,
            personal_details,
            name_badge,
            icebreaker,
            sciencecommunicationevening,
            sciencespeedmeeting,
            postersession,
            postconferenceparty,
        } = response[0]; // Destructuring for better readability

        // Update HasUserIntialState properties
        if(Setter !== undefined && SetValue === 0) {
            Setter(id)
        }
        HasUserIntialState.id = id;
        HasUserIntialState.uid = uid;
        HasUserIntialState.confParticipantType_id = confParticipantType_id;
        HasUserIntialState.confSocialEvents_id = confSocialEvents_id;
        HasUserIntialState.acceptDataStorage = acceptDataStorage;
        HasUserIntialState.acceptPhoto = acceptPhoto;
        HasUserIntialState.year = year;
        HasUserIntialState.intent = intent;
        HasUserIntialState.icebreaker = icebreaker;
        HasUserIntialState.sciencecommunicationevening = sciencecommunicationevening;
        HasUserIntialState.sciencespeedmeeting = sciencespeedmeeting;
        HasUserIntialState.postersession = postersession;
        HasUserIntialState.postconferenceparty = postconferenceparty;

        // Handle nested objects conditionally
        if (institutional_address !== undefined && institutional_address !== null) {
            HasUserIntialState.institutional_address.id = institutional_address.id;
            HasUserIntialState.institutional_address.rid = institutional_address.rid;
            HasUserIntialState.institutional_address.nameAff = institutional_address.nameAff;
            HasUserIntialState.institutional_address.streetAff = institutional_address.streetAff;
            HasUserIntialState.institutional_address.numberAff = institutional_address.numberAff;
            HasUserIntialState.institutional_address.nameAff = AutofillCheck(institutional_address.nameAff,Autofill?.mainAffiliation)
            HasUserIntialState.institutional_address.zipAff = institutional_address.zipAff;
            HasUserIntialState.institutional_address.cityInstitut = institutional_address.cityInstitut;
            HasUserIntialState.institutional_address.countryInstitut = institutional_address.countryInstitut;
            HasUserIntialState.institutional_address.vatInstitut = institutional_address.vatInstitut;
            HasUserIntialState.institutional_address.InvoicePersonal = institutional_address.InvoicePersonal;
        }

        // Handle nested objects conditionally
        if (personal_details !== undefined && personal_details !== null) {
            HasUserIntialState.personal_details.id = personal_details.id;
            HasUserIntialState.personal_details.rid = personal_details.rid;
            HasUserIntialState.personal_details.title = personal_details.title;
            HasUserIntialState.personal_details.middle_name = personal_details.middle_name;
            HasUserIntialState.personal_details.street = personal_details.street;
            HasUserIntialState.personal_details.streetNumber = personal_details.streetNumber;
            HasUserIntialState.personal_details.zip = personal_details.zip;
            HasUserIntialState.personal_details.first_name = AutofillCheck(personal_details.first_name,Autofill?.user.first_name);
            HasUserIntialState.personal_details.last_name = AutofillCheck(personal_details.last_name,Autofill?.user.last_name)
            HasUserIntialState.personal_details.cityPersonal = personal_details.cityPersonal;
            HasUserIntialState.personal_details.countryAdressPersonal = personal_details.countryAdressPersonal;
        }

        // Handle nested objects conditionally
        if (name_badge !== undefined && name_badge !== null) {
            HasUserIntialState.name_badge.id = name_badge.id;
            HasUserIntialState.name_badge.rid = name_badge.rid;
            HasUserIntialState.name_badge.nameBadgeName = name_badge.nameBadgeName;
            HasUserIntialState.name_badge.nameBadgeAff = name_badge.nameBadgeAff;
            HasUserIntialState.name_badge.pronoun_id = name_badge.pronoun_id
            HasUserIntialState.name_badge.nameBadgePronounOther = name_badge.nameBadgePronounOther
            HasUserIntialState.name_badge.researchInt = name_badge.researchInt
            HasUserIntialState.name_badge.institutional_address = name_badge.institutional_address
        }

    } else if (Autofill !== undefined) {
        HasUserIntialState.personal_details.first_name = AutofillCheck(personal_details.first_name,Autofill.user.first_name);
        HasUserIntialState.personal_details.last_name = AutofillCheck(personal_details.last_name,Autofill.user.last_name)
        HasUserIntialState.institutional_address.nameAff = AutofillCheck(institutional_address.nameAff,Autofill.mainAffiliation)
    }

    return HasUserIntialState;
}

/**
 * Check Autofill use or not
 * @param value
 * @param autofill
 * @constructor
 */
export const AutofillCheck = (value:any,autofill:any) => {
    return (value === '' || value === null || value === undefined ? autofill : value)
}