import {InfoButton, IntlMessage, IntlMessageValue, UniCol, UniForm, UniRow} from "../../../../../@WUM/core/Elements"
import React from "react";
import {FormCheck} from "react-bootstrap";
import { HeadlineHelp } from "../../../../components/Elements/headlineHelp";

interface SurveyInterface {
    Store: any
}

interface Checkboxes {
    label: string;
    id: string;
}

export const Survey = (props: SurveyInterface) => {

    const surveys: Checkboxes[] = [
        {
            label: 'Social Media',
            id: 'social',
        },
        {
            label: 'Recommendation friend/colleague’',
            id: 'recommendation',
        },
        {
            label: 'Email/Newsletter',
            id: 'sciencespeedmeeting',
        },
        {
            label: 'Others',
            id: 'others',
        }
    ]

    const participation: Checkboxes[] = [
        {
            label: 'Monday',
            id: 'monday',
        },
        {
            label: 'Tuesday',
            id: 'tuesday',
        },
        {
            label: 'Wednesday',
            id: 'wednesday',
        },
        {
            label: 'Thursday',
            id: 'thursday',
        },
        {
            label: 'Friday',
            id: 'friday',
        }
    ]

    const gender: Checkboxes[] = [
        {
            label: 'Non-binary',
            id: 'nonbinary',
        },
        {
            label: 'Woman',
            id: 'woman',
        },
        {
            label: 'Prefer not to say',
            id: 'declined',
        },
        {
            label: 'Others',
            id: 'othersSex',
        }
    ]
    const age: Checkboxes[] = [
        {
            label: 'under 18',
            id: 'u18',
        },
        {
            label: '18-35',
            id: '18to35',
        },
        {
            label: 'over 35',
            id: 'o35',
        }
    ]
    const education: Checkboxes[] = [
        {
            label: 'Bachelor',
            id: 'bsc',
        },
        {
            label: 'Master',
            id: 'msc',
        },
        {
            label: 'PhD',
            id: 'phd',
        },
        {
            label: 'Others',
            id: 'othersEducation',
        }
    ]


    return (
        <>
            <HeadlineHelp Store={props.Store} headType={"h2"} headlineText={"survey.headline"}/>
            <p><IntlMessage messageId="survey.headline.explanation" Store={props.Store}/></p>
            <HeadlineHelp Store={props.Store} headType={"h4"} headlineText={"survey.learning"} />

            {surveys.map((event) => (
                <FormCheck
                    key={event.id}
                    type="checkbox"
                    id={event.id}
                    label={event.label}
                    name={event.id}
                />
            ))}
            <HeadlineHelp Store={props.Store} headType={"h4"} headlineText={"survey.participate"} />

            {participation.map((event) => (
                <FormCheck
                    key={event.id}
                    type="checkbox"
                    id={event.id}
                    label={event.label}
                    name={event.id}
                />
            ))}
            <HeadlineHelp Store={props.Store} headType={"h4"} headlineText={"survey.gender"} />

            {gender.map((event) => (
                <FormCheck
                    key={event.id}
                    type="checkbox"
                    id={event.id}
                    label={event.label}
                    name={event.id}
                />
            ))}
            <UniForm.FormControl type="text" name={'otherSexText'} placeholder="Other" readOnly={true} class={"mb-md-3"}/>

            <HeadlineHelp Store={props.Store} headType={"h4"} headlineText={"survey.degree"} />
            {education.map((event) => (
                <FormCheck
                    key={event.id}
                    type="checkbox"
                    id={event.id}
                    label={event.label}
                    name={event.id}
                />
            ))}
            <UniForm.FormControl type="text" name={'otherDegreeText'} placeholder="Other" readOnly={true} class={"mb-md-3"}/>

            <HeadlineHelp Store={props.Store} headType={"h4"} headlineText={"survey.age"} />
            {age.map((event) => (
                <FormCheck
                    key={event.id}
                    type="checkbox"
                    id={event.id}
                    label={event.label}
                    name={event.id}
                />
            ))}
            <UniForm.InputGroup class={"mb-md-0 mb-3 mt-md-3"}>
                <UniForm.InputGroupText
                    text={IntlMessageValue('de', 'icy.country.origin', props.Store)}
                />
                <UniForm.Select freeValue={true} freeValueLabel={'Please choose'}
                                items={
                                    // customerHolder
                                    [{value: '1', text: 'Germany'}, {value: '2', text: 'USA participant'}]
                                }
                                required={false}
                />
            </UniForm.InputGroup>
            <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                <UniForm.InputGroupText
                    class={"mb-md-3"}
                    text={IntlMessageValue('de', 'icy.country.current', props.Store)}
                />
                <UniForm.Select freeValue={true} freeValueLabel={'Please choose'}
                                items={
                                    // customerHolder
                                    [{value: '1', text: 'Germany'}, {value: '2', text: 'USA participant'}]
                                }
                                required={false}
                />
            </UniForm.InputGroup>


        </>
    )
}
