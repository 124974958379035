import {StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    footerHeadline: {
        fontFamily: "Days One",
        fontSize: "7pt",
    },
    text: {
        fontFamily: "Raleway",
        fontSize: "7pt",
    },
    link: {
        fontFamily: "Days One",
        fontSize: "10pt",
    }
});
