/**
 * @author WUM Solution UG <Gregor Schalm>
 */
export interface SendPaymentDataModel {
    /**
     * Registration status, e.g. cancelled or success as string
     */
    redirect_status: string;
    /**
     * Payment intent client secret as string
     */
    payment_intent_client_secret: string;
    /**
     * the registrationID as used in e.g. icymare to send data to the correct data
     */
    RegistrationId?: string,
    /**
     * the event data
     */
    e?: any
    /**
     * the callback function, which should be run after finish
     */
    callbackFunc?: string,
    /**
     * the callback controller, which should be run after finish, have to contain the callbackFunc
     */
    callback?: string,


}

/**
 * Defines the initial state for the `PaymentPageModel`.
 * - `value` is set to an empty string.
 * - `NonStripePath` is set to false.
 */
export const initialState: SendPaymentDataModel = {
    redirect_status: 'cancelled',
    payment_intent_client_secret: '',
    RegistrationId: ''
};
