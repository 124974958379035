import { InfoButton, IntlMessage, IntlMessageValue, UniCol, UniRow } from "../../../@WUM/core/Elements";
import React, { CSSProperties } from "react";

/**
 * Interface defining the props accepted by the `HeadlineHelp` component.
 */
interface InstitutionalInfoInterface {
    /**
     * The data store to be used for internationalization.
     */
    Store: any;

    /**
     * The type of heading to be used (h1-h6).
     */
    headType: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

    /**
     * The text content for the main headline (for `IntlMessage`).
     */
    headlineText: string;

    /**
     * The text content for the descriptive text displayed with an information button (for `IntlMessage`).
     */
    descriptionText?: string;

    /**
     * Optional styling object for the component.
     */
    styling?: StylingInterface;

    /**
     * Optional icon to display alongside the information button.
     */
    icon?: string;
}

interface StylingInterface {
    /**
     * Styling options for the headline element.
     */
    headline: headlineInterface;

    /**
     * Styling options for the row element.
     */
    row: rowInterface;

    /**
     * Styling options for the column containing the headline.
     */
    colHeadline: colHeadlineInterface;

    /**
     * Styling options for the column containing the information button.
     */
    colInfoButton: colInfoButtonInterface;
}

interface headlineInterface {
    /**
     * Set the ID attribute on the headline element.
     */
    id?: string;

    /**
     * Set inline styles for the headline element.
     */
    style?: CSSProperties | undefined;

    /**
     * Set custom CSS classes for the headline element.
     */
    className?: string;
}

interface rowInterface {
    /**
     * Set the ID attribute on the row element.
     */
    id?: string;

    /**
     * Set inline styles for the row element.
     */
    style?: CSSProperties | undefined;

    /**
     * Set custom CSS classes for the row element.
     */
    className?: string;
}

interface colHeadlineInterface {
    /**
     * Set the ID attribute on the column containing the headline.
     */
    id?: string;

    /**
     * Set inline styles for the column containing the headline.
     */
    style?: CSSProperties | undefined;

    /**
     * Set custom CSS classes for the column containing the headline.
     */
    className?: string;
}

interface colInfoButtonInterface {
    /**
     * Set the ID attribute on the column containing the information button.
     */
    id?: string;

    /**
     * Set inline styles for the column containing the information button.
     */
    style?: CSSProperties | undefined;

    /**
     * Set custom CSS classes for the column containing the information button.
     */
    className?: string;
}

/**
 * HeadlineHelp component that renders a heading with an optional information button.
 *
 * @param {InstitutionalInfoInterface} props - The props passed to the component.
 * @returns {JSX.Element} - The rendered JSX element.
 *
 * @author Gregor Schalm
 */
export const HeadlineHelp = (props: InstitutionalInfoInterface) => {
    /**
     * Casts the `headType` prop to the appropriate React element type (h1-h6).
     */
    const HeadingComponent = props.headType as React.ElementType;

    return (
        <HeadingComponent
            id={props.styling?.headline.id}
            style={props.styling?.headline.style}
            className={props.styling?.headline.className}
        >
            <UniRow
                id={props.styling?.row.id}
                style={props.styling?.row.style}
                class={props.styling?.row.className}
            >
                <UniCol sm="auto" id={props.styling?.colInfoButton.id} style={props.styling?.colInfoButton.style} class={props.styling?.colInfoButton.className}>
                    <IntlMessage messageId={props.headlineText} Store={props.Store} />
                </UniCol>
                {props.descriptionText && (
                    <UniCol
                        id={props.styling?.colHeadline.id}
                        style={props.styling?.colHeadline.style}
                        class={props.styling?.colHeadline.className}
                    >
                        <InfoButton.Wrapper>
                            <InfoButton
                                /**
                                 * Retrieves the description text based on language and data store.
                                 */
                                text={IntlMessageValue('de', props.descriptionText, props.Store)}
                                icon={props.icon !== undefined ? props.icon : 'info'}
                            />
                        </InfoButton.Wrapper>
                    </UniCol>
                )}
            </UniRow>
        </HeadingComponent>
    );
};
