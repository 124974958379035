interface LocationBarViewProps {
    locationHeadline?: string,
    locationLink?: string,
    locationAddress?: string
    referral?: 'detail' | 'overview';
}

export const LocationBarView = (props: LocationBarViewProps) => {
    return (
        <>
            {props.locationHeadline && (
                <div className="location-bar ">
                    <a href={props.locationLink} target="_blank" rel="noopener noreferrer" className={"href-no-highlight"}>
                        <div>{props.locationHeadline}</div>
                        {props.referral === 'detail' && (
                            <div>
                                    {props.locationAddress}
                            </div>
                            )}
                    </a>
                </div>
            )}        </>

    )
}
