import { Button } from "react-bootstrap";
import {HeaderView, LocationBarView, TimeFrameView} from "../Views/Elements";
import {Link, useLocation, useParams} from "react-router-dom";
import {MIcon} from "../../../../@WUM/core/Elements";
import React from "react";
import {SubstituteHtmlCharactersController} from "../Controller/SubstituteHtmlCharacters.Controller"


interface SessionDetailViewProps {
    value?: any
}

export const DetailEvent = (props: SessionDetailViewProps) => {
    const {id} = useParams<{ id: string }>();

    const location = useLocation();

    const data = location.state.value


    return (
            <>
                <div className={'freeWrapper bg-white'}>
                    <div className="event-calendar">
                        <HeaderView header={location.state.header}/>
                        <Link to="/unbound/event-calendar">
                            <Button variant={"dark"} className={"w-100 event-button-back"}> <MIcon
                                style={{verticalAlign: "top"}}>arrow_back</MIcon> Back to Schedule</Button>
                        </Link>
                        <div className={"daily-header"}>
                            <h3 className={"session-headline"}>{data.name}</h3>
                        </div>
                        <LocationBarView locationLink={data.locationLink} locationAddress={data.locationAddress} locationHeadline={data.locationHeadline} referral={"detail"}/>
                        <TimeFrameView start={data.start} end={data.end} />
                        <div className={"abstract-text"}>{SubstituteHtmlCharactersController(data.description)}</div>
                    </div>
                </div>
            </>
    )
}

