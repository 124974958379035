import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";


const Affiliations = (props:{affiliations:any,readonly?:any,newlabel?:any}) => {

    let affiliations = props.affiliations;

    if(affiliations === undefined) {
        return (<><InputGroup className="mb-3 inputGroupExtra form-control">
            <Form.Control
                readOnly={(props.readonly !== undefined && props.readonly !== null ? props.readonly : true)}
                name={'Affiliations0'}
                placeholder="No Affiliations find"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                className={'inputNoBorder'}
            />

        </InputGroup></>)
    }

    let affiliationsContainer = [];

    for(let a=0;a<affiliations.length;a++){
        affiliationsContainer.push(
        <>
            <InputGroup className="mb-3 inputGroupExtra form-control">
                <InputGroup.Text>
                    {(props.newlabel !== undefined && props.newlabel !== null ? props.newlabel : 'Author' )}:
                </InputGroup.Text>
                    <Form.Control className={"col-1"} type="text" name={'affiliationText'+a}  defaultValue={affiliations[a].authorAffId} readOnly={(props.readonly !== undefined && props.readonly !== null ? props.readonly : true)}/>
                <InputGroup.Text>
                    Affiliations:
                </InputGroup.Text>
                <Form.Control
                    readOnly={(props.readonly !== undefined && props.readonly !== null ? props.readonly : true)}
                    name={'Affiliations'+a}
                    placeholder="Affiliations"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                    defaultValue={affiliations[a].affiliationText}
                />

            </InputGroup>
        </>
    )
    }


    return (
        <Row id={'Affiliations'}>
            <Row>
                <div className={'col-12'}>
                {affiliationsContainer}
            </div>
            </Row>
        </Row>
    )

}

export default Affiliations;
