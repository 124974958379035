export function FormatDatesLongEnglishController(
    startDate: string | number | Date,
    endDate: string | number | Date
): string {
    const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };

    const start = new Date(startDate);
    const end = new Date(endDate);

    const formatDayWithSuffix = (date: Date) => {
        const day = date.getDate();
        let suffix = 'th';

        if (day % 10 === 1 && day !== 11) {
            suffix = 'st';
        } else if (day % 10 === 2 && day !== 12) {
            suffix = 'nd';
        } else if (day % 10 === 3 && day !== 13) {
            suffix = 'rd';
        }

        return day + suffix;
    };

    const formattedStart = start.toLocaleDateString('en-US', { month: 'long' }) + ' ' + formatDayWithSuffix(start);
    const formattedEnd = formatDayWithSuffix(end);

    return formattedStart + ' - ' + formattedEnd;
}

export function formatDateLongEnglishControllerSingle(date: string | number | Date): string {
    const dateObj = new Date(date);

    // Überprüfen, ob das Datum gültig ist
    if (isNaN(dateObj.getTime())) {
        throw new Error("Ungültiges Datum");
    }

    // Funktion zur Bestimmung des passenden Suffixes für den Tag
    const formatDayWithSuffix = (day: number): string => {
        if (day >= 11 && day <= 13) {
            return `${day}th`;
        }
        switch (day % 10) {
            case 1: return `${day}st`;
            case 2: return `${day}nd`;
            case 3: return `${day}rd`;
            default: return `${day}th`;
        }
    };

    // Optionen für die Formatierung des Wochentags und des Monats
    const optionsWeekday: Intl.DateTimeFormatOptions = { weekday: 'long' };
    const optionsMonth: Intl.DateTimeFormatOptions = { month: 'long' };

    const weekday = dateObj.toLocaleDateString('en-US', optionsWeekday);
    const month = dateObj.toLocaleDateString('en-US', optionsMonth);
    const day = dateObj.getDate();

    return `${weekday} ${month} ${formatDayWithSuffix(day)}`;
}
