import React, {useState
} from 'react';
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import {useNavigate,
    useParams
} from 'react-router-dom';
import { ContentSwitch } from '../../../../../@WUM/core/Elements/index';
import {useSelector} from "react-redux";
import SearchJSX from "../../../../../@WUM/core/component/const/SearchJSX";
import configuration from '../configuration/Page/overview';
import {ResponsePresentation, ResponsePresentationSingle} from "../../API/response/presentation";

const PresentationOverview = (value:any) => {

    const {page,deleteID} = useParams<{page: any,deleteID: string}>();
    let limit: any;

    const Store = useSelector((state: any) => state)
    const history = useNavigate();
    /**
     * Neuladen der Seite
     */
    const routeChange = (paths:string) => {
        history(-1);
    };

    const debug = (search: any,modalShow: boolean, modalData: any[]) => {
        return;
    }

    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    } else {
        limit = (page * 10) - 10;
    }

    const destination = 'settings/presentation';
    const namespace_search = "Session_searchValue";
    const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
    const [search, setSearch] = useState<boolean>(false)
    const response = ResponsePresentation(limit,searchValue);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState([]);
    const Configuration = configuration('',response,page,Store);
    debug(search,modalShow,modalData)
    const [mode, setMode] = useState('cart');


    const SearchConfig = [{
        namespace:namespace_search,
        destination: destination,
        input:[
            {id:'search_name',translation: 'Search for title...',param: 'title',method: 'like'}

        ],
        button: ['Search','Delete Search','new Session']
    }]


    const CardsConfiguration = {
        'data': {
            'ressource': response,
            'img': '',
            'status': 'state',
            'statusindi': true,
            'statusMapping': [
                {name: 'resubmitted',color:'dark'},
                {name: 'submitted',color:'danger'},
                {name: 'Icymare: Declined ',color:'danger'},
                {name: 'Icymare: Rework by user',color:'warning'},
                {name: 'Icymare: Passed to Host',color:'warning'},
                {name: 'Host: Not suitable for session',color:'danger'},
                {name: 'Host: Revision by user',color:'warning'},
                {name: 'Host: Final acceptance',color:'success'}
            ],
            'link': 'translation.de.slug',
            'prelink': '/magazin/[param]/',
            'prelinksearch': 'translation.de.slug',
            'title': 'presentationTitle',
            'descriptionShow': true,
            'description': 'confSession.title',
            'router': 'id',
            'showauthor': true,
            'author_firstname': 'user.first_name',
            'author_lastname': 'user.last_name',
            'preroute': '/settings/presentation/Detail/',
            'edit': 'Show Presentation',
            'editOnly': true,
            'delete': false,
            'deleteLink': '/Abstract/Delete/'+page+'/[ID]',
            'Dontvisited': true,
        }
    }

    let NewPage = (page === undefined ? 1:page);
    if (response.length > 0) {
        return (
            <> <SearchJSX noNewButton={true} setSearchValue={setSearchValue} Store={Store} SearchConfig={SearchConfig} />
                { <ContentSwitch pagenew={'new'} destinationnew={'settings/event'} tonew={'new'} text={'Show Session'}
                                 response={response} CardsConfiguration={CardsConfiguration} mode={mode}
                                 Configuration={Configuration} setMode={setMode}  destination={destination} page={NewPage}
                                 to={'overview'} setSearchValues={setSearchValue} setSearchs={setSearch} search={false}
                                 modalShow={modalShow} setModalShow={setModalShow} modalData={modalData} setModalData={setModalData} SearchParam={'?translation.title'}
                                 searchfield={false} allTaxon={[]} _handleKeyFilters={[]} _handleEnter={[]} filter={true} stop={false} Store={Store}/>}
            </>

        )
    } else {
        localStorage.setItem('searchEnterInput','');
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }

};



export default PresentationOverview;



