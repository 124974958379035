/**
 * @author WUM Solution UG <Gregor Schalm>
 */
export interface CommentInvoiceModel {
    /**
     * @property {boolean} NonStripePath - Optional flag indicating a non-Stripe payment path (default: false).
     */
    NonStripePath?: boolean;
    /**
     * @property {string} status - The current status of the payment process (default: '')
     */
    status: string;
}

/**
 * Defines the initial state for the `PaymentPageModel`.
 * - `status` is set to an empty string.
 * - `NonStripePath` is set to false.
 */
export const initialState: CommentInvoiceModel = {
    status: '',
    NonStripePath: false,
};
