import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";


const Keywords = (props:{keywords:any,readonly?:any}) => {

    let keywords = props.keywords;

    if(keywords === undefined) {
        return (<><InputGroup className="mb-3 inputGroupExtra form-control" >
            <Form.Control
                readOnly={(props.readonly !== undefined && props.readonly !== null ? props.readonly : true)}
                name={'Keywords0'}
                placeholder="No Keywords find"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                className={'inputNoBorder'}
            />

        </InputGroup></>)
    }

    let keywordsContainer = [];

    for(let a=0;a<keywords.length;a++){
        keywordsContainer.push(
        <>
            <InputGroup className="mb-3 inputGroupExtra form-control" >
                <InputGroup.Text>
                    {a+1}
                </InputGroup.Text>
                <Form.Control
                    readOnly={(props.readonly !== undefined && props.readonly !== null ? props.readonly : true)}
                    name={'Keywords'+a}
                    placeholder="Keywords"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                    defaultValue={keywords[a].tag}
                />

            </InputGroup>
        </>
    )
    }


    return (
        <Row id={'Keywords'}>
            <Row>
                <Col>
                {keywordsContainer}
                </Col>
            </Row>
        </Row>
    )

}

export default Keywords;
