import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
import {getToken} from "../../../../../@WUM/core/component/const/CardJSX";
import {StornoInterface} from "./storno.interface";

export const sendStorno = (props: StornoInterface) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+ getToken());

    var raw = JSON.stringify(
        {
        }
    );

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
    };

    fetch(ApiURL() +"conf/invoice/storno/" + props.id + '/', requestOptions)
        .then(response => response.json())
        .then(result => {console.log(result)})
        .catch(error => console.log(error));

}
