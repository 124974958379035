import { initialState, StripeStatusMessageModel } from "../Model/StripeStatusMessageModel";
import React from "react";

import {
    CanceledView,
    ProcessingView,
    RequiresActionView,
    RequiresCaptureView,
    RequiresConfirmationView,
    RequiresPaymentMethodView,
    SuccessView,
    UnexpectedView,
} from "./SingleStatusPage";
import BypassView from "./SingleStatusPage/BypassView";

/**
 * @author WUM Solution UG <Gregor Schalm>
 * StripeStatusMessageView component renders the appropriate single status page based on the provided Stripe status.
 *
 * It takes an optional `props` argument of type `StripeStatusMessageModel`.
 * If no props are provided, it defaults to the `initialState` imported from the StripeStatusMessageModel module.
 *
 * The component uses a switch statement to determine the status and renders the corresponding SingleStatusPage view component.
 * - SuccessView for 'succeeded' status.
 * - BypassView for 'bypass' status.
 * - CanceledView for 'canceled' status.
 * - ProcessingView for 'processing' status.
 * - RequiresActionView for 'requires_action' status.
 * - RequiresCaptureView for 'requires_capture' status.
 * - RequiresConfirmationView for 'requires_confirmation' status.
 * - RequiresPaymentMethodView for 'requires_payment_method' status.
 * - UnexpectedView for any other status.
 *
 * @param {StripeStatusMessageModel} [props] - Optional props object of type StripeStatusMessageModel. Defaults to initialState.
 * @returns {JSX.Element} - The JSX element representing the appropriate single status page.
 */

const StripeStatusMessageView = (props: StripeStatusMessageModel = initialState):JSX.Element => {
    switch (props.status) {
        case "succeeded":
            return (
                <>
                    <SuccessView Store={props.Store} configuration={props.configuration} status={props.status} />
                </>
            );
        case "bypass":
            return (
                <>
                    <BypassView Store={props.Store} configuration={props.configuration} status={props.status} />
                </>
            );
        case "canceled":
            return (
                <>
                    <CanceledView Store={props.Store} configuration={props.configuration} status={props.status} />
                </>
            );
        case "processing":
            return (
                <>
                    <ProcessingView Store={props.Store} status={props.status} configuration={props.configuration} />
                </>
            );
        case "requires_action":
            return (
                <>
                    <RequiresActionView Store={props.Store} status={props.status} configuration={props.configuration} />
                </>
            );
        case "requires_capture":
            return (
                <>
                    <RequiresCaptureView Store={props.Store} status={props.status} configuration={props.configuration} />
                </>
            );
        case "requires_confirmation":
            return (
                <>
                    <RequiresConfirmationView Store={props.Store} status={props.status} configuration={props.configuration} />
                </>
            );
        case "requires_payment_method":
            return (
                <>
                    <RequiresPaymentMethodView Store={props.Store} status={props.status} configuration={props.configuration} />
                </>
            );
        default:
            return (
                <>
                    <UnexpectedView Store={props.Store} configuration={props.configuration} status={props.status} />
                </>
            );
    }
};

export default StripeStatusMessageView;
