import IntlMessage from '../../../../../../@WUM/core/component/const/IntlMessage';
import React from "react";

/**
 *
 * @memberOf blog
 * @prop conf
 * @prop response
 * @prop page
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
function configuration(response:any,endpoint:any='') {

    return {
        tableBody: {
            data: response,
            dataID: 'uid',
            underdata: true,
            keys: [
                'social',
                'recommendation',
                'sciencespeedmeeting',
                'others',
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'nonbinary',
                'woman',
                'man',
                'declined',
                'otherSexText',
                'u18',
                '_18to35',
                'o35',
                'bsc',
                'msc',
                'phd',
                'otherDegreeText',
                'countryOriginName.valueEN',
                'currentCountryName.valueEN'
            ],
            type: ['string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string'],
        },
        tableHeader: [
            'Social Media',
            'Recommendation',
            'E-Mail',
            'Others',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'nonbinary',
            'woman',
            'man',
            'declined',
            'Other gender',
            'u18',
            '18to35',
            'o35',
            'bsc',
            'msc',
            'phd',
            'othersEducation',
            'Country origin',
            'countryCurrent'
        ],
        tableHeaderWidth: ['auto', 'auto'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: true,
            menutriggerDelete: false,
            menuItem: [
                ''
            ],
            menuroute: [''],
            menuParams: ['', 'uid', 'uid'],
            menuIcons: [''],
        },
    }
}

export default configuration;
