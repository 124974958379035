import React, { type SVGProps } from "react";

/**
 * Defines the props for the EosIconsScientistOutlined component.
 */
interface EosIconsScientistOutlinedProps extends SVGProps<SVGSVGElement> {
    /** Optional width of the SVG element in pixels. Defaults to 24. */
    width?: number;

    /** Optional height of the SVG element in pixels. Defaults to 24. */
    height?: number;

    /** Optional color **/
    color?: string
}

/**
 * Renders an SVG representing a scientist icon.
 *
 * @param {EosIconsScientistOutlinedProps} props - Component props.
 * @returns {JSX.Element} - The rendered SVG element.
 */
export const EosIconsScientistOutlined: React.FC<EosIconsScientistOutlinedProps> = (props) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={props.width}
                height={props.height}
                {...props}
            >
                <circle cx={20} cy={5} r={1} fill="#135e7e" />
                <circle cx={21.5} cy={3.5} r={0.5} fill="#135e7e" />
                <circle cx={20.5} cy={1.5} r={0.5} fill="#135e7e" />
                <path
                    fill={props.color || "#135e7e"}
                    d="M16 7v1h1l.003 10.031C17 19.712 18 21 19.5 21s2.5-1.207 2.5-3V8h1V7Zm5 8l-.565.424a1.77 1.77 0 0 1-1.05.326H19V15h-1v-2h1v-1h-1v-2h1V9h-1V8h3ZM8 10a2 2 0 1 1-2 2a2.006 2.006 0 0 1 2-2m0 10c2.7 0 5.8 1.29 6 2H2c.23-.72 3.31-2 6-2M8 8a4 4 0 1 0 4 4a4 4 0 0 0-4-4m0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4"
                />
            </svg>
        </>
    );
};
