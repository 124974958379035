import React from "react";
import {styles} from "../../View/css/Elements/InitialState.css";

/**
 * @typedef {Object} PDFUniTextModel - Interface for StandardText component props.
 * @property {string} text - Text content to be displayed.
 * @property {React.CSSProperties} [styleText] - Optional inline styles for the Text component.
 * @property {React.CSSProperties} [styleView] - Optional inline styles for the View component.
 * @property {string} [classText] - Optional class name for styling the Text component (from CSS).
 * @property {string} [classView] - Optional class name for styling the View component (from CSS).
 * @property {boolean} [debugView] - Optional state for debug mode of surrounding view.
 * @property {boolean} [debugText] - Optional state for text component.
 * @property {boolean} [wrap] - Optional state if text should be wrapped or it should be kept on one page (does not change hyphenation).
 */
export interface PDFUniTextModel {
    text: string | undefined;
    styleText?: React.CSSProperties;
    styleView?: React.CSSProperties;
    classText?: any;
    classView?: any;
    debugView?: boolean
    debugText?: boolean
    fixed?: boolean
    wrap?: boolean
}

/**
 * @constant {PDFUniTextModel} initialState - Default initial state for the PDFUniStandardText component.
 */
export const initialState: PDFUniTextModel = {
    text: '',
    styleText: { fontSize: 'inherit' },
    styleView: { fontSize: 'inherit' },
    classText: styles.empty,
    classView: styles.empty,
    debugView: false,
    debugText: false,
    fixed: false,
    wrap: true
};

