import {IntlMessageValue, UniCol, UniForm, UniRow} from "../../../../../@WUM/core/Elements"
import React, {ChangeEvent, useState} from "react";
import BadgePDFController from "../../../../components/Elements/pdf/Controller/BadgePDFController";
import {NameBadgePreview} from "./NameBadgePreview";
import {HasUserInterface} from "../../API/reponse/HasUser.interface";
import {OtherSet} from "../../../../../@WUM/core/Function/OtherSet/OtherSet";
import { PDFViewer } from "@react-pdf/renderer";

interface NameBadgeInterface {
    Store: any,
    Pronouns: any
    UserDataOld: HasUserInterface | undefined
    PDFRender?: boolean
}

export const NameBadge = (props: NameBadgeInterface) => {

    const [name, setName] = useState(props.UserDataOld?.name_badge.nameBadgeName);
    const [pronoun, setPronoun] = useState(props.UserDataOld?.name_badge.pronoun_id);
    const [affiliation, setAffiliation] = useState(props.UserDataOld?.name_badge.nameBadgeAff);
    const [interests, setInterests] = useState(props.UserDataOld?.name_badge.researchInt);

    const handleNameChange = (event: ChangeEvent<Element>) => {
        setName((event.target as HTMLInputElement).value);
    };

    const handlePronounChange = (event: ChangeEvent<HTMLSelectElement>) => {
        OtherSet(event,11,'nameBadgePronounOther');
        const selectedValue = Number(event.target.value);
        //console.log("Selected value:", selectedValue);
        const selectedPronoun = props.Pronouns.find(
            (pronoun: { value: number; }) => pronoun.value === selectedValue
        );
        //console.log("Selected pronoun object:", selectedPronoun);
        if (selectedPronoun) {
            setPronoun(selectedPronoun.text);
            //console.log("Updated pronoun text:", selectedPronoun.text);
        }
    };

    const getPronounText = (value: number): string => {
        const foundPronoun = props.Pronouns.find((pronoun: { value: number; }) => pronoun.value === value);

        if (foundPronoun) {
            console.log(foundPronoun.text)
            if (foundPronoun.text === "others") {
                return props.UserDataOld?.name_badge.nameBadgePronounOther
            } else {
                return foundPronoun.text;
            }
        } else if (value === 11) { // Assuming "others" has the value 11
            return props.UserDataOld?.name_badge.nameBadgePronounOther || " ";
        } else {
            return " "; // Oder einen anderen Standardwert, falls nichts gefunden wird
        }
    };

    const handlePronounInputChange = (event: ChangeEvent<Element>) => {
        setPronoun((event.target as HTMLInputElement).value);
    };

    const handleAffiliationChange = (event: ChangeEvent<Element>) => {
        setAffiliation((event.target as HTMLInputElement).value);
    };

    const handleInterestChange = (event: ChangeEvent<Element>) => {
        setInterests((event.target as HTMLInputElement).value);
    };

    type PDFData = {
        name: string
    }

    const generatePDF = ({ name }: PDFData) => {
        return (
            <BadgePDFController
                name={name}
                pronoun={getPronounText(pronoun)}
                affiliation={affiliation}
                interests={interests}

            />
        );
    };

    const generatePreview = ({ name }: PDFData) => {
        return (
            <NameBadgePreview
                name={name}
                pronoun={pronoun}
                affiliation={affiliation}
                interests={interests}

            />
        );
    };

    return (
        <>
            <UniForm.FormControl type="hidden" name={'name_badge_id'} defaultValue={(props.UserDataOld?.name_badge.id !== 0 && props.UserDataOld !== undefined ? props.UserDataOld.name_badge.id : '0')}/>
            <UniRow>
                <UniCol lg={6} sm={12}>
                    <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                        <UniForm.InputGroupText class={"mb-md-3"}
                                                text={IntlMessageValue('de', 'icy.name.namebadge', props.Store)}
                                                InfoButton={{
                                                    display: true,
                                                    text: IntlMessageValue('de', 'icy.name.namebadge.explanation', props.Store)
                                                }}/>
                        <UniForm.FormControl type="text" name={'nameBadgeName'} placeholder="Enter your name" onChange={handleNameChange} id={'nameBadgeName'} defaultValue={props.UserDataOld?.name_badge.nameBadgeName}/>
                    </UniForm.InputGroup>
                    <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                        <UniForm.InputGroupText class={"mb-md-3"}
                                                text={IntlMessageValue('de', 'icy.main.affiliation.namebadge', props.Store)}
                                                InfoButton={{
                                                    display: true,
                                                    text: IntlMessageValue('de', 'icy.main.affiliation.explanation', props.Store)
                                                }}/>
                        <UniForm.FormControl type="text" name={'nameBadgeAff'} placeholder="Enter your Institution"
                                             maxLength={50} onChange={handleAffiliationChange} id={'nameBadgeAff'} defaultValue={props.UserDataOld?.name_badge.nameBadgeAff}/>
                    </UniForm.InputGroup>
                    <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                        <UniForm.InputGroupText class={"mb-md-3"}
                                                text={IntlMessageValue('de', 'icy.pronouns.namebadge', props.Store)}
                                                InfoButton={{
                                                    display: true,
                                                    text: IntlMessageValue('de', 'icy.pronouns.namebadge.explanation', props.Store)
                                                }}/>
                        <UniForm.Select freeValue={true} freeValueLabel={'Please choose'} name={'nameBadgePronoun'} onChange={(e)=>handlePronounChange(e)}
                                        items={
                                            // holder containing all pronouns
                                            props.Pronouns
                                        }
                                        id={'nameBadgePronoun'}
                                        defaultValue={props.UserDataOld?.name_badge.pronoun_id}
                        />
                        <UniForm.FormControl type="text" readOnly={true} name={'nameBadgePronounOther'} id={'nameBadgePronounOther'} placeholder="Other"
                                             maxLength={50} onChange={(e)=>handlePronounInputChange(e)}
                                             defaultValue={props.UserDataOld?.name_badge.nameBadgePronounOther}
                        />

                    </UniForm.InputGroup>
                    <UniForm.InputGroup class={"mb-md-0 mb-3"}>
                        <UniForm.InputGroupText class={"mb-md-3"}
                                                text={IntlMessageValue('de', 'icy.research.interest', props.Store)}
                                                InfoButton={{
                                                    display: true,
                                                    text: IntlMessageValue('de', 'icy.research.interest.explanation', props.Store)
                                                }}/>
                        <UniForm.FormControl type="text" name={'researchInt'} placeholder="Research interest" maxLength={50} onChange={handleInterestChange} id={'researchInt'} defaultValue={props.UserDataOld?.name_badge.researchInt}/>
                    </UniForm.InputGroup>
                </UniCol>
                <UniCol>
                    {!props.PDFRender && (
                        <div className="d-flex justify-content-center">
                            <div className={"d-block, position-relative" } style={{height: "6cm"}}>
                                {generatePreview({name})}

                            </div>


                            <div style={{
                            width: '100%',
                            height: '50vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        </div>
                        </div>
                    )}
                    {props.PDFRender && ( <PDFViewer
                            width={"100%"}
                            height={"100%"}
                            className={"col-12"}
                        >
                            {generatePDF({ name })}

                        </PDFViewer>
                    )}
                </UniCol>
            </UniRow>


        </>
    )
}

