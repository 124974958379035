import React from 'react';
import {useSelector} from "react-redux";
import EventCalendarOverview from "./modules/Pages/overview";
import {DetailSession} from "./modules/Pages/DetailSession";
import {PresentationDetails} from "./modules/Pages/PresentationDetails";
import {DetailEvent} from "./modules/Pages/DetailEvent";

export const EventCalendarPagesConfig = (value: any) => {
    const Store = useSelector((state: any) => state)
    let Role = Store.user.roles

    switch(Role){
        default:
            return [
                {
                    path: '/unbound/event-calendar',
                    elements: <EventCalendarOverview value={value}/>,
                    roles: ['no-check']
                },
                {
                    path: '/unbound/event-calendar/session-details/:id',
                    elements: <DetailSession value={value}/>,
                    roles: ['no-check']
                },
                {
                    path: '/unbound/event-calendar/presentation-details/:id',
                    elements: <PresentationDetails value={value}/>,
                    roles: ['no-check']
                },
                {
                    path: '/unbound/event-calendar/event-details/:id',
                    elements: <DetailEvent value={value}/>,
                    roles: ['no-check']
                },
            ]
    }
}

export const EventCalendarMenuConfig = (value: any) => {
    return [
        {
            name: 'Calendar',
            route: '/event-calendar/',
            icon: 'dialer_sip',
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        }
    ]
}
