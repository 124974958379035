import {abstractApiDetail} from "../reponse/abstractAPI.interface";
import {useEffect, useState} from 'react';
import {abstract} from "../reponse/abstract";
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";
import {HasUserInterface} from "../reponse/HasUser.interface";

export const ResponseLevel = () => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getLevel()
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [])


    return isError ? (posts ) : (posts);

}
export const ResponseHasUser = (id:any,year:any) => {
    const [posts, setPosts] = useState<HasUserInterface[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getHasUser(id,year)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [])


    return isError ? (posts ) : (posts);

}


export const ResponsePrasentationType = () => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getPrasentationType()
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [])


    return isError ? (posts ) : (posts);

}

export const ResponseSession = () => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getSession()
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [])


    return isError ? (posts ) : (posts);

}



export const ResponseAbstract = (limit:any,searchValue:any) => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getPosts(limit,searchValue)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [limit,searchValue])


    return isError ? (posts ) : (posts);

}

export const ResponseAbstractByUser = (limit:any,searchValue:any,userid:any) => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getPostsUser(limit,searchValue,userid)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [limit,searchValue,userid])


    return isError ? (posts ) : (posts);

}

export const ResponseAbstractByHost = (limit:any,searchValue:any,userid:any) => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getPostsHost(limit,searchValue,userid)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [limit,searchValue,userid])


    return isError ? (posts ) : (posts);

}

export const ResponseAbstractSingle = (id:any) => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getAPost(id)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [id])


    return isError ? (posts ) : (posts);

}
// imports country list
export const ResponseACountry = () => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await abstract
                .getACountry() /* <-- Point falls neuer geschaffen tauschen sonst nix verändern */
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true)
                })
        })()
    }, [])

    return isError ? isError : posts
}
// imports pronouns
export const ResponsePronouns = () => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await abstract
                .getPronouns() /* <-- Point falls neuer geschaffen tauschen sonst nix verändern */
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true)
                })
        })()
    }, [])

    return isError ? isError : posts
}

// imports pronouns
export const ResponseParticipantType = () => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await abstract
                .getParticipantType() /* <-- Point falls neuer geschaffen tauschen sonst nix verändern */
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true)
                })
        })()
    }, [])

    return isError ? isError : posts
}

export const ResponseSurvey = (year:any) => {
    const [posts, setPosts] = useState<abstractApiDetail[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await abstract.getSurveys(year)
                .then((data) => {
                    checkLogin(data)
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [year])


    return isError ? (posts ) : (posts);

}
