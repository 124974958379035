import {StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    // Tabellenzeile
    tableRowHeader: {
        margin: 1,
        flexDirection: "row",
        borderStyle: "solid",
        borderBottomWidth: 0.5,
        borderTopWidth: 0.5,
        borderColor: "#31718f"
    },
    tableRow: {
        margin: 1,
        flexDirection: "row",
        borderStyle: "solid",
        borderBottomWidth: 0.5,
        borderColor: "#31718f"
    },
    // Tabellenspalte - definiert die Standardbreite von 12,5%. Muss jede Zelle im View-Container haben
    tableCol: {
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    // Definiert Standardzellen, Abstände und Textgrößen
    tableCell: {
        fontFamily: "Raleway",
        fontSize: "11pt",
        fontWeight: "medium"
    },
    tableCellEnd: {
        fontFamily: "Raleway",
        fontSize: "11pt",
        fontWeight: "medium",
        textAlign: "right"
    },
    tableHeader: {
        fontFamily: "Raleway",
        fontSize: "11pt",
        fontWeight: "medium",
        fontStyle: "italic"
    },
    legalText: {
        fontSize: "10pt",
        marginLeft: "30mm",
        textAlign: "right",
        fontStyle: "italic",
        fontFamily: "Raleway",
        fontWeight: "medium"
    }
});
