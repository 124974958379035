import InvoicePDFView from "../View/InvoicePDFView";
import {InvoicePDFModelController} from "../Model/InvoicePDFModelController";
import {INTLNumberFormat} from "../../../Functions/IntlNumberFormat";

const InvoicePDFController = (props: InvoicePDFModelController) => {

    const role = props.response.reason

    // formats price and discount and sum
    const price = INTLNumberFormat.format(props.response.price / 100)
    const discount = INTLNumberFormat.format(props.response.discount / 100 * -1)
    const sum = INTLNumberFormat.format((props.response.price - props.response.discount)/100)


    const rows:string[][] = [
        ['1', 'Scientific program', '1', price],
        // add more rows if necessary
    ]

    //console.log(props.response)


    const totalRow = ['Total invoice sum', sum]


    // adds potential discount
    if (role === 'team member') {
        rows.push(['2', 'Discount Team member', '', discount]);
    } else if (role === 'host') {
        rows.push(['2', 'Discount Host', '', discount]);
    } else if (role === 'sponsor') {
        rows.push(['2', 'Discount Sponsor', '', discount]);
        console.log("here")
    }

    // defines message below text
    let message: string;
    if (props.response.received === 1) {
        message = "Your payment was successfully registered";
    } else if (props.response.received === 0) {
        message = "Please transfer the invoice total to the account given below."
    } else if (props.response.received === 3) {
        message = "This invoice has been cancelled, no action is needed from your side."
    } else if (props.response.received === 2) {
        message = "Your payment was successfully registered."
    } else {
        message = "Unknown status"
    }

    console.log(role)





    return (
        <InvoicePDFView response={props.response} rows={rows} role={props.role} totalRow={totalRow} message={message}/>
    )
};

export default InvoicePDFController
