interface formatFloattoIntProps {
    value: string
}

export const INTLNumberFormat =
    new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
    });

export const ValueFormat =
    new Intl.NumberFormat('de-DE', {
        style: 'decimal', // Change the style to 'decimal' to remove currency formatting
        minimumFractionDigits: 2, // Specify the minimum number of decimal places
        maximumFractionDigits: 2, // Specify the maximum number of decimal places
    });

export const formatFloattoInt = (value: string) => {
    const float = parseInt(value.replace(',', '').replace('.', ''), 10);
    if (!isNaN(float)) {
        return float/100;
    } else {
        console.error("Error: Invalid invoice total format. Please provide a number.");
        return 0; // Or return any default value you prefer for invalid input
    }
};

export const formatFloattoCurrency = (value: string) => {
    const float = parseInt(value.replace(',', '').replace('.', ''), 10);
    if (!isNaN(float)) {
        const formattedValue = INTLNumberFormat.format(float/100)
        return formattedValue;
    } else {
        console.error("Error: Invalid invoice total format. Please provide a number.");
        return 0; // Or return any default value you prefer for invalid input
    }
};