import * as React from 'react';
import {TabpanelView} from "../View/Tabpanel.View";
import {RowVariantView} from "../View/RowVariant.View";
import {MakeMuiTabViewModel} from "../Model/MakeMuiTabView.Model";


export const MakeMuiTabView = (props: MakeMuiTabViewModel) => {

    const maxWidth = window.innerWidth;

    if (props.tabConfigurations.mobileBreakpoint !== undefined && maxWidth > props.tabConfigurations.mobileBreakpoint) {
        return (
            <>
                <RowVariantView tabConfigurations={props.tabConfigurations}/>
            </>
        )
    } else {
        return (
            <>
                <TabpanelView tabConfigurations={props.tabConfigurations} />
            </>
        )
    }
}