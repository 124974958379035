export function SubstituteHtmlCharactersController(string: string | undefined): string |undefined {
    // Erstelle ein RegExp, um effizient mehrere Entitäten zu ersetzen
    const entityRegex = /&apos;|&amp;/g;
    // Ersetze die Entitäten durch ihre entsprechenden Zeichen
    if (string === undefined || string === null) {
    } else {
        return string.replace(entityRegex, match => {
            switch (match) {
                case '&apos;':
                    return "'";
                case '&amp;':
                    return "&";
                default:
                    return match;
            }
        });

    }
}
