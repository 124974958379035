import {Fetchdownlaod} from "./certifcation";
import {PDFViewer} from "@react-pdf/renderer";
import InvoicePDFController from "../../../components/Elements/pdf/Controller/InvoiceController";
import {InvoiceDetail} from "../../../components/Elements/pdf/Model/sample";
import {ResponseInvoiceDetail} from "../API/Response/profil";
import React, {useState} from "react";
import {SpinnerJSX} from "../../../../@WUM/core/component/const/Spinner";
import {useSelector} from "react-redux";
import QrController from "../../../../@WUM/core/QrCode/Controller/QrController";
import {UniCol, UniRow} from "../../../../@WUM/core/Elements";


const OverviewProfil = (props:{value:any}) => {

    Fetchdownlaod('Conf/invoice/bulk/','There is no invoice available for you. If you have any questions, please contact <a href="mailto:support@icymare.com">support@icymare.com</a>.')
    const Store = useSelector((state: any) => state)

    const InvoiceDetail = ResponseInvoiceDetail(416)

    if (InvoiceDetail !== true && typeof InvoiceDetail !== "boolean" && InvoiceDetail !== undefined) {
        return (
            <>
                <div id={'download'}/><div/>
                <UniRow >
                    <PDFViewer
                        width={"auto"}
                        height={"750px"}
                        className={"col-8"}
                    >
                        <InvoicePDFController response={InvoiceDetail}/>
                   </PDFViewer>
                    <UniCol>
                        <QrController url={"www.google.de"} width={200} height={200} urlWithId={true} id={5} />
                    </UniCol>

                </UniRow>



            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }

}

export default OverviewProfil;
