import {useEffect, useState} from 'react';
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import {eventCalendar} from "../response/EventCalendar";
import {EventCalendarApiInterface, SessionDetailsInterface} from "../response/EventCalendarApi.interface";

export const EventCalendarOverviewApi = () => {
    const [posts, setPosts] = useState<EventCalendarApiInterface | null>(null);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await eventCalendar.getPosts()
                .then((data) => {
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [])


    return isError ? (posts ) : (posts);

}

export const EventCalendarSessionDetails = (id?: string) => {
    const [posts, setPosts] = useState<SessionDetailsInterface | null>(null);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await eventCalendar.getSessionDetail(id)
                .then((data) => {
                    if(data.hasOwnProperty('msg')) {
                        Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Unfortunately, something went wrong'}, insert:'top',duration:4})
                    }
                    setPosts(data);
                })
                .catch((err) => {
                    Notification({type:'danger', mode:'classic', title:{text:'Error'}, text:{text: err}, insert:'top',duration:4})
                    setIsError(true);
                });
        })()}, [])


    return isError ? (posts ) : (posts);
}