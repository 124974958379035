import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {a11yProps} from "../Controller/CreateAriaLabels";
import {CustomTabPanel} from "../View/CustomTabPanel.View";
import {VariantsModel} from "../Model/Variants.Model";


export const TabpanelView = (props: VariantsModel) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{width: '100%'}}  className={props.tabConfigurations.class} id={props.tabConfigurations.id} >
                <Box>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                          variant="scrollable"
                          scrollButtons
                          allowScrollButtonsMobile
                    >
                        {props.tabConfigurations.tabs.map((config) => (
                            <Tab key={config.TabNumber}
                                 label={`${config.label}`} {...a11yProps(config.TabNumber)} />
                        ))}
                    </Tabs>
                </Box>
                {props.tabConfigurations.tabs.map((config) => (
                    <CustomTabPanel key={config.TabNumber} value={value} index={config.TabNumber}>
                        <>{config.content}</>
                    </CustomTabPanel>
                ))}
            </Box>
        </>
    )
}