import {Font} from "@react-pdf/renderer";
// @ts-ignore
import openSansLight from "./open-sans-v40-latin-300.ttf";
// @ts-ignore
import openSansRegular from "./open-sans-v40-latin-regular.ttf";
// @ts-ignore
import openSansMedium from "./open-sans-v40-latin-500.ttf";
// @ts-ignore
import openSansSemibold from "./open-sans-v40-latin-600.ttf";
// @ts-ignore
import openSansBold from "./open-sans-v40-latin-700.ttf";
// @ts-ignore
import openSansExtrabold from "./open-sans-v40-latin-800.ttf";

const OpenSansFontsRegister = () => {
    Font.register({
        family: 'Open Sans',
        fonts: [
            {
                src: openSansLight,
                fontWeight: "light"
            },
            {
                src: openSansRegular,
                fontWeight: "normal"
            },
            {
                src: openSansMedium,
                fontWeight: "medium"
            },
            {
                src: openSansSemibold,
                fontWeight: "semibold"
            },
            {
                src: openSansBold,
                fontWeight: "bold"
            },
            {
                src: openSansExtrabold,
                fontWeight: "ultrabold"
            },
        ]
    })
}

export default OpenSansFontsRegister;