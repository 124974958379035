
import {SessionMenuConfig, SessionPagesConfig} from "./session/modules";
import {MemberMenuConfig, MemberPagesConfig} from "./Member";
import {EducationMenuConfig, EducationPagesConfig} from "./educationLevel/modules";
import {PresentationMenuConfig, PresentationPagesConfig} from "./presentationType/modules";
import {EmailPagesConfig, EmailMenuConfig} from "./Email/modules";
import {PresentationSettingsMenuConfig, PresentationSettingsPagesConfig} from "./presentation/modules";
import {
    SocialEventSettingsMenuConfig,
    SocialEventSettingsPagesConfig
} from "./events/modules";
import {EventSettingsMenuConfig, EventSettingsPagesConfig} from "./Conference/modules";

export const SettingsRouteConfig = (value:any) => {
    return [
        ...SessionPagesConfig(value),
        ...EducationPagesConfig(value),
        ...PresentationPagesConfig(value),
        ...MemberPagesConfig(value),
        ...EmailPagesConfig(value),
        ...PresentationSettingsPagesConfig(value),
        ...SocialEventSettingsPagesConfig(value),
        ...EventSettingsPagesConfig(value)
    ]
}

export const SettingsMenuConfig = (value:any) => {
    return [
        {
            header: 'Settings',
            icon: 'settings',
            menuitem: [
                ...SessionMenuConfig(value),
                ...EducationMenuConfig(value),
                ...PresentationMenuConfig(value),
                ...MemberMenuConfig(value),
                ...EmailMenuConfig(value),
                ...PresentationSettingsMenuConfig(value),
                ...SocialEventSettingsMenuConfig(value),
                ...EventSettingsMenuConfig(value)
            ],
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
    ];
}
