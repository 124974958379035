import React, { type SVGProps } from "react";

/**
 * Defines the props for the LaUniversity component.
 */
interface LaUniversityProps extends SVGProps<SVGSVGElement> {
    /** Optional color for the SVG element. */
    color?: string;

    /** Optional width of the SVG element in pixels. Defaults to 32. */
    width?: number;

    /** Optional height of the SVG element in pixels. Defaults to 32. */
    height?: number;
}

/**
 * Renders an SVG representing an University.
 *
 * @param {LaUniversityProps} props - Component props.
 * @returns {JSX.Element} - The rendered SVG element.
 *
 * @source https://icon-sets.iconify.design/la/university/
 */
export const LaUniversity: React.FC<LaUniversityProps> = (props) => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                width={props.width}
                height={props.height}
                {...props}
            >
                <path
                    fill={props.color || "#135e7e"}
                    d="m16 3.875l-.438.219L5.563 9L5 9.281V11h22V9.281L26.437 9l-10-4.906zm0 2.25L21.875 9h-11.75zM7 12v10H6v2h20v-2h-1V12h-2v10h-2V12h-2v10h-2V12h-2v10h-2V12h-2v10H9V12zM4 25v2h24v-2z"
                />
            </svg>
        </>
    );
};
