import React, {
    useEffect, useState
} from 'react';
import {useNavigate,
    useParams
} from 'react-router-dom';
import {
    ApiURL,
    ContentSwitch,
    IntlMessage,
    IntlMessageValue,
    SearchJSX,
    SpinnerJSX
} from '../../../../../@WUM/core/Elements/index';
import {useSelector} from "react-redux";
import {ResponseSurvey} from '../../API/request/abstract';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import configuration from '../configuration/Page/overview';
import PielersTable from '../../../../../@WUM/core/tablemaker';
import {
    CreateInvoiceModel,
    initialState
} from "../../../../../@WUM/core/stripe/PaymentSuccess/Model/CreateInvoiceModel";
import CommentInvoiceController
    from "../../../../../@WUM/core/stripe/PaymentSuccess/Controller/CommentInvoiceController";
import InvoiceStatusController from "../../../../../@WUM/core/stripe/PaymentSuccess/Controller/InvoiceStatusController";
import {getToken} from "../../../../../@WUM/core/component/const/getToken";
import { Button } from 'react-bootstrap';
import Table from "react-bootstrap/Table";



const RegistrationOverview = (value:any) => {

    const {page,deleteID} = useParams<{page: string,deleteID: string}>();
    // let limit: any;
    // if (typeof page === "string") {
    //     limit = (parseFloat(page) * 10) - 10;
    // }

    let year: any
    year = 2024

    const Store = useSelector((state: any) => state)
    const history = useNavigate();
    /**
     * Neuladen der Seite
     */
    const routeChange = (paths:string) => {
        history(-1);
    };

    if(deleteID) {

        routeChange('/registration/overview/1')
    }
    const debug = (page: string | undefined,modalShow: boolean, modalData: any[]) => {
        return;
    }
    const destination = 'Registration';
    const namespace_search = "Registration_searchValue";
    const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
    const [search, setSearch] = useState<boolean>(false)
    const response = ResponseSurvey(year);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState([]);
    debug(page,modalShow,modalData)
    const [mode, setMode] = useState('cart');


    const SearchConfig = [{
        namespace:namespace_search,
        destination: destination,
        input:[
            {id:'search_name',translation: 'Search for title...',param: 'presentationTitle',method: 'like'}

        ],
        button: ['Search','Delete Search','Register']
    }]


    const CardsConfiguration = {
        'data': {
            'ressource': response,
            'img': '',
            'status': 'confParticipantType_id',
            'statusindi': true,
            "statusindiRow": true,
            'statusMapping': [
                {name: 'participant',color:'dark'},
                {name: 'presenting participan',color:'danger'},
                {name: 'session host',color:'danger'},
                {name: 'sponsor',color:'warning'},
                {name: 'team member',color:'warning'}
            ],
            'link': 'translation.de.slug',
            'prelink': '/magazin/[param]/',
            'prelinksearch': 'translation.de.slug',
            'title': 'confParticipantType_id',
            'descriptionShow': true,
            'description': 'confSession.title',
            'router': 'uid',
            'showauthor': true,
            'author_firstname': 'personal_details.first_name',
            'author_lastname': 'personal_details.last_name',
            'preroute': '/registration/Detail/',
            'edit': 'Show Registration',
            'editOnly': true,
            'delete': false,
            'deleteLink': '/registration/Delete/'+page+'/[ID]',
            'Dontvisited': true,
        }
    }

    const DownloadCsv = (props: CreateInvoiceModel = initialState) => {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify(
            {
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        fetch(ApiURL() +"conf/survey/getcsv/2024/", requestOptions)
            .then(response => response.json())
            .then(result => {console.log(result)})
            .catch(error => console.log(error));

    }

    const handleClick = () => {
        DownloadCsv(); // Aufruf der Funktion beim Klick
    };











    if (response.length > 0) {

                    return (
            <>
                {/*<SearchJSX noNewButton={true} setSearchValue={setSearchValue} Store={Store} SearchConfig={SearchConfig} />*/}
                {/*{ <ContentSwitch pagenew={'new'} destinationnew={'Abstract'} tonew={'new'} text={'Show Abstract'}*/}
                {/*                 response={response} CardsConfiguration={CardsConfiguration} mode={mode}*/}
                {/*                 Configuration={configuration(response)} setMode={setMode}  destination={destination} page={page}*/}
                {/*                 to={'overview'} setSearchValues={setSearchValue} setSearchs={setSearch} search={false}*/}
                {/*                 modalShow={modalShow} setModalShow={setModalShow} modalData={modalData} setModalData={setModalData} SearchParam={'?translation.title'}*/}
                {/*                 searchfield={false} allTaxon={[]} _handleKeyFilters={[]} _handleEnter={[]} filter={true} stop={false} Store={Store}/>}*/}
                <p>Number of submitted surveys: {response.length}</p>
                <MyTable data={response} />

                <PielersTable Configuration={configuration(response)}/>
                {/*<Button onClick={handleClick}>test</Button>*/}



            </>

        )
    } else {
        localStorage.setItem('searchEnterInput','');
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }

};



export default RegistrationOverview;


/* just a quick summary function - not adaptable. Maybe an idea for the table maker as option? */
interface DataItem {
    id: number;
}


export function restructureData(data: DataItem[]): Record<keyof DataItem, number[]> {
    const columns = Object.keys(data[0]).filter(key => key !== 'id' && key !== 'countryOriginName' && key !== 'currentCountryName' && key !== 'otherSexText' && key !== 'otherDegreeText' && key !== 'year' && key !== 'countryOrigin'  && key !== 'currentCountry'  );

    const restructuredData: Record<keyof DataItem, number[]> = columns.reduce((acc, column) => {
        acc[column as keyof DataItem] = data.map(row => row[column as keyof DataItem] as number);
        return acc;
    }, {} as Record<keyof DataItem, number[]>);

    return restructuredData;
}

function calculateColumnSums(data: Record<string, number[]>): Record<string, number> {
    return Object.keys(data).reduce((sums, column) => {
        sums[column] = data[column].reduce((sum, value) => sum + value, 0);
        return sums;
    }, {} as Record<string, number>);
}

function MyTable({ data }: { data: DataItem[] }) {
    const [columnSums, setColumnSums] = useState<Record<string, number>>({});

    useEffect(() => {
        const restructuredData = restructureData(data);
        const sums = calculateColumnSums(restructuredData);
        setColumnSums(sums);
    }, [data]);
    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                {Object.keys(columnSums).map(column => (
                    <th key={column}>{column}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {/* ... Tabellenkörper ... */}
            </tbody>
            <tfoot>
            <tr>
                {Object.keys(columnSums).map(column => (
                    <td key={column}>{columnSums[column]}</td>
                ))}
            </tr>
            </tfoot>
        </Table>
    );
}