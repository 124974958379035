
import updateDataMappingProps from "../@WUM/core/component/core/interface/updateDataMappingProps.interface";
import {blogApiDetail} from "../modules/setting/Email/API/request/blog/blogApiDetail.interface";
import {configBlog, outputBlog} from "../modules/setting/Email/modules/configuration/mapping";


const updateDataMapping = (props:updateDataMappingProps) => {
    switch (props.part) {
        case 'blog':
            let requestDataBlog: blogApiDetail[] & configBlog = Object.assign(
                {},
                props.currentTutorial,
                outputBlog(props.Data)
            )
            return requestDataBlog
    }
}

export default updateDataMapping;
