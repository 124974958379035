import {EventCalendarOverviewApi} from "../../API/request/EventCalendar";
import {MakeMuiTabView} from "../MuiTabpanel/Controller/Tabpanel.Controller";
import {TabConfiguration} from "../MuiTabpanel/Model/TabConfiguration.Model";
import DayView from "../Views/Day.View";
import {HeaderView} from "../Views/Elements";
import './../CSS/eventCalendar.css'
import {EventCalendarApiInterface} from "../../API/response/EventCalendarApi.interface";
import {SplashscreenView} from "../Views/Elements/Splashscreen.View";
import {useEffect, useState} from "react";


const EventCalendarOverview = (value: any) => {
    const response: EventCalendarApiInterface | null = EventCalendarOverviewApi();

    /** handle splash screen functionality */
    const [hasVisited, setHasVisited] = useState(false);

    useEffect(() => {
        const storedValue = localStorage.getItem('EventCalendarVisited');
        if (storedValue) {
            setHasVisited(true);
        } else {
            localStorage.setItem('EventCalendarVisited', 'true');
        }
    }, []);

    const handleContinue = () => {
        setHasVisited(true);
        localStorage.setItem('EventCalendarVisited', 'true');
    };

    if (response !== null && response !== undefined) {
        const tabs = response.days.map((day, index) => ({
            label: "Day " + (index + 1),
            content: (
                <DayView
                    value={day.events}
                    header={response.icyEvent}
                    day={day.date}
                    tabLabel={"Day " + (index + 1)}
                />
            ),
            TabNumber: index,
        }));
        const tabConfigurations: TabConfiguration = {
            id: 'main-tabs',
            class: 'bg-white',
            mobileBreakpoint: 768,
            tabs: tabs
        };

        return (
            <>
                <div className={'freeWrapper'} style={{background: 'white'}}>
                    {hasVisited ? (
                        <div className="event-calendar">
                            <HeaderView header={response.icyEvent}/>
                            <MakeMuiTabView tabConfigurations={tabConfigurations}/>
                        </div>
                    ) : (
                        <SplashscreenView onContinue={handleContinue}/>
                    )}
                </div>
            </>
        )
    } else {
        return <>waiting</>
    }
}

export default EventCalendarOverview