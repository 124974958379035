import {HeaderView, LocationBarView, PresentationOverview} from "../Views/Elements";
import {Link, useLocation, useParams} from "react-router-dom";
import {Button} from "react-bootstrap";
import {MIcon} from "../../../../@WUM/core/Elements";
import React from "react";
import {removeHTMLTags} from "../Controller/RemoveHTML.Controller";

interface PresentationDetailViewProps {
    value?: any
}

export const PresentationDetails = (props: PresentationDetailViewProps) => {
    const {id} = useParams<{ id: string }>();

    const location = useLocation();
    const sanitizedHtmlTitle = removeHTMLTags(location.state.value.title);
    const sanitizedHtmlAbstract = removeHTMLTags(location.state.value.abstract);


    return (
        <>

            <div className={'freeWrapper bg-white'}>
                <div className="event-calendar">
                    <HeaderView header={location.state.header}/>
                    <Link to={"/unbound/event-calendar/session-details/" + location.state.value.sessionId}
                          state={location.state.header}>
                        <Button variant={"dark"} className={"w-100 event-button-back"}> <MIcon
                            style={{verticalAlign: "top"}}>arrow_back</MIcon> Back to Schedule</Button>
                    </Link>
                    <div className={"daily-header"}>
                        <h3 className={"session-headline"}>{sanitizedHtmlTitle}</h3>
                        <p className={"session-description"}>{location.state.value.speaker || location.state.value.secondSpeaker}</p>
                    </div>
                    <div className={"abstract-text"}>
                        {sanitizedHtmlAbstract}
                    </div>
                    {/*
                        <LocationBarView locationLink={location.locationLink} locationAddress={location.locationAddress} locationHeadline={location.locationHeadline}/>
                        <Link to="/unbound/event-calendar">
                            <Button variant={"dark"} className={"w-100"}> <MIcon style={{verticalAlign: "top"}}>arrow_back</MIcon>  Back to Schedule</Button>
                        </Link>
                        <PresentationOverview value={location.presentations} locationLInk={location.locationLink}
                                              locationAddress={location.locationAddress}
                                              locationHeadline={location.locationHeadline} name={location.name}
                                              description={location.description}/>
*/}
                </div>
            </div>
        </>
    )

}
