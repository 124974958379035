
export interface TextareaModel {
    data: any
    rows: string[][]
    totalRow: string[]
    message?: string
}

export const initialState:TextareaModel = {
    data: [],
    totalRow: [],
    rows: [['']],
    message: ''
}