import {Page, Document, View, Text, Image, Font} from '@react-pdf/renderer';
import { styles } from './css/BadgePDFView.css';
import FontsRegister from "../../../../../PDFFonts/FontsRegister";
import React from "react";
import {PDFUniTextView} from "./Elements";
import {BadgePDFControllerModel} from "../Model/BadgePDFControllerModel";

/**
 * @author Gregor Schalm
 * @function BadgePDFView - Functional React component for rendering the main view of the icymare badge document.
 * @param {BadgePDFControllerModel} props - Component properties containing invoice data.
 * @returns {JSX.Element} - React JSX element representing the badge PDF document.
 */

const BadgePDFView = (props: BadgePDFControllerModel) => {
    FontsRegister({raleway: true, daysone: true });

    // avoids hyphenation
    Font.registerHyphenationCallback(word => (
        [word]
    ));

    return (
        <Document>
            <Page size={{ width: 255, height: 170 }} style={styles.page}>
                <View>
                    <PDFUniTextView text={props.name} classView={styles.name}/>
                    <PDFUniTextView text={props.pronoun} classView={styles.pronouns}/>
                    <PDFUniTextView text={props.affiliation} classView={styles.affiliation}/>
                    <PDFUniTextView text={props.interests} classView={styles.interests} wrap={false}/>
1                </View>
                <View style={{position: "absolute", bottom: "2mm", left: "4mm", height: "13.5mm", width: "80.6mm"}} debug={false}>
                    <Image src={"/pdf-images/IcymareBadgeGraph.png"} />
                </View>
            </Page>
        </Document>
    );
};

export default BadgePDFView;
