import {requestsUnauth} from "../UnauthSecureRoutes/RequestsUnauth";
import {EventCalendarApiInterface, SessionDetailsInterface} from "./EventCalendarApi.interface";

// necessary to create the authorization value
const IP = '37.157.255.206'
const date = new Date().toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
});
const secretWord = 'ldGvNNWMtHPgoQkq'

const secret = date + '/' + secretWord + '/' + IP

const base64Encode = btoa(secret)


export const eventCalendar = {
    getPosts: (): Promise<EventCalendarApiInterface> => requestsUnauth.get(`conf/shedule/event/overview/${base64Encode}/`),
    getSessionDetail: (id?: string): Promise<SessionDetailsInterface> => requestsUnauth.get(`/conf/shedule/event/detail/${base64Encode}/?id=${id}&type=session`),
    getEventDetail: (id?: string): Promise<SessionDetailsInterface> => requestsUnauth.get(`/conf/shedule/event/detail/${base64Encode}/?id=${id}&type=event`)

};
