import {useNavigate, useParams} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup'
import '../css/dashboard.css'
import {useSelector} from "react-redux";
import ReactDOM from "react-dom";
import {ResponseLevel, ResponsePrasentationType, ResponseSession,ResponseAbstractSingle} from "../../API/request/abstract";
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import Badge from "react-bootstrap/Badge";
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import {btn_back} from "../../../../../@WUM/core/component/const/btn_back";
import Keywords from "../helper/keywords";
import Authors from "../helper/authors";
import Affiliations from "../helper/affiliation";
import {InfoButton, IntlMessage, IntlMessageValue, MIcon, UniForm} from "../../../../../@WUM/core/Elements";
import SunEditor from "suneditor-react";
import send from "../elements/Send";
import {SecureRequest} from "../../../SessionforHost/modules/Pages/detail";
import React from "react";
const AbstractDetailUser = (props: { value: any }) => {
    const {id} = useParams<{ id: string }>();
    const Store = useSelector((state: any) => state)
    let authorint = 0;
    let keywordsint = 0;
    let affiliationsint = 0;
    const Level = ResponseLevel();
    const response = ResponseAbstractSingle(id)
    const PresentationType = ResponsePrasentationType();
    const history = useNavigate();
    const back =  btn_back(history,"common.back","btnBack",Store)
    const Session = ResponseSession();

/*   const send = (e:any) => {
        e.preventDefault();
        let data = new FormData(e.target);
        let value = Object.fromEntries(data.entries());
        let raw = JSON.stringify(value);
       let root = ApiURL();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw,
        };
        fetch(root+"Conference/Presentation/"+id+"/", requestOptions)
            .then(response => response.text())
            .then(result => afterSend('success'))
            .catch(error => afterSend('error'));
   }*/

    const afterSend = (state:any) => {
        if(state === 'success') {
            Notification({reload:true,type:'success', mode:'classic', title:{text:'Success'}, text:{text: 'Saved successfully'}, insert:'center right',duration:4})
        } else if(state === 'error'){
            Notification({reload:true,type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Sorry, an error has occurred'}, insert:'center right',duration:4})
        }
    }


    const addElement = (row:any = false,col:any,id:any,addClass:any='') => {
        let counter;
        if(id==='Authors') {
            authorint++;
            counter= authorint;
        }
        if(id==='Keywords'){
            keywordsint++;
            counter= keywordsint;
        }

        if(id==='Affiliations') {
            affiliationsint++;
            counter= affiliationsint;
        }
        if(row === true) {
            let newElement = (<><div className={'col-'+col}><InputGroup className={"mb-3 inputGroupExtra form-control "+addClass}>
                <Form.Control
                    name={id+''+counter}
                    placeholder="Lorem Ipsum"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                />
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                 onClick={()=>addElement(row,col,id,addClass)}>
                                <span className="material-icons">
                                    add
                                </span>
                </InputGroup.Text>
            </InputGroup></div></>);
            let temp = document.createElement('div');
            temp.classList.add('row')
            ReactDOM.render(newElement, temp);
            let ele =  document.getElementById(id);
            if(ele !== null) {
                ele.appendChild(temp)
            }
        } else {
            let newElement = (<><InputGroup className={"mb-3 inputGroupExtra form-control "+addClass}>
                <Form.Control
                    name={id+''+counter}
                    placeholder="Lorem Ipsum"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                />
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                 onClick={()=>addElement(row,col,id,addClass)}>
                                <span className="material-icons">
                                    add
                                </span>
                </InputGroup.Text>
            </InputGroup></>);
            let temp = document.createElement('div');
            temp.classList.add('col-'+col)
            ReactDOM.render(newElement, temp);
            let ele =  document.getElementById(id);
            if(ele !== null) {
                ele.appendChild(temp)
            }
        }

    }

    if(Level.length > 0 && PresentationType.length > 0 && Session.length > 0 && response != undefined) {
        let optionLevel = [];

        let optionPresentationType = [];

        let optionSession = [];

        for(let l=0;l<Level.length;l++){
            optionLevel.push(
                <>
                    <option value={Level[l].id}>{Level[l].type}</option>
                </>
            )
        }

        for(let l=0;l<PresentationType.length;l++){
            optionPresentationType.push(
                <>
                    <option value={PresentationType[l].id}>{PresentationType[l].type}</option>
                </>
            )
        }

        for(let l=0;l<Session.length;l++){
            optionSession.push(
                <>
                    <option value={Session[l].id}
                            dangerouslySetInnerHTML={{__html: Session[l].title}}/>
                </>
            )
        }
        let badgeColor;
        let badgeText;
        switch (response.sesonState) {
            case 4:
                badgeColor = 'danger';
                badgeText = 'resubmitted';
                break;
            case 3:
                badgeColor = 'success';
                badgeText = 'accepted by Host';
                break;
            case 2:
                badgeColor = 'warning';
                badgeText = 'returned for rework by Host';
                break;
            case 1:
                badgeColor = 'danger';
                badgeText = 'declined by Host';
                break;
            default:
                badgeColor = 'warning';
                badgeText = 'passed to Host';
        }
        let icybadgeColor;
        let icybadgeText;
        let state = [
            {name: 'submitted',color:'danger'},
            {name: 'Icymare: Declined ',color:'danger'},
            {name: 'Icymare: Rework by user',color:'warning'},
            {name: 'Icymare: Passed to Host',color:'warning'},
            {name: 'Host: Not suitable for session',color:'danger'},
            {name: 'Host: Revision by user',color:'warning'},
            {name: 'Host: Final acceptance',color:'success'}]

        for(let s =0;s<state.length;s++) {
            if(state[s].name === response.state){
                badgeColor = state[s].color;
                badgeText = state[s].name;
            }
        }
        const SunEditorConfig = () => {
            return [
                // Default
                ['undo', 'redo','italic','subscript', 'superscript','removeFormat','fullScreen'],
            ]
        }


        return (<>
            <Row className={''}>
                <Row>
                    <Col xs={12} md={12} xl={4} style={{background: 'transparent linear-gradient(270deg, #0CB4CE 0%, #317391 100%) 0% 0% no-repeat padding-box',color:'white',maxHeight:'840px'}}>
                        <br />
                        <p className={'text-center d-none d-xl-block'}><img src={'https://cdn.wum-solution.de//icymareKonfDev/2024/02/27/Xk/ICYMARE-Logo-wide-screen-SW.png'} /></p>
                        <br />
                        <h2>Abstract Submission ICYMARE 2024 Bremen</h2>
                        <p> <img src={"https://cdn.wum-solution.de//icymareKonfDev/2023/12/18/R5/ICYMARE-2024-BREMEN-CallForAbstracts.jpg"} /></p>
                        <br />
                        {/*<p><IntlMessage messageId="icy.abstract.submission" Store={Store}/></p>
                        <p className={'text-center'}><strong>State ICYMARE:</strong> <Badge bg={icybadgeColor}> {icybadgeText}
                        </Badge></p>*/}
                        <p className={'text-center'}><strong>State Session Host:</strong> <Badge bg={badgeColor}> {badgeText}
                        </Badge></p>
                    </Col>
                    <Col style={{background: '#FFFFFF 0% 0% no-repeat padding-box',maxHeight:'840px',overflowX:'hidden',overflowY:'scroll'}}>  <Form id={'form01'} onSubmit={(e)=> send(e,afterSend,id,response.file1,response.file2)}>
                        <Row> <h3>Presenting Author</h3></Row><Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="Title">
                                <UniForm.Label
                                    text={IntlMessageValue('de', 'icy.title', Store)}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessageValue('de', 'icy.title.explanation', Store)
                                    }} />
                                <Form.Control type="text" name={'Title'} placeholder="Enter Title" defaultValue={response.title}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="FirstName">
                                <UniForm.Label
                                    text={IntlMessageValue('de', 'icy.first.name', Store)}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessageValue('de', 'icy.first.name.explanation', Store)
                                    }} />
                                <Form.Control type="text" name={'FirstName'} placeholder="Enter first name" defaultValue={response.user.first_name}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="LastName">
                                <UniForm.Label
                                    text={IntlMessageValue('de', 'icy.last.name', Store)}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessageValue('de', 'icy.last.name.explanation', Store)
                                    }} />
                                <Form.Control type="text" name={'LastName'} placeholder="Enter last name" defaultValue={response.user.last_name}/>
                            </Form.Group>
                        </Col>
                    </Row>
                        <Row>

                            <Col>
                                <Form.Group className="mb-3" controlId="Emailaddress">
                                    <UniForm.Label
                                        text={IntlMessageValue('de', 'icy.email', Store)}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessageValue('de', 'icy.email.explanation', Store)
                                        }} />
                                    <Form.Control type="email" name={'Emailaddress'} placeholder="Enter email adress" defaultValue={response.user.email}/>
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row><Col><Form.Group className="mb-3" controlId="Mainaffiliation">
                            <Form.Label>Main affiliation</Form.Label>
                            <Form.Control type="text" name={'mainAffiliation'} placeholder="Enter main affiliation" defaultValue={response.mainAffiliation}/>
                        </Form.Group></Col></Row>


                        <Row>
                            <h3><IntlMessage messageId="icy.education.level" Store={Store} /></h3>
                            <p><IntlMessage messageId="icy.education.level.explanation" Store={Store} /></p>
                        </Row>

                        <Row>
                            <Col>
                                <UniForm.Label
                                    text={IntlMessageValue('de', 'icy.level', Store)}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessageValue('de', 'icy.level.explanation', Store)
                                    }} />
                                <Form.Select aria-label="Default select example" name={'Level'} defaultValue={response.educationLevel.levelId}>
                                    {optionLevel}
                                </Form.Select>
                            </Col>
                            <Col><Form.Group className="mb-3" controlId="Year">
                                <UniForm.Label
                                    text={IntlMessageValue('de', 'icy.year', Store)}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessageValue('de', 'icy.edu.year.explanation', Store)
                                    }} />
                                <Form.Control type="text"  name={'Year'} placeholder="Enter Year" defaultValue={response.educationLevel.year}/>
                            </Form.Group></Col>
                        </Row>

                        <Row>
                            <h3><IntlMessage messageId="icy.sessions" Store={Store} /></h3>
                            <p><IntlMessage messageId="icy.sessions.explanation" Store={Store} /></p>
                        </Row>
                        <Row>
                            <Col>
                                <UniForm.Label
                                    text={IntlMessageValue('de', 'icy.session', Store)}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessageValue('de', 'icy.session.explanation', Store)
                                    }} />
                                <Form.Select aria-label="Default select example" name={'Sessions'} defaultValue={response.confSession.id} disabled={true}>
                                    {optionSession}
                                </Form.Select>
                            </Col>
                            <Col>
                                <UniForm.Label
                                    text={IntlMessageValue('de', 'icy.type.presentation', Store)}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessageValue('de', 'icy.type.presentation.explanation', Store)
                                    }} />
                                <Form.Select aria-label="Default select example" name={'PresentationType'} defaultValue={response.presentationTypeId}>
                                    {optionPresentationType}
                                </Form.Select>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Form.Group className="mb-3" controlId="PresentationTitle">
                                <Form.Label>Presentation title</Form.Label>
                                <SunEditor
                                    key={'test'}
                                    name={'PresentationTitle'}
                                    placeholder="Enter presentation title"
                                    defaultValue={response.presentationTitle}
                                    setOptions={{
                                        buttonList: SunEditorConfig(),
                                        attributesWhitelist: {
                                            div: 'id',
                                            p: 'id',
                                            h: 'id'
                                        }

                                    }}
                                    height={'50px'}
                                />
                            </Form.Group>
                        </Row>

                        <Row>
                            <h3><IntlMessage messageId="icy.authors.affiliation" Store={Store}/></h3>
                            <Col xs={11}>
                                <p><IntlMessage messageId="icy.authors.affiliation.explanation" Store={Store}/></p>
                            </Col>
                            <Col>
                                <InfoButton.Wrapper>
                                    <InfoButton
                                        text={IntlMessageValue('de', 'icy.author.name.explanation', Store)}
                                        icon={'info'}
                                    />
                                </InfoButton.Wrapper>
                            </Col>
                        </Row>
                        <Authors author={response.authors} readonly={false} />
                        <br />
                        <Row>
                            <Col xs={11}>
                                <p><IntlMessage messageId="icy.affiliation.text" Store={Store} /></p>
                            </Col>
                            <Col>
                                <InfoButton.Wrapper>
                                    <InfoButton
                                        text={IntlMessageValue('de', 'icy.affiliation.explanation', Store)}
                                        icon={'info'}
                                    />
                                </InfoButton.Wrapper>
                            </Col>
                        </Row>
                        <Affiliations affiliations={response.affiliates} readonly={false} />
                        <br />
                        <br />
                        <Row>
                            <h3><IntlMessage messageId="icy.social.media" Store={Store}/></h3>
                            <p><IntlMessage messageId="icy.social.media.explanation" Store={Store}/></p>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="Twitter">
                                    <Form.Label>Twitter</Form.Label>
                                    <Form.Control type="text"  name={'Twitter'} placeholder="Enter Twitter account" defaultValue={response?.socialMediaInstitution[0]?.Twitter}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="Instagram">
                                    <Form.Label>Instagram</Form.Label>
                                    <Form.Control type="text" name={'Instagram'} placeholder="Enter Instagram account" defaultValue={response?.socialMediaInstitution[0]?.Instagram}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="Facebook">
                                    <Form.Label>Facebook</Form.Label>
                                    <Form.Control type="text" name={'Facebook'} placeholder="Enter Facebook account" defaultValue={response?.socialMediaInstitution[0]?.Facebook}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="LinkedIn">
                                    <Form.Label>LinkedIn</Form.Label>
                                    <Form.Control type="text" name={'LinkedIn'} placeholder="Enter LinkedIn account" defaultValue={response?.socialMediaInstitution[0]?.LinkedIn}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="Mastodon">
                                    <Form.Label>Mastodon</Form.Label>
                                    <Form.Control type="text" name={'Mastodon'} placeholder="Enter Mastodon account" defaultValue={response?.socialMediaInstitution[0]?.Mastodon}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="Bluesky">
                                    <Form.Label>Bluesky</Form.Label>
                                    <Form.Control type="text" name={'Bluesky'} placeholder="Enter Bluesky account" defaultValue={response?.socialMediaInstitution[0]?.Bluesky}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <h4><IntlMessage messageId="icy.social.media.institution" Store={Store} /></h4>
                            <p><IntlMessage messageId="icy.social.media.explanation.institution" Store={Store} /></p>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionTwitter">
                                    <Form.Label>Twitter</Form.Label>
                                    <Form.Control type="text" name={'InstitutionTwitter'} placeholder="Enter Twitter account" defaultValue={response?.socialMediaInstitution[0]?.InstitutionTwitter}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionInstagram">
                                    <Form.Label>Instagram</Form.Label>
                                    <Form.Control type="text" name={'InstitutionInstagram'} placeholder="Enter Instagram account" defaultValue={response?.socialMediaInstitution[0]?.InstitutionInstagram}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionFacebook">
                                    <Form.Label>Facebook</Form.Label>
                                    <Form.Control type="text" name={'InstitutionFacebook'} placeholder="Enter Facebook account" defaultValue={response?.socialMediaInstitution[0]?.InstitutionFacebook}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionLinkedIn">
                                    <Form.Label>LinkedIn</Form.Label>
                                    <Form.Control type="text" name={'InstitutionLinkedIn'} placeholder="Enter LinkedIn account" defaultValue={response?.socialMediaInstitution[0]?.InstitutionLinkedIn}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionMastodon">
                                    <Form.Label>Mastodon</Form.Label>
                                    <Form.Control type="text" name={'InstitutionMastodon'} placeholder="Enter Mastodon account" defaultValue={response?.socialMediaInstitution[0]?.InstitutionMastodon}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionBluesky">
                                    <Form.Label>Bluesky</Form.Label>
                                    <Form.Control type="text" name={'InstitutionBluesky'} placeholder="Enter Bluesky account" defaultValue={response?.socialMediaInstitution[0]?.InstitutionBluesky}/>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <h3><IntlMessage messageId="icy.keywords" Store={Store} /></h3>
                            <p><IntlMessage messageId="icy.keywords.explanation" Store={Store} /></p>
                        </Row>
                        <Keywords keywords={response.tags} readonly={false} />

                        <Row>
                            <Col xs="auto">
                                <h3><IntlMessage messageId="icy.abstract" Store={Store}/></h3>
                            </Col>
                            <Col>
                                <InfoButton.Wrapper>
                                    <InfoButton
                                        text={IntlMessageValue('de', 'icy.abstract.support', Store)}
                                        icon={'info'}
                                    />
                                </InfoButton.Wrapper>
                            </Col>
                            <p><IntlMessage messageId="icy.abstract.explanation" Store={Store}/></p>
                        </Row>
                        <Row>
                            <Form.Group className="mb-3" controlId="Abstract">
                                <SunEditor
                                    key={'test'}
                                    name={'Abstract'}
                                    placeholder="Enter Abstract"
                                    defaultValue={response.abstract}
                                    setOptions={{
                                        buttonList: SunEditorConfig(),
                                        attributesWhitelist: {
                                            div: 'id',
                                            p: 'id',
                                            h: 'id'
                                        }
                                    }}
                                    height={'250px'}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="formFileMultiple" className="mb-3">
                                    <UniForm.Label
                                        text={IntlMessageValue('de', 'icy.CV', Store)}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessageValue('de', 'icy.abstract.CV.explanation', Store)
                                        }} />
                                    <Form.Control id={'fileuploadAttachment1'} name={'file1'} type="file" accept=".pdf"/>
                                </Form.Group>
                                <Form.Group controlId="formFileMultiple" className="mb-3">
                                    <UniForm.Label
                                        text={IntlMessageValue('de', 'icy.abstract.upload', Store)}
                                        InfoButton={{
                                            display: true,
                                            text: IntlMessageValue('de', 'icy.abstract.docxDocument.explanation', Store)
                                        }} />
                                    <Form.Control id={'fileuploadAttachment2'} name={'file2'} type="file" accept=".docx"/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            {response.media1 !== null && response.media1 !== undefined
                                ?
                                <Col>
                                    <UniForm.Label
                                        text={IntlMessageValue('de', 'icy.CV', Store)}
                                        InfoButton={{
                                            display: false,
                                            text: IntlMessageValue('de', 'icy.abstract.CV.explanation', Store)
                                        }} />
                                    <br/>
                                    <object
                                        data={SecureRequest(response.media1.cdn_url)}
                                        width={'100%'}
                                        height={'400px'}
                                        aria-label={'pdf'}
                                        className={'mb-3'}
                                    />
                                </Col>
                                :
                                <>
                                </>

                            }
                            {response.media2 !== null && response.media2 !== undefined
                                ?
                                <Col className={"mb-3"}>
                                    <UniForm.Label
                                        text={IntlMessageValue('de', 'icy.abstract.upload', Store)}
                                        InfoButton={{
                                            display: false,
                                            text: IntlMessageValue('de', 'icy.abstract.docxDocument.explanation', Store)
                                        }} />
                                    <Form.Label  className={'h-100 w-100'}>
                                        <a download={response.media2.name}
                                           href={SecureRequest(response.media2.cdn_url) + '&content_type=' + response.media2.content_type + '&filename=' + response.media2.name}
                                           target={'_blank'} style={{height: "400px"}}
                                           className={'w-100 d-flex align-items-center text-center btn btn-primary'}>
                                            <div className={'w-100 display-6'}>
                                                <div><MIcon children={'description'} variant={'-outlined'} className={'display-3'}/></div>
                                                Abstract as .docx
                                            </div>
                                        </a>
                                    </Form.Label>
                                </Col>
                                :
                                <>
                                </>

                            }
                        </Row>
                        <Row>
                            <Form.Control  name={'state'} id={'state'} readOnly={true} type={'hidden'} defaultValue={'resubmitted'}/>
                            <Form.Control  name={'sesonState'} id={'sesonState'} readOnly={true} type={'hidden'} defaultValue={'4'}/>
                            <Col>
                                <br/>
                                <Form.Group className="mb-3" controlId="mending">
                                    <Form.Label>Mending Message ICYMARE </Form.Label>
                                    <Form.Control as="textarea" name={'Response'} id={'Response'} readOnly={true}  rows={5} defaultValue={response.icyResponse}/>
                                </Form.Group>
                                <br/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <br/>
                                <Form.Group className="mb-3" controlId="mending">
                                    <Form.Label>Mending Message Session Host</Form.Label>
                                    <Form.Control as="textarea" name={'Response'} id={'Response'} readOnly={true}  rows={5} defaultValue={response.seasonResponse}/>
                                </Form.Group>
                                <br/>
                            </Col>

                        </Row>

                        <Row>

                            <Col className={'text-center'}>{back}</Col>
                            <Col className={'text-center'} />
                            <Col className={'text-center'}>
                                {
                                    (response.state === 'Icymare: Rework by user' || response.state === 'Host: Revision by user' || response.state === undefined || response.state === null
                                        ? <Button variant="success" type="submit">resend</Button>
                                        : <></>)                                }

                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <br/> <br/>
                            </Col>
                        </Row>
                    </Form></Col>
                </Row>

            </Row>
        </>)
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }
}

export default AbstractDetailUser;
