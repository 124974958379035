import React from 'react';
import SessionOverview from "./Pages/overview";
import SessionDetail from "./Pages/detail";
import SessionNew from "./Pages/new";
import RewriteDeleted from "./Pages/deleted";

export const SessionPagesConfig = (value: any) => {

    return [
        {
            path: '/Session/overview/:page',
            elements: <SessionOverview value={value}/>,
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
        {
            path: '/Session/Detail/:id',
            elements: <SessionDetail value={value}/>,
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
        {
            path: '/Session/new/:page',
            elements: <SessionNew value={value}/>,
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },{
            path: '/Session/Delete/:page/:deleteID',
            elements: <RewriteDeleted value={value} />,
            roles: [
                'superadmin',
                'admin',
                'host',
                'user',
                'icymare'
            ]
        },
    ];
}

export const SessionMenuConfig = (value: any) => {
    return [
            {
                name: 'Session Overview',
                route: '/Session/overview/1',
                icon: 'dialer_sip',
                roles: [
                    'superadmin',
                    'admin',
                    'host',
                    'user',
                    'icymare'
                ]
            }
        ]
}
