import {LocationBarView, TimeFrameView} from "./";
import {Link} from "react-router-dom";
import React from "react";

export const EventOverviewView = (props: any) => {
    return (
        <>
            <Link to={"/unbound/event-calendar/event-details/" + props.id} className={"href-no-highlight"}
                  state={{header: props.header, value: props}}>
                <div style={{background: props.color}}>
                    <TimeFrameView start={props.start} end={props.end}/>
                    {props.state === 2 && (
                        <div
                            className="event-changed-warning">{props.changeReason ? props.changeReason : "The time/venue has been updated"}</div>
                    )}
                    {props.state === 3 && (
                        <div
                            className="event-changed-removed">{props.changeReason ? props.changeReason : "The event was cancelled"}</div>
                    )}
                    <LocationBarView locationLink={props.locationLink} locationAddress={props.locationAddress} locationHeadline={props.locationHeadline}/>
                    <div className={props.shortDescription ? "py-4" : "py-3"}>
                        <h3 className={"event-headline"}>{props.name}</h3>
                        {props.shortDescription && (
                            <div className="event-short-description pt-2">{props.shortDescription}</div>
                        )}
                    </div>
                </div>
            </Link>
        </>
    )
}