import CompanyInfoView from "./CompanyInfoView";
import { HeaderModel, initialState } from "../Model/HeaderModel";
import CustomerAddressView from "./CustomerAdressView";

/**
 * @author Gregor Schalm
 * @function HeaderView - Functional React component for rendering the header section of a PDF document.
 * @param {HeaderModel} props - Component properties that define the header content.
 * @returns {JSX.Element} - React JSX element representing the header section.
 */

const HeaderView = (props: HeaderModel = initialState) => {
    return (
        <>
            <CompanyInfoView />
            <CustomerAddressView data={props.data} />
        </>
    );
};

export default HeaderView;
