// send payment data to save the status of the payment
import {getToken} from "../../../component/const/getToken";
import {ApiURL} from "../../../component/const/ApiURL";
import {initialState, SendPaymentDataModel} from "../Model/SendPaymentDataModel";

export const sendPaymentData = (props: SendPaymentDataModel = initialState) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+ getToken());

    var raw = JSON.stringify(
        {
            "class": process.env.REACT_APP_API_STRIPE_CLASS,
            "redirect_status": props.redirect_status,
            "id": Number(props.RegistrationId),
            "payment_intent_client_secret": props.payment_intent_client_secret,
            "callback": props.callback,
            "callbackFunc": props.callbackFunc,
        }
    );

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
    };

    fetch(ApiURL() +"stripe/payed/", requestOptions)
        .then(response => response.json())
        .then(result => {console.log(result)})
        .catch(error => console.log(error));

}
