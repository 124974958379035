import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup'
import '../css/dashboard.css'
import {useSelector} from "react-redux";
import ReactDOM from "react-dom";
import {ResponseLevel, ResponsePrasentationType, ResponseSession} from "../../API/request/abstract";
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import {useNavigate} from "react-router-dom";
import {btn_back} from "../../../../../@WUM/core/component/const/btn_back";
import SunEditor from "suneditor-react";
import {InfoButton, UniForm} from "../../../../../@WUM/core/Elements";
import {IntlMessage, IntlMessageValue} from "../../../../../@WUM/core/Elements/index";
import token from "../../../../ticketsystem/modules/components/Token";
import send from "../elements/Send";
import React, {useEffect,useState} from "react";

const AbstractNew = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    let authorint = 0;
    let authorIconInt = 2;
    let keywordIconInt = 2;
    let keywordsint = 0;
    let affiliationsint = 0;
    const Level = ResponseLevel();
    const history = useNavigate();
    const back =  btn_back(history,"common.back","btnBack",Store)
    const PresentationType = ResponsePrasentationType();
    const [valueAbstract, setValueAbstract] = useState("");
    const [valuePresentationTitle, setValuePresentationTitle] = useState("");
    const Session = ResponseSession();

    const afterSend = (state:any) => {
        if(state === 'success') {
            Notification({rewrite:true,landingpages:'/',type:'success', mode:'classic', title:{text:'Success'}, text:{text: 'Saved successfully'}, insert:'center right',duration:4})
        } else if(state === 'error'){
            Notification({rewrite:true,landingpages:'/',type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Sorry, an error has occurred'}, insert:'center right',duration:4})
        }
    }
    const setEditorAbstractContent = (value:any) =>{
        if(value === '<p><br></p>') {
            setValueAbstract('')
        } else{
            setValueAbstract(value)
        }

    }
    const setEditorPresentationTitleContent = (value:any) =>{
        if(value === '<p><br></p>') {
            setValuePresentationTitle('')
        } else{
            setValuePresentationTitle(value)
        }

    }

    const deleteElement = (e:any) => {
        let element = e.target.parentElement.parentElement
        element.remove();
    }

    const addElement = (row:any = false,col:any,id:any,addClass:any='',numberIcon:any=false) => {
        let counter;
        if(id==='Authors') {
            authorint++;
            counter= authorint;
        }
        if(id==='Keywords'){
            if(keywordsint ===4) {
                alert('You may not enter more than 5 keywords')
                return (<></>)
            }
            keywordsint++;
            counter= keywordsint;
        }
        let preHolderAffiliations = <></>;
        if(id==='Affiliations') {
            affiliationsint++;
            counter= affiliationsint;
            let preHolderAffiliationsAuthorOption = [];

            for(let pre =1;pre<authorIconInt;pre++) {
                preHolderAffiliationsAuthorOption.push(<option value={pre}>{pre}</option>)
            }

            preHolderAffiliations = <><InputGroup.Text>
                Author:
            </InputGroup.Text>
            <Form.Select className={"col-1 AffiliationAuthor"} aria-label="Default select example" name={'affiliationText'+counter} id={'AffiliationAuthor'}  required={true}>
                {preHolderAffiliationsAuthorOption}
            </Form.Select>
            <InputGroup.Text>
                Affiliations:
            </InputGroup.Text></>
        }
        let numberIconHolder = <></>;
        if(numberIcon !== false) {
            numberIconHolder = <InputGroup.Text >
                {(id==='Keywords' ? keywordIconInt : (id==='Authors' ? authorIconInt : 0))}
            </InputGroup.Text>

            if(id==='Keywords') {
                keywordIconInt++;
            }
            if(id==='Authors') {
                let select = document.getElementsByClassName('AffiliationAuthor') as HTMLSelectElement;
                for(let s = 0;s<select.length;s++){
                    var opt = document.createElement('option');
                    opt.value = authorIconInt.toString();
                    opt.innerHTML = authorIconInt.toString();
                    select[s].appendChild(opt);
                }

                authorIconInt++;
            }
        }

        if(row === true) {
            // Affiliatons
            let newElement = (<><div className={'col-'+col}><InputGroup
                className={"mb-3 inputGroupExtra form-control " + addClass}>
                {numberIconHolder}
                {preHolderAffiliations}
                <Form.Control
                    maxLength={250}
                    name={id + '' + counter}
                    placeholder={id}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                />
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                 onClick={() => addElement(row, col, id, addClass, numberIcon)}>
                                <span className="material-icons">
                                    add
                                </span>
                </InputGroup.Text>
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}>
                 <span
                        className="material-symbols-outlined" style={{color: '#317391'}} onClick={(e) => deleteElement(e)}>
                    delete
                    </span>
                </InputGroup.Text>
            </InputGroup></div>
            </>);
            let temp = document.createElement('div');
            temp.classList.add('row')
            ReactDOM.render(newElement, temp);
            let ele =  document.getElementById(id);
            if(ele !== null) {
                ele.appendChild(temp)
            }
        } else {
            // Author
            let newElement = (<><InputGroup className={"mb-3 inputGroupExtra form-control "+addClass}>
                {numberIconHolder}
                {preHolderAffiliations}
                <Form.Control
                    maxLength={100}
                    name={id+''+counter}
                    placeholder={id}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                />
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                 onClick={()=>addElement(row,col,id,addClass,numberIcon)}>
                                <span className="material-icons">
                                    add
                                </span>
                </InputGroup.Text>
            </InputGroup></>);
            let temp = document.createElement('div');
            temp.classList.add('col-'+col)
            ReactDOM.render(newElement, temp);
            let ele =  document.getElementById(id);
            if(ele !== null) {
                ele.appendChild(temp)
            }
        }

    }

    if(Level.length > 0 && PresentationType.length > 0 && Session.length > 0) {
        let optionLevel = [];

        let optionPresentationType = [];

        let optionSession = [];

        for(let l=0;l<Level.length;l++){
            optionLevel.push(
                <>
                    <option value={Level[l].id} dangerouslySetInnerHTML={{__html: Level[l].type}} />
                </>
            )
        }

        for(let l=0;l<PresentationType.length;l++){
            optionPresentationType.push(
                <>
                    <option value={PresentationType[l].id} dangerouslySetInnerHTML={{__html: PresentationType[l].type}}/>
                </>
            )
        }

        for (let l = 0; l < Session.length; l++) {
            optionSession.push(
                <>
                    <option value={Session[l].id}
                            dangerouslySetInnerHTML={{__html: Session[l].title}}/>
                </>
            )
        }
        const SunEditorConfig = () => {
            return [
                // Default
                ['undo', 'redo','italic','subscript', 'superscript','removeFormat','fullScreen'],
            ]
        }

        if (process.env.REACT_APP_ABSTRACT_SUBMISSION_ACTIVE !== undefined && process.env.REACT_APP_ABSTRACT_SUBMISSION_ACTIVE === 'True') {
        return (<>
            <Row className={''}>
                <Row>
                <Col xs={12} md={12} xl={4} style={{background: 'transparent linear-gradient(270deg, #0CB4CE 0%, #317391 100%) 0% 0% no-repeat padding-box',color:'white',maxHeight:'840px'}}>
                    <br />
                    <p className={'text-center d-none d-xl-block'}><img src={'https://cdn.wum-solution.de//icymareKonfDev/2024/02/27/Xk/ICYMARE-Logo-wide-screen-SW.png'} /></p>
                    <br />
                    <h2>Abstract Submission ICYMARE 2024 Bremen</h2>
                    <p> <img src={"https://cdn.wum-solution.de//icymareKonfDev/2023/12/18/R5/ICYMARE-2024-BREMEN-CallForAbstracts.jpg"} /></p>
                    <br />
                    <p><IntlMessage messageId="icy.abstract.submission" Store={Store} /></p>
                </Col>
                <Col style={{background: '#FFFFFF 0% 0% no-repeat padding-box',maxHeight:'840px',overflowX:'hidden',overflowY:'scroll'}}>  <Form id={'form01'} onSubmit={(e)=> send(e,afterSend)}>
                    <Row> <h3>Presenting Author</h3></Row><Row>
                    <Col>
                        <Form.Control type="hidden" name={'UserId'}  defaultValue={Store.user.id}/>
                        <Form.Group className="mb-3" controlId="Title">
                            <UniForm.Label
                                text={IntlMessageValue('de', 'icy.title', Store)}
                                InfoButton={{
                                    display: true,
                                    text: IntlMessageValue('de', 'icy.title.explanation', Store)
                                }} />
                            <Form.Control maxLength={10} type="text" name={'Title'} placeholder="Enter Title" translate="no"/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="FirstName">
                            <UniForm.Label
                                text={IntlMessageValue('de', 'icy.first.name', Store)}
                                InfoButton={{
                                    display: true,
                                    text: IntlMessageValue('de', 'icy.first.name.explanation', Store)
                            }} />
                            <Form.Control type="text" name={'FirstName'} readOnly={true} placeholder="Enter first name" defaultValue={Store.user.first_name}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="LastName">
                            <UniForm.Label
                                text={IntlMessageValue('de', 'icy.last.name', Store)}
                                InfoButton={{
                                    display: true,
                                    text: IntlMessageValue('de', 'icy.last.name.explanation', Store)
                                }} />
                            <Form.Control type="text" name={'LastName'} readOnly={true} placeholder="Enter last name" defaultValue={Store.user.last_name}/>
                        </Form.Group>
                    </Col>
                </Row>
                    <Row>

                        <Col>
                            <Form.Group className="mb-3" controlId="Emailaddress">
                                <UniForm.Label
                                    text={IntlMessageValue('de', 'icy.email', Store)}
                                    InfoButton={{
                                        display: true,
                                        text: IntlMessageValue('de', 'icy.email.explanation', Store)
                                    }} />
                                <Form.Control type="email" name={'Emailaddress'} readOnly={true} placeholder="Enter email adress" defaultValue={Store.user.email}/>
                            </Form.Group>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row><Col><Form.Group className="mb-3" controlId="Mainaffiliation">
                        <UniForm.Label
                            text={IntlMessageValue('de', 'icy.main.affiliation', Store)}
                            InfoButton={{
                                display: true,
                                text: IntlMessageValue('de', 'icy.main.affiliation.explanation', Store)
                            }} />
                        <Form.Control type="text" maxLength={250} name={'mainAffiliation'} placeholder="Enter main affiliation"  required={true}/>
                    </Form.Group></Col></Row>


                    <Row>
                        <h3><IntlMessage messageId="icy.education.level" Store={Store} /></h3>
                        <p><IntlMessage messageId="icy.education.level.explanation" Store={Store} /></p>
                    </Row>

                <Row>
                    <Col>
                        <UniForm.Label
                            text={IntlMessageValue('de', 'icy.level', Store)}
                            InfoButton={{
                                display: true,
                                text: IntlMessageValue('de', 'icy.level.explanation', Store)
                            }} />
                        <Form.Select aria-label="Default select example" name={'Level'}  required={true}>
                            {optionLevel}
                        </Form.Select>
                    </Col>
                    <Col><Form.Group className="mb-3" controlId="Year">
                        <UniForm.Label
                            text={IntlMessageValue('de', 'icy.year', Store)}
                            InfoButton={{
                                display: true,
                                text: IntlMessageValue('de', 'icy.edu.year.explanation', Store)
                            }} />
                        <Form.Control type="number" name={'Year'} min="1900" max="2099" placeholder="Enter Year"  required={true}/>
                    </Form.Group></Col>
                </Row>

                    <Row>
                        <h3><IntlMessage messageId="icy.sessions" Store={Store} /></h3>
                        <p><IntlMessage messageId="icy.sessions.explanation" Store={Store} /></p>
                    </Row>
                    <Row>
                        <Col>
                            <UniForm.Label
                                text={IntlMessageValue('de', 'icy.session', Store)}
                                InfoButton={{
                                    display: true,
                                    text: IntlMessageValue('de', 'icy.session.explanation', Store)
                                }} />
                            <Form.Select aria-label="Default select example" name={'Sessions'}  required={true}>
                                {optionSession}
                            </Form.Select>
                        </Col>
                        <Col>
                            <UniForm.Label
                                text={IntlMessageValue('de', 'icy.type.presentation', Store)}
                                InfoButton={{
                                    display: true,
                                    text: IntlMessageValue('de', 'icy.type.presentation.explanation', Store)
                                }} />
                            <Form.Select aria-label="Default select example" name={'PresentationType'}  required={true}>
                                {optionPresentationType}
                            </Form.Select>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Form.Group className={"mb-3 border rounded pt-2 pb-2 "+(valuePresentationTitle === '' ? 'border-danger ' : 'border-success ')} controlId="PresentationTitle">
                            <Form.Label>Presentation title</Form.Label>
                            <SunEditor
                                key={'test'}
                                name={'PresentationTitle'}
                                setContents={valuePresentationTitle}
                                onChange={(valuePresentationTitle) => setEditorPresentationTitleContent(valuePresentationTitle)}
                                placeholder="Enter presentation title"
                                setOptions={{
                                    buttonList: SunEditorConfig(),
                                    attributesWhitelist: {
                                        div: 'id',
                                        p: 'id',
                                        h: 'id'
                                    }
                                }}
                                height={'50px'}
                            />

                        </Form.Group>
                    </Row>

                    <Row>
                        <h3><IntlMessage messageId="icy.authors.affiliation" Store={Store} /></h3>

                        <Col xs={11}>
                            <p><IntlMessage messageId="icy.authors.affiliation.explanation" Store={Store}/></p>
                        </Col>
                        <Col>
                            <InfoButton.Wrapper>
                            <InfoButton
                                    text={IntlMessageValue('de', 'icy.author.name.explanation', Store)}
                                    icon={'info'}
                                />
                            </InfoButton.Wrapper>
                        </Col>
                    </Row>
                    <Row id={'Authors'}>
                       <div className={'col-4'}>
                        <InputGroup className="mb-3 inputGroupExtra form-control w250" >
                            <InputGroup.Text>
                                1
                            </InputGroup.Text>
                            <Form.Control
                                name={'Authors0'}
                                maxLength={100}
                                placeholder="Author"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                className={'inputNoBorder'}
                                required={true}
                            />
                            <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                            onClick={()=>addElement(false,'4','Authors','w250',true)}
                            >
                                <span className="material-icons">
                                    add
                                </span>
                            </InputGroup.Text>
                        </InputGroup>
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={11}>
                            <p><IntlMessage messageId="icy.affiliation.text" Store={Store} /></p>
                        </Col>
                        <Col>
                            <InfoButton.Wrapper>
                                <InfoButton
                                    text={IntlMessageValue('de', 'icy.affiliation.explanation', Store)}
                                    icon={'info'}
                                />
                            </InfoButton.Wrapper>
                        </Col>
                    </Row>
                    <Row id={'Affiliations'}>
                        <Row>
                        <div className={'col-12'}>
                        <InputGroup className="mb-3 inputGroupExtra form-control">
                            <InputGroup.Text>
                                 Author:
                            </InputGroup.Text>
                            <Form.Select className={"col-1 AffiliationAuthor"} name={'affiliationText0'} aria-label="Default select example" id={'AffiliationAuthor'}  required={true}>
                                <option value={1}>1</option>
                            </Form.Select>
                            <InputGroup.Text>
                                Affiliations:
                            </InputGroup.Text>
                            <Form.Control
                                maxLength={250}
                                name={'Affiliations0'}
                                placeholder="Affiliations"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                className={'inputNoBorder'}
                                required={true}
                            />
                            <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                             onClick={()=>addElement(true,'12','Affiliations','')}>
                                 <span className="material-icons">
                                    add
                                </span>
                            </InputGroup.Text>
                        </InputGroup>
                        </div>
                        </Row>
                    </Row>
                    <br />
                    <br />
                    <Row>
                        <h3><IntlMessage messageId="icy.social.media" Store={Store} /></h3>
                        <p><IntlMessage messageId="icy.social.media.explanation" Store={Store} /></p>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="Twitter">
                                <Form.Label>Twitter</Form.Label>
                                <Form.Control maxLength={100} type="text"  name={'Twitter'} placeholder="Enter Twitter account" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="Instagram">
                                <Form.Label>Instagram</Form.Label>
                                <Form.Control maxLength={100} type="text" name={'Instagram'} placeholder="Enter Instagram account"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="Facebook">
                                <Form.Label>Facebook</Form.Label>
                                <Form.Control maxLength={100} type="text" name={'Facebook'} placeholder="Enter Facebook account"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="LinkedIn">
                                <Form.Label>LinkedIn</Form.Label>
                                <Form.Control maxLength={100} type="text" name={'LinkedIn'} placeholder="Enter LinkedIn account"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="Mastodon">
                                <Form.Label>Mastodon</Form.Label>
                                <Form.Control maxLength={100} type="text" name={'Mastodon'} placeholder="Enter Mastodon account"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="Bluesky">
                                <Form.Label>Bluesky</Form.Label>
                                <Form.Control maxLength={100} type="text" name={'Bluesky'} placeholder="Enter BlueSky account"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <h4><IntlMessage messageId="icy.social.media.institution" Store={Store} /></h4>
                        <p><IntlMessage messageId="icy.social.media.explanation.institution" Store={Store} /></p>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="InstitutionTwitter">
                                <Form.Label>Twitter</Form.Label>
                                <Form.Control maxLength={100} type="text" name={'InstitutionTwitter'} placeholder="Enter Twitter account"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="InstitutionInstagram">
                                <Form.Label>Instagram</Form.Label>
                                <Form.Control maxLength={100} type="text" name={'InstitutionInstagram'} placeholder="Enter Instagram account"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="InstitutionFacebook">
                                <Form.Label>Facebook</Form.Label>
                                <Form.Control maxLength={100} type="text" name={'InstitutionFacebook'} placeholder="Enter Facebook account"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="InstitutionLinkedIn">
                                <Form.Label>LinkedIn</Form.Label>
                                <Form.Control maxLength={100} type="text" name={'InstitutionLinkedIn'} placeholder="Enter LinkedIn account"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="InstitutionMastodon">
                                <Form.Label>Mastodon</Form.Label>
                                <Form.Control maxLength={100} type="text" name={'InstitutionMastodon'} placeholder="Enter Mastodon account"/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="InstitutionBluesky">
                                <Form.Label>Bluesky</Form.Label>
                                <Form.Control maxLength={100} type="text" name={'InstitutionBluesky'} placeholder="Enter Bluesky account"/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <h3><IntlMessage messageId="icy.keywords" Store={Store} /></h3>
                        <p><IntlMessage messageId="icy.keywords.explanation" Store={Store} /></p>
                    </Row>
                    <Row id={'Keywords'}>
                        <Row>
                        <Col>
                            <InputGroup className="mb-3 inputGroupExtra form-control">
                                <InputGroup.Text>
                                    1
                                </InputGroup.Text>
                                <Form.Control
                                    maxLength={200}
                                    name={'Keywords0'}
                                    placeholder="Keyword"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    className={'inputNoBorder'}
                                    required={true}
                                />
                                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                                 onClick={()=>addElement(true,'12','Keywords','',true)}>
                                     <span className="material-icons">
                                    add
                                </span>
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        </Row>
                    </Row>

                    <Row>
                        <Col xs="auto">
                            <h3><IntlMessage messageId="icy.abstract" Store={Store} /></h3>
                        </Col>
                        <Col>
                            <InfoButton.Wrapper>
                                <InfoButton
                                    text={IntlMessageValue('de', 'icy.abstract.support', Store)}
                                    icon={'info'}
                                />
                            </InfoButton.Wrapper>
                        </Col>

                        <p><IntlMessage messageId="icy.abstract.explanation" Store={Store} /></p>
                    </Row>
                    <Row>
                        <Form.Group className={"mb-3 border rounded pt-2 pb-2 "+(valueAbstract === '' ? 'border-danger ' : 'border-success ')} controlId="Abstract" id={'AbstractWrapper'}>
                            <SunEditor
                                key={'test'}
                                name={'Abstract'}
                                setContents={valueAbstract}
                                onChange={(valueAbstract) => setEditorAbstractContent(valueAbstract)}
                                placeholder="Enter Abstract"
                                setOptions={{
                                    buttonList: SunEditorConfig(),
                                    attributesWhitelist: {
                                        div: 'id',
                                        p: 'id',
                                        h: 'id'
                                    }
                                }}
                                height={'250px'}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                    <Col>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <UniForm.Label
                                text={IntlMessageValue('de', 'icy.CV', Store)}
                                InfoButton={{
                                    display: true,
                                    text: IntlMessageValue('de', 'icy.abstract.CV.explanation', Store)
                                }} />
                            <Form.Control id={'fileuploadAttachment1'} name={'file1'} type="file" accept=".pdf" required={true}/>
                        </Form.Group>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <UniForm.Label
                                text={IntlMessageValue('de', 'icy.abstract.upload', Store)}
                                InfoButton={{
                                    display: true,
                                    text: IntlMessageValue('de', 'icy.abstract.docxDocument.explanation', Store)
                                }} />
                            <Form.Control id={'fileuploadAttachment2'} name={'file2'} type="file" accept=".docx" required={true}/>
                        </Form.Group>
                    </Col>
                </Row>

<Row>
    <Col>
        {back}
                    <Button variant="primary" className={'float-end'} id={'sendButton'} type="submit"
                            disabled={(valueAbstract === '' || valuePresentationTitle === '' ? true : false)}
                    style={{background: (valueAbstract === '' || valuePresentationTitle === '' ? 'lightgray' : '#307593 0% 0% no-repeat padding-box'),
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '34px',
                        marginBottom:'20px',
                        opacity: 1,
                        float:'right',
                        height: '67px',
                        width: '350px'}}>
                        <span style={{
                            font: 'normal normal bold 30px/40px Segoe UI',
                            letterSpacing: '0px',
                            color: '#FFFFFF',
                            opacity: 1
                        }}>
                             Send
                        </span>
                    </Button>
                    <br />
                    <br />
    </Col>
</Row>
                </Form></Col>
            </Row>

        </Row>
    </>)
        } else {
            return (
                <>
                    <div className={"m-3"}>
                        <h1>Abstract submission is closed!</h1>
                        <p>Hi there!</p>
                        <p>Our Call for Abstracts for ICYMARE 2024 Bremen has ended on April 30th. Therefore, new submissions can no longer be considered. If you would still like to participate in this year's conference beyond listening, consider submitting a workshop proposal until the 31st of May! Find more information here: <a href={"https://www.icymare.com/about-the-conference/call-for-workshops/"}>https://www.icymare.com/about-the-conference/call-for-workshops/</a>
                        </p>
                    </div>
                </>
            );
        }
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }
}

export default AbstractNew;
